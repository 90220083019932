import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import webUrls from '../Weburls/weburls';
import SuperAdminHeader from './SuperAdminHeader';
import SuperAdminSidebar from './SuperAdminSidebar';

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('*Please enter firstname')
    .min(3, '*First name should have at least 3 characters')
    .matches(/^[A-Za-z]+$/, '*First name should contain only alphabetic characters'),
  lastName: yup
    .string()
    .required('*Please enter lastname')
    .min(3, '*Last name should have at least 3 characters')
    .matches(/^[A-Za-z]+$/, '*Last name should contain only alphabetic characters'),
  email: yup
    .string()
    .required('*Please enter email')
    .email('*Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, '*Invalid email format'),
  phoneNumber: yup
    .string()
    .required('*Please enter phone number')
    .matches(/^[0-9]+$/, '*Please enter number only')
    .matches(/^\d{10}$/, '*Phone number should have 10-digits'),
  companyName: yup.string().required('*Please enter company name'),
  companyWebsite: yup
    .string()
    .required('*Please enter company website')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      '*Please enter a valid URL',
    )
    .url('*Please enter a valid URL start with https'),
});

const NewCompany = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const inputFile = useRef(null);
  const navigate = useNavigate();
  const [profileLogo, setProfileLogo] = useState();
  const [companyLogo, setCompanyLogo] = useState();

  const [companyData, setCompanyData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    fileName: '',
    companyWebsite: '',
    companyName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };
  const handleChangeFile = (file) => {
    setProfileLogo(file.target.files[0]);
  };
  const handleChangeCompanyLogo = (file) => {
    setCompanyLogo(file.target.files[0]);
  };
  const onSubmitData = async (companyData) => {
    try {
      const formData = new FormData();

      formData.append('companyName', companyData.companyName);
      formData.append('companyWebsite', companyData.companyWebsite);
      formData.append('email', companyData.email);
      formData.append('firstName', companyData.firstName);
      formData.append('lastName', companyData.lastName);
      formData.append('phoneNumber', companyData.phoneNumber);
      formData.append('profileImage', profileLogo);
      formData.append('companyLogo', companyLogo);

      const response = await axios.post(webUrls.Super_admin_company_add, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Make sure to set this content type for file uploads
        },
      });

      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Companies');
        }, 3000);
      } else if (response.status === 404) {
        toast.error('Something went wrong please try again.', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(`Error post data: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />

        <div className="appmain">
          <SuperAdminSidebar />

          <div className="app-main__outer main-page-details-settings">
            <ToastContainer position="top-right" autoClose={5000} closeOnClick />

            <div className="col-md-12 mb-3">
              <h3 className="small-heading">
                <Link to="/Companies">
                  <i class="fa-solid fa-arrow-left"></i> Back to companies
                </Link>
              </h3>
            </div>
            <div className="setting_background_color">
              <form onSubmit={handleSubmit(onSubmitData)}>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h2 className="bold black big-top-heading">Add a new company</h2>
                    <p className="setting_text_color">Add company details.</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12 ">
                    <h5 className="bold black heading-new">Contact details </h5>
                    <div className="setting_text_color">This is who to contact about their company.</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 your-contact-details-side">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="firstName"
                            class="form-control input_border"
                            placeholder="First name"
                            onChange={handleChange}
                            {...register('firstName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.firstName?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="lastName"
                            class="form-control input_border"
                            placeholder="Last name"
                            onChange={handleChange}
                            {...register('lastName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        {errors.lastName && <p className="error-message">{errors.lastName?.message}</p>}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-7">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="email"
                            class="form-control input_border "
                            placeholder="Email"
                            onChange={handleChange}
                            {...register('email', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.email?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-5">
                        <div class="input-group mb-3">
                          <input
                            type="tel"
                            name="phoneNumber"
                            class="form-control input_border "
                            placeholder="Phone number"
                            onChange={handleChange}
                            {...register('phoneNumber', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.phoneNumber?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                      {/* <div className="col-md-10 col-xl-10 profile-image-side">
                        <div class="input-group">
                          <input
                            type="text"
                            name="profileImage"
                            value={companyData.fileName}
                            class="form-control input_border "
                            placeholder="Profile Image"
                          />
                          <div className="upload">
                            <input
                              type="file"
                              id="file"
                              name="profileImage"
                              accept="image/*"
                              ref={inputFile}
                              onChange={handleChangeFile}
                              style={{ display: 'none' }}
                              // {...register('profileImage')}
                            />

                            <Upload className="upload-logo" />
                          </div>
                        </div>
                        <p className="error-message">{errors.profileImage?.message}</p>
                      </div> */}
                      <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                        <div className="col-md-10 col-xl-10 profile-image-side">
                          <div class="input-group">
                            <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                              Profile image
                              <input
                                type="file"
                                class="form-control input_border "
                                placeholder="Profile Image"
                                id="file-format"
                                name="profileImage"
                                onChange={handleChangeFile}
                              />
                              <div className="upload">
                                <Upload className="upload-logo" />
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-2 profile-logo-side">
                          <div className="profile-img">
                            <img src={Profile} alt="profile" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Company details</h5>
                    <div className="setting_text_color">Enter in the details of the company.</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 your-contact-details-side">
                    <div className="row mt-2">
                      <div className="col-xl-7 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="url"
                            name="companyWebsite"
                            class="form-control input_border"
                            placeholder="Company website"
                            onChange={handleChange}
                            {...register('companyWebsite', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.companyWebsite?.message}</p>
                      </div>
                      <div className="col-xl-5 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            name="companyName"
                            class="form-control input_border "
                            placeholder="Company name"
                            onChange={handleChange}
                            {...register('companyName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                          />
                        </div>
                        <p className="error-message">{errors.companyName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                      <div className="col-md-10 profile-image-side">
                        <div class="input-group">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                            Company Profile image
                            <input
                              type="file"
                              class="form-control input_border "
                              placeholder="Profile Image"
                              id="file-format"
                              name="companyLogo"
                              onChange={handleChangeCompanyLogo}
                            />
                            <div className="upload">
                              <Upload className="upload-logo" />
                            </div>
                          </label>
                        </div>
                        {/* <p className="error-message">{errors.companyLogo?.message}</p> */}
                      </div>
                      <div className="col-md-2 profile-logo-side">
                        <div className="profile-img">
                          <img src={Profile} alt="profile" />
                        </div>
                      </div>
                      <div className="button mb-4">
                        <Link to="/Companies">
                          <button type="button" className="comn-btn button_white">
                            Cancel
                          </button>
                        </Link>
                        <button type="submit" className="comn-btn common-btn-style">
                          Save details
                          <i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewCompany;
