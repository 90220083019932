import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import weburls from '../../Weburls/weburls';
import './addIncome.css';

const schema = yup.object().shape({
  incomeName: yup.string().required('*Please enter income name'),
  incomeType: yup.string().required('*Please enter income type'),
  incomeAmount: yup
    .string()
    .required('*Please enter income amount')
    .matches(/^[0-9]+$/, '*Please enter number only'),
  lastPaymentDate: yup.string().required('*Please select income date'),

  howOften: yup.string().required('*Please select income frequency'),
});

const EditIncome = ({ modalOpen, setModalOpen, incomeUuid, fetchIncome, accountData, uuid, fetchTotalIncome }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [editIncomeData, setEditIncomeData] = useState({
    userUuid: '',
    incomeUuid: incomeUuid,
    incomeName: '',
    incomeAmount: '',
    incomeType: '',
    lastPaymentDate: '',
    howOften: '',
    accountAssociated: '',
  });
  const [howOftenList, setOftenList] = useState([]);

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditIncomeData((prev) => ({ ...prev, userUuid: uuid, [name]: value }));
  };

  const fetchData = useCallback(() => {
    axios
      .get(`${weburls.Get_Income_ById}${incomeUuid}`)
      .then((response) => {
        setEditIncomeData(response?.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [incomeUuid]);

  const howOften = useCallback(async () => {
    try {
      const data = await axios.get(weburls.How_Often);
      setOftenList(data?.data);
    } catch (error) {
      toast.error(`Error fetching data: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [setOftenList]);

  useEffect(() => {
    fetchData();
    howOften();
  }, [fetchData, howOften]);

  const onSubmitData = async () => {
    try {
      const response = await axios.put(weburls.Update_Income, editIncomeData);
      if (response.status === 200) {
        toast.success(response.data.message || 'Income update successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
        setModalOpen(false);
        fetchIncome();
        // fetchTotalIncome();
      } else if (response.status === 500) {
        toast.error('Something went wrong please try againaaaaa.');
      }
    } catch (error) {
      console.error('error', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  const handleDeleteIncome = async (id) => {
    try {
      const response = await axios.delete(`${weburls.Delete_income}${uuid}/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Income delete successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
        setModalOpen(false);
        fetchIncome();
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Edit your income</span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitData)}>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Edit your income details</h5>
                <div className="setting_text_color popup-text">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Income Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="incomeName"
                        class="form-control input_border"
                        placeholder="Income name"
                        defaultValue={editIncomeData?.incomeName}
                        onChange={handleChange}
                        {...register('incomeName', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.incomeName?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Income Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="incomeAmount"
                        class="form-control input_border"
                        placeholder="Income amount"
                        defaultValue={editIncomeData?.incomeAmount}
                        onChange={handleChange}
                        {...register('incomeAmount', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.incomeAmount?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Income Type :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="incomeType"
                        class="form-control input_border "
                        placeholder="Income type"
                        defaultValue={editIncomeData?.incomeType}
                        onChange={handleChange}
                        {...register('incomeType', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.incomeType?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Last Payment Date :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <input
                        type="date"
                        id="date"
                        defaultValue={editIncomeData?.lastPaymentDate?.substring(0, 10)}
                        class="form-control input_border "
                        placeholder="Last payment date"
                        name="lastPaymentDate"
                        onChange={handleChange}
                        {...register('lastPaymentDate', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.lastPaymentDate?.message}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xl-12 col-sm-12">
                    <div className="row mt-2 plan-wrp">
                      <div className="col-xl-6 col-md-12">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          How Often :
                        </label>
                        <select
                          class="form-select input_border mb-3"
                          name="howOften"
                          onChange={handleChange}
                          value={editIncomeData?.howOften}
                          {...register('howOften', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        >
                          <option value="" disabled={true}>
                            How often?
                          </option>

                          {Array.isArray(howOftenList) && howOftenList.length > 0
                            ? howOftenList &&
                              howOftenList?.map((item) => {
                                return (
                                  <option key={item.howOftenId} value={item.howOftenName}>
                                    {item.howOftenName}
                                  </option>
                                );
                              })
                            : 'new'}
                        </select>{' '}
                        <p className="error-message">{errors.howOften?.message}</p>
                      </div>

                      <div className="col-md-12 col-xl-6">
                        <label htmlFor="event-start" className="mb-1 labels-text">
                          Account Associated :
                        </label>
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            onChange={handleChange}
                            name="accountAssociated"
                            value={editIncomeData?.accountAssociated}
                          >
                            <option value="" disabled={true}>
                              Choose Account?
                            </option>

                            {Array.isArray(accountData) && accountData.length > 0
                              ? accountData &&
                                accountData?.map((item) => {
                                  return (
                                    <option key={item.accountUuid} value={item.accountUuid}>
                                      {item.accountName}
                                    </option>
                                  );
                                })
                              : 'new'}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="button modal-popup-btn-wrp">
                    <button type="button" class="comn-btn delete-btn" onClick={() => handleDeleteIncome(incomeUuid)}>
                      Delete Income
                    </button>
                    <button type="button" class="comn-btn common-btn-style" onClick={onSubmitData}>
                      Save edits
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditIncome;
