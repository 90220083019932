import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import paymentSuccess from '../../assests/images/payment-success.gif';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import '../pages/Login.css';

const FeedbackThankYou = () => {
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const logoRef = useRef(null);
  const formRef = useRef(null);

  const onSubmitHandler = async () => {
    setTimeout(() => navigate(`/manageAccountLogin`), 1000);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp mt-4" ref={formRef}>
                <p className="text-center welcomeText" style={{ fontSize: '28px', padding: '10px' }}>
                  Thank you 🙏🏻
                </p>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-outline">
                    <div className="text-center">
                      <img
                        src={paymentSuccess}
                        className="payment-gif"
                        style={{ marginBottom: '15px', marginTop: '20px' }}
                      ></img>
                    </div>
                  </div>
                  <div className="text-center pt-2" style={{ marginTop: '20px' }}>
                    <button type="submit" className="comn-btn feedback-btn" style={{ marginBottom: '20px' }}>
                      close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeedbackThankYou;
