import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Client } from '../../assests/images/icons/client.svg';
import { ReactComponent as Group } from '../../assests/images/icons/group.svg';
import { ReactComponent as Home } from '../../assests/images/icons/home.svg';
import { ReactComponent as Info } from '../../assests/images/icons/info.svg';
import { ReactComponent as Logout } from '../../assests/images/icons/logout.svg';
const SuperAdminSidebar = ({ superAdmin }) => {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  // const { _id } = useParams();
  // const id = localStorage.getItem('user_uuid');
  const navigate = useNavigate();
  const Logoutpg = () => {
    localStorage.removeItem('budget-token');
    localStorage.removeItem('userData');
    localStorage.removeItem('user_uuid');
    localStorage.removeItem('type');
    localStorage.removeItem('role');

    navigate('/');
  };

  return (
    <>
      <div className="app-sidebar sidebar-show">
        <div className="scrollbar-sidebar">
          <div className="app-sidebar__inner">
            <ul className="vertical-nav-menu metismenu">
              <li>
                <NavLink to="/superAdmin" exact activeClassName="navbar__link--active" className="navbar__link">
                  <Home />
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/Companies" exact activeClassName="navbar__link--active" className="navbar__link">
                  <Client />
                  Companies
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Plans"
                  id="purple-active"
                  exact
                  activeClassName="purple_navbar__link--active"
                  className="navbar__link"
                >
                  <Group />
                  Plans
                </NavLink>
              </li>
              {/* <li>
                  <NavLink
                    to="/Setting"
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    // onClick={settingApi}
                  >
                    <Setting />
                    Setting
                  </NavLink>
                </li> */}
            </ul>
          </div>
          <div className="bottom-list">
            <ul className="vertical-nav-menu metismenu">
              <li className="navbar__link">
                <a href="#">
                  <Info />
                  Help & info
                </a>
              </li>
              <li className="navbar__link" onClick={Logoutpg}>
                <a href="#">
                  <Logout />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminSidebar;
