import React from 'react';

const PopupColorModal = ({ isOpen, onClose, title, options, selectedOption, handleOptionClick }) => {
  const colorCss = (color) => {
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
        break;
      case 'Red':
        return 'red-color';
        break;
      case 'Yellow':
        return 'yellow-color';
        break;
      case 'Blue':
        return 'blue-color';
        break;
      case 'Purple':
        return 'purple-color-color';
        break;
      case 'Orange':
        return 'orange-color';
        break;
      case 'Pink':
        return 'pink-color';
        break;
      case 'Light blue':
        return 'light-blue-color';
        break;
      case 'Lime green':
        return 'lime-green-color';
        break;
      case 'Dark red':
        return 'dark-red-color';
        break;
      case 'Green':
        return 'green-color';
        break;
      case 'Dark blue':
        return 'dark-blue-color';
        break;
      default:
        return 'green';
    }
  };

  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div className="modal-content popup-width-color">
          <button onClick={onClose} className="close-modal-button">
            <i className="fa fa-times"></i>
          </button>
          <div className="setting_background_color">
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                <span className="bold black popup-top-heading">Pick your colour</span>
              </div>
            </div>
            <div className="col-lg-3 mb-3 popup-color-content-width">
              <div className="group-backgroud-color">
                <div className="bold black mb-3" style={{ float: 'left' }}>
                  Colour
                </div>
                <div className="group-btn green-btn popup-padding-zero" style={{ float: 'left' }}>
                  {options.map((option) => (
                    <div key={option} className="button-blck-color">
                      <a
                        href="#"
                        className={`group-button ${colorCss(option)}`}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PopupColorModal;
