import { yupResolver } from '@hookform/resolvers/yup';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import weburls from '../Weburls/weburls';
import './Login.css';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  username: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please enter a proper email'),
  amount: yup.number(),
});

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
      return;
    }
    setConfirmPasswordType('password');
  };
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    name: '',
    confirmPassword: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    // setLoading(true);
    console.log(formData, 'formData');
    const body = {
      name: formData.name,
      email: formData.username,
      amount: formData.amount,
      planName: 'monthly Plan',
      planType: 'Standard',
      plan: 'monthly',
    };

    const stripe = await loadStripe(
      'pk_test_51NLhblAjK0SDxqutPfSMuJfkluy14cy2dUJnfw3xUb0ZTqg4ZvxFjT4RsY5os8e20NOrghSF8Q7NVyngtgWO9F9w00wXscRGkr',
    );

    try {
      const response = await axios.post(weburls.Plan_payment_session, body);

      // Handle success
      const session = response.data;
      window.location = session.url;

      // setFormData(response);
      setLoading(false);
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data.error);
      } else if (error.request) {
        toast.error('No response received from the server. Please try again.');
      } else {
        toast.error('Payment failed. Please try again.');
      }

      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <section class="login-section loginSectionWelcome">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div
              class="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center	mb-4">
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp">
                <p className="text-center	registerText">Welcome to BudgetBuddie 👋</p>
                <p className="text-center	mb-3 tagtext">Complete the sign up to get started</p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div class="form-outline">
                    <label class="form-label" for="form3Example3">
                      Name
                    </label>
                    <input
                      {...register('name', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      value={formData.name}
                      name="name"
                      type="text"
                      id="form3Example3"
                      class="form-control form-control-lg"
                      placeholder="Enter a name"
                    />
                    <p className="mt-1 error-message">{errors.name?.message}</p>
                  </div>
                  <div class="form-outline">
                    <label class="form-label" for="form3Example3">
                      Email
                    </label>
                    <input
                      {...register('username', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      value={formData.username}
                      name="username"
                      type="text"
                      id="form3Example3"
                      class="form-control form-control-lg"
                      placeholder="Enter a valid email"
                    />
                    <p className="mt-1 error-message">{errors.username?.message}</p>
                  </div>
                  <div class="form-outline">
                    <label class="form-label" for="form3Example3">
                      Amount
                    </label>
                    <input
                      {...register('amount', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      value={formData.amount}
                      name="amount"
                      type="number"
                      id="form3Example3"
                      class="form-control form-control-lg"
                      placeholder="Enter amount"
                    />
                    <p className="mt-1 error-message">{errors.amount?.message}</p>
                  </div>

                  <ToastContainer
                    position="top-right"
                    autoClose={5000} // Auto close after 5 seconds
                    closeOnClick
                  />
                  {/* {loading === true ? (
                    <OverlayContainer>
                      <div>
                        <Loading />
                      </div>
                    </OverlayContainer>
                  ) : (
                    ''
                  )} */}
                  <div class="text-center mt-2 pt-2">
                    <button type="submit" class="comn-btn sign-btn">
                      Confirm Plan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Register;
