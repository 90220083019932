import React from 'react';

const PopupInfoModal = ({ isOpen, onClose, title, description }) => {
  const images = require('../../assests/images/icons/popup-back-potrait.png');
  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div
          className="modal-content popup-width"
          style={{
            backgroundImage: `url(${images})`,
            borderRadius: '20px',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          <button onClick={onClose} className="close-modal-button">
            <i className="fa fa-times" style={{ color: 'white' }}></i>
          </button>
          <div className="setting_background_info_color">
            <div className="d-flex justify-content-between" style={{ marginBottom: '20px' }}>
              <div className="fs-3">
                <span className="bold black popup-top-info-heading">{title}</span>
              </div>
            </div>
            {description.map((desc, index) => (
              <div key={index} className="col-lg-3 mb-3 popup-content-info-width">
                {desc}
              </div>
            ))}

            <button className="comn-info-btn" style={{ marginBottom: '20px' }}>
              I’m good to go 👌🏻
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PopupInfoModal;
