import axios from 'axios';
import React,{ useCallback,useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import Airnewzealand from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Tableimg from '../../assests/images/icons/image.png';
import { Linecart } from '../../Components/Innerpanel/Linecart';
import Activebutton from '../Buttons/Activebutton';
import Inactivebutton from '../Buttons/Inactivebutton';
import Notactivatedbutton from '../Buttons/Notactivatedbutton';
import Header from '../Header/Header';
import Activepage from '../pages/Activepage';
import Sidebar from '../Sidebar/Sidebar';
import weburls from '../Weburls/weburls';
import AdvisorList from './AdvisorList';

const Dashboard = () => {
  const [active, setActive] = useState(false);
  const companyId = JSON.parse(localStorage.getItem('userData'));
  const [tableFilter, setTableFilter] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [groupClients, setGroupWithClient] = useState([]);

  const activeChange = () => {
    setActive(true);
  };
  const fetchedData = useCallback(async () => {
    try {
      const response = await axios.get(weburls.Get_clients + `${companyId.companyId}`);
      localStorage.setItem('client-userUuid', JSON.stringify(response.data.clients));
      if (response?.data?.clients?.length > 0) {
        setTableFilter(response.data.clients);
      }
    } catch (error) {
      console.error(error);
    }
  }, [companyId.companyId]);

  const userDetailsApi = useCallback(async () => {
    try {
      const response = await axios.get(weburls.Advisor_Details + `${companyId.userUuid}`);
      if (response?.data) {
        setUserDetails(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [companyId.userUuid]);

  const groupDetailsWithClients = async () => {
    const response = await axios.get(`${weburls.Get_Group_With_Clients}?companyId=${companyId.companyId}`);
    if (response?.data) {
      setGroupWithClient(response.data);
    }
  };
  const topGroups =
  groupClients.length > 0 &&
  groupClients
    .map((group) => {
      const activeClientsCount = (group.client_details?.filter((client) => client.client_status === 1) ?? []).length;
      const inActiveClientsCount = (group.client_details?.filter((client) => client.client_status === 2) ?? []).length;
      const notActivatedClientsCount = (group.client_details?.filter((client) => client.client_status === 3) ?? []).length;
      return {
        group_name: group.group_name,
        group_logo: group.group_logo,
        activeClientsCount,
        inActiveClientsCount,
        notActivatedClientsCount,
      };
    })
    .sort((a, b) => {
      // Customize the sorting logic based on your requirements
      // For example, sorting by activeClientsCount and then inActiveClientsCount
      if (b.activeClientsCount !== a.activeClientsCount) {
        return b.activeClientsCount - a.activeClientsCount;
      } else {
        return b.inActiveClientsCount - a.inActiveClientsCount;
      }
    })
    .slice(0, 3);


  const activeCount = tableFilter?.filter((item) => item.status === 1).length;
  const inactiveCount = tableFilter?.filter((item) => item.status === 2).length;
  const notActivatedCount = tableFilter?.filter((item) => item.status === 3).length;
  useEffect(() => {
    fetchedData();
    userDetailsApi();
    groupDetailsWithClients();
  }, [fetchedData, userDetailsApi]);
  const topGroup = groupClients.length > 0 && topGroups.shift();
  return (
    <>
      {active ? (
        <Activepage />
      ) : (
        <div className="container-fluid">
          <Header />
          <div className="appmain">
            <Sidebar />
            <div className="app-main__outer main-front-dashboard-container">
              <div className="fs-3 big-head-new">
                Good Morning,
                <span className="text-jeremy">
                  {userDetails?.firstName}
                  <div className="hand-img">👋</div>
                </span>
              </div>
              <div className="fst-normal light-grey">Here's your dashboard of clients using BudgetBuddie.</div>
              <div className="row mt-3 dashboard-wrp">
                <div className="col-md-12 col-xl-3">
                  <div className="total-clients">
                    <div className="bold black first-heading-total sub-head-text mb-4">Total clients</div>
                    <div className="margin-nu d-flex">
                      <h3 className="number-heading acc-num-head">{tableFilter?.length}</h3>
                      <div className="percentage bold">+15%</div>
                    </div>
                    <div className="text-client">Clients using BudgeBuddie</div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-4">
                  <div className="total-clients">
                    <div className="bold black sub-head-text">Amount of users</div>
                    <div className="margin-amount">
                      <h2 className="number-heading second-num-head">{tableFilter?.length}</h2>
                    </div>
                    <div className="d-flex justify-content-between mt-5">
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">{activeCount}</div>

                        <Activebutton />
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">{inactiveCount}</div>

                        <Inactivebutton />
                      </div>
                      <div className="user-wrp">
                        <div className="amount-number acc-num-head">{notActivatedCount}</div>

                        <Notactivatedbutton />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-2">
                  <div className="total-clients">
                    <div className="bold black text-center sub-head-text">Share requests</div>
                    <div className="share-number fs-3">15</div>
                    <button className="comn-btn">View requests</button>
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 col-xl-3">
                  <div className="total-clients">
                    <div className="bold black sub-head-text">Your top Group</div>
                    <div className="air-new group-name-tab air-new-zealand-tab">
                      <div className="air-image">
                        <img
                          src={
                            topGroup?.group_logo === '' || topGroup?.group_logo === null
                              ? Airnewzealand
                              : topGroup?.group_logo
                          }
                          alt="Air"
                        />
                      </div>
                      {topGroup?.group_name}
                    </div>
                    <div className="air-new-active active">
                      <div className="amount-number acc-num-head">{topGroup?.activeClientsCount}</div>
                      <Activebutton />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
              <div className="net-worth-line-cart-canvas col-lg-7 col-md-12">
                  <div className="line-cart-net">
                    <div className="bar-cart-heading-wrp col-lg-12 col-md-4">
                      <div className="top-heading-canvas-price">
                        <h4 className="bold black">Your active users</h4>
                    </div>
                    <Linecart netWorth={[3000, 4000, 5000, 6000, 7000, 8000, 9000,10000,11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 285000, 285000, 285000, 285000, 285000, 285000]} />
                  </div>
                </div>
                </div>
                <div className="col-md-12 col-lg-5 mt-5 left-section">
                  <AdvisorList />
                </div>
              </div>
              {/* <div className="row mt-3">
                <div className="fw-bold heading-main">Top {topGroups?.length} Groups</div>
                {otherGroups &&
                  otherGroups?.map((group, index) => (
                    <div key={index} className="col-xl-4 col-sm-12 col-md-12">
                      <div className="total-top-group">
                        <div className="bold black ranked-heading">Ranked {index + 2}</div>
                        <div className="air-new group-name-tab">
                          <div className="air-image">
                            <img
                              src={group?.group_logo}
                              alt={group?.group_name}
                              style={{
                                borderRadius: '50%',
                              }}
                            />
                          </div>
                          {group.group_name}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="user-wrp">
                            <div className="amount-number">{group?.activeClientsCount}</div>
                            <div className="amount-active">Active</div>
                          </div>
                          <div className="user-wrp">
                            <div className="amount-number">{group?.inActiveClientsCount}</div>
                            <div className="amount-inactive">Inactive</div>
                          </div>
                          <div className="user-wrp">
                            <div className="amount-number">{group?.notActivatedClientsCount}</div>
                            <div className="amount-activated">Not activated</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div> */}

              <div class="table-wrp clients-with-share-rqst-wrapper" id="#down">
                <div className="fw-bold heading-main mt-5">Clients with share requests</div>
                <div class="">
                  <div class="col-12 table-horizontal">
                    <table class="table-image main-table">
                      <thead>
                        <tr className="green">
                          <th scope="col">Name</th>
                          <th scope="col">Group</th>
                          <th scope="col">Plan</th>
                          <th scope="col">Frequency</th>
                          <th scope="col">Status</th>
                          <th scope="col">Email</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="space-table">
                        {tableFilter?.length > 0 &&
                          tableFilter?.map((item) => {
                            return (
                              <tr onClick={activeChange} className="cursor-pointer" key={item?.id}>
                                <th>
                                  <div className="air-image">
                                    <img src={Tableimg} alt="table-img" />
                                    <h3 className="table-content display">{item?.firstName}</h3>
                                    <Link className="amount-active" to={`/Dashbaordpanel/${item?.userUuid}`}>
                                      Open <i class="fa-solid fa-arrow-right"></i>
                                    </Link>
                                  </div>
                                </th>
                                <td className="table-text-color">{item?.groupName}</td>
                                <td className="table-text-color">{item?.planName}</td>
                                <td className="table-text-color">{item?.planFrequency}</td>
                                <td>
                                  {item?.status === 1 ? (
                                    <div className="active-status">
                                      <button className="active-btn table-text-color">
                                        <i class="fa-solid fa-circle"></i> Active
                                      </button>
                                    </div>
                                  ) : item?.status === 3 ? (
                                    <div className="orange-status">
                                      <button className="not-inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Not-Activated
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="in-active-status-div">
                                      <button className="inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Inactive
                                      </button>
                                    </div>
                                  )}
                                </td>
                                <td className="table-text-color">{item?.email}</td>
                                <td>
                                  <Link to={`/Clients/Edit/${item?.userUuid}`}>
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Dashboard;
