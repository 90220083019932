import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const CancelPlan = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const userEmail = getWithExpiry('email');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail]);

  const fetchedData = useCallback(async () => {
    const body = {
      email: userEmail,
    };
    try {
      const response = await axios.post(weburls.Get_CurrentPlan, body);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const onDeleteSubmit = async () => {
    navigate(`/cancelPlanFeedback`);
    // const body = {
    //   email: userEmail,
    // };
    // try {
    //   await axios.post(weburls.Cancel_current_plan, body);
    //   navigate(`/cancelPlanFeedback`);
    // } catch (error) {
    //   console.error(error);
    // }
  };
  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp mt-3" ref={formRef}>
                <p
                  className="text-center welcomeText mt-3 mb-5"
                  style={{
                    fontSize: '30px',
                  }}
                >
                  Confirm cancellation
                </p>
                <p className="text-center mb-3 tagtext">Your plan will be cancelled immediately.</p>
                <div className="mt-4">
                  <div
                    style={{
                      marginTop: '5px',
                    }}
                  >
                    <p className="plan-selected-text">Current Plan:</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 account-assets-column  box-radis-25">
                      {data?.amount === 0 && (
                        <div className="plan-box-mobile-view-14-day-trail-account">
                          <p className="select-plans-div-text-mobile">14 day free trial</p>
                          <p className="select-plans-text-mobile">
                            Get access to all features of our Pro plan for 14 days for free including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and unlimited connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#469B88',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(data?.amount === 9.5 || data?.amount === 95) && (
                        <div className="plan-box-mobile-view-free-account">
                          <p className="select-plans-div-text-mobile">
                            Basic - {data?.amount === 9.5 ? '9.50' : data?.amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 9.5 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            For the basic budgeter, limited features, including dashboard, budget, 2 goals, accounts, 6
                            months of transactions and 2 connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{ color: '#8914FF', backgroundColor: 'rgba(254, 254, 254)' }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(data?.amount === 103.5 || data?.amount === 10.35) && (
                        <div className="plan-box-mobile-view-standard-account">
                          <p className="select-plans-div-text-mobile">
                            Standard - ${data?.amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 10.35 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Our most popular plan with the essentials. Includes access to most features such as
                            dashboard, budget, cashflow, 4 goals, and 5 connections.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#145BFF',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}

                      {(data?.amount === 138 || data?.amount === 13.8) && (
                        <div className="plan-box-mobile-view-pro-account">
                          <p className="select-plans-div-text-mobile">
                            Pro - ${data?.amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 13.8 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth and unlimited connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#56D77A',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {data?.amount === 82.8 && (
                        <div className="plan-box-mobile-view-ealry-account">
                          <p className="select-plans-div-text-mobile">
                            Early Bird - ${data?.amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 13.8 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and 10 connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#CD56D7',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(data?.amount === 2 || data?.amount === 3) && (
                        <div className="plan-box-mobile-view-standard-account">
                          <p className="select-plans-div-text-mobile">
                            Standard
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 2 ? 'Monthly' : 'annual'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Our most popular plan with the essentials. Includes access to most features such as
                            dashboard, budget, cashflow, 4 goals, and 5 connections.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{ color: '#145BFF', backgroundColor: 'rgba(254, 254, 254)' }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(data?.amount === 4 || data?.amount === 5) && (
                        <div className="plan-box-mobile-view-pro-account">
                          <p className="select-plans-div-text-mobile">
                            Pro
                            <span className="select-plans-div-sub-text-mobile">
                              /{data?.amount === 4 ? 'Monthly' : 'annual'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth and unlimited connections.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{ color: '#56D77A', backgroundColor: 'rgba(254, 254, 254)' }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      {data?.amount === 1 && (
                        <div className="plan-box-mobile-view-freemium-account">
                          <p className="select-plans-div-text-mobile">Freemium</p>
                          <p className="select-plans-text-mobile">
                            Take control of your finances, for free, forever. Enjoy access to the dashboard, budget,
                            cashflow, 2 goals, net worth, retirement planner transactions and accounts.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{ color: '#43B9F6', backgroundColor: 'rgba(254, 254, 254)' }}
                            >
                              Current plan
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="text-cancel-plan">
                        <strong>NOTE :</strong> All cancellations for paid plans are prorated, you will receive a credit
                        for the unused amount oftime on your plan. Read more about this in our{' '}
                        <a
                          href="https://www.budgetbuddie.com/terms-of-use"
                          className="text-green"
                          style={{ fontWeight: 600 }}
                        >
                          Terms of Use
                        </a>
                      </div>
                    </div>

                    <div className="text-center mt-2 pt-2 mb-4">
                      <button type="submit" className="comn-btn cancel-plan-btn" onClick={onDeleteSubmit}>
                        Confirm cancellation
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CancelPlan;
