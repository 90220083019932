export const setWithExpiry = (key, value, expiry) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + expiry, // Set expiry time in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemString = localStorage.getItem(key);
  if (!itemString) {
    return null;
  }
  const item = JSON.parse(itemString);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    // Remove the item from localStorage if it has expired
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
