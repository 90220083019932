import axios from 'axios';
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import webUrls from '../../Weburls/weburls';

const EditAdvisor = ({ modalOpen, setModalOpen, setSubmit, data }) => {
  const [formError, setFormError] = useState(false);
  const handleClose = () => {
    setModalOpen(!modalOpen);
  };
  const [formData, setFormData] = useState({
    firstName: data.firstName,
    lastName: data.lastName,
    advisorId: data.advisorId,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const UpdateAdvisor = async () => {
    try {
      if (formData.firstName !== '' && formData.lastName !== '') {
        const update = await axios.post(webUrls.updateAdvisorDashboard, formData);
        if (update.status === 200) {
          setModalOpen(!modalOpen);
          setSubmit(true);
        } else if (update.status === 500) {
          setSubmit(false);
          toast.error('Something went wrong please try again.');
        }
      } else {
        if (formData.firstName === '') {
          validation('firstName', 'First Name');
        } else if (formData.lastName === '') {
          validation('lastName', 'Last Name');
        }
      }
    } catch (error) {
      toast.error(`Something went wrong ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
      setSubmit(false);
    }
  };
  const validation = (key, fieldName) => {
    setFormError((prevErrors) => ({
      ...prevErrors,
      [key]: `${fieldName} is required`, // Generate a custom error message based on the field name
    }));
  };
  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Edit Advisor</span>
            </div>
          </div>
          <hr />
          <form>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      First Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="firstName"
                        class="form-control input_border"
                        placeholder="First name"
                        value={formData.firstName}
                        required
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {formError.firstName && <p className="error-message">{formError.firstName}</p>}
                  </div>
                  <div className="col-md-12 col-xl-6">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Last Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="lastName"
                        class="form-control input_border"
                        placeholder="Last Name"
                        value={formData.lastName}
                        required
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {formError.lastName && <p className="error-message">{formError.lastName}</p>}
                  </div>
                </div>
              </div>
              <div class="button modal-popup-btn-wrp">
                <button type="button" class="comn-btn common-btn-style" onClick={UpdateAdvisor}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAdvisor;
