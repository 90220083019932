import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import weburls from '../Weburls/weburls';
import './Login.css';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('*Please enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password must contain at least one letter, one number, one special character, and be at least 8 characters long',
    ),
  cpassword: yup
    .string()
    .required('*Please enter your confirm password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('id');
  console.log(email, 'email');
  console.log(code, 'code');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfirmType, setPasswordConfirmType] = useState('password');
  const [loading, setLoading] = useState(false);
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const toggleConfirmPassword = () => {
    if (passwordConfirmType === 'password') {
      setPasswordConfirmType('text');
      return;
    }
    setPasswordConfirmType('password');
  };

  const [formData, setFormData] = useState({
    password: '',
    cpassword: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    const requestData = {
      email: email,
      code: code,
    };
    try {
      const response = await axios.post(weburls.Check_Reset_Password_Link, requestData);
      setFormData(response);
      setLoading(false);
      if (response.data.code == 0) {
        toast.error('Link is Invalid. Please try again', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
      if (response.data.code == 1) {
        const sendData = {
          email: email,
          newPassword: formData.password,
        };
        const responseData = await axios.post(weburls.Set_New_Password, sendData);
        if (responseData.data.code == 1) {
          toast.success('Your Password Reset Successfully', {
            position: 'top-right',
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(`/`);
          }, 2000);
        }
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Link is expire or Invalid. Please try again.');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login-section loginSectionWelcome">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="logoCss text-center mb-3">
              <WordLogoBlack />
            </div>

            <div className="loginFormWrp">
              <p className="text-center mb-5 mt-3 reset-password-text">Set new Password</p>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-outline mb-4">
                  <label className="form-label" for="form3Example3">
                    Password
                  </label>
                  <div className="formwrp">
                    <input
                      {...register('password', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      type={passwordType}
                      name="password"
                      id="password"
                      className="form-control form-control-lg"
                      placeholder="Enter password"
                    />
                    <div className="" onClick={togglePassword}>
                      {passwordType === 'password' ? (
                        <i className="fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa-solid fa-eye-slash"></i>
                      )}
                    </div>
                  </div>
                  <p className="mt-1 error-message">{errors.password?.message}</p>
                </div>

                <div className="form-outline mb-5">
                  <label className="form-label" for="form3Example4">
                    Confirm password
                  </label>
                  <div className="formwrp">
                    <input
                      {...register('cpassword', {
                        onChange: (e) => {
                          handleChange(e);
                        },
                      })}
                      type={passwordConfirmType}
                      name="cpassword"
                      id="cpassword"
                      className="form-control form-control-lg "
                      placeholder="Enter confirm password"
                    />
                    <div className="" onClick={toggleConfirmPassword}>
                      {passwordConfirmType === 'password' ? (
                        <i className="fa-solid fa-eye"></i>
                      ) : (
                        <i className="fa-solid fa-eye-slash"></i>
                      )}
                    </div>
                  </div>
                  <p className="mt-1 error-message">{errors.cpassword?.message}</p>
                </div>

                <ToastContainer
                  position="top-right"
                  autoClose={5000} // Auto close after 5 seconds
                  closeOnClick
                />
                {/* {loading === true ? (
                  <OverlayContainer>
                    <div>
                      <Loading />
                    </div>
                  </OverlayContainer>
                ) : (
                  ''
                )} */}
                <div className="text-center mt-2 pt-2">
                  <button type="submit" className="comn-btn open-reset-btn mb-5">
                    Set new password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ResetPassword;
