import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import weburls from '../Weburls/weburls';

const PopupOneOffBudgetModal = ({
  isOpen,
  setDeleteOneOffBudgetModal,
  oneOffBudgetUuid,
  uuid,
  fetchOneOffBudget,
  editOneOffBudgetScreen,
}) => {
  const navigate = useNavigate();
  const handleDeleteOneOfBudget = async (id) => {
    try {
      const response = await axios.delete(`${weburls.Delete_OneOffBudget}${id}/${uuid}`);
      setDeleteOneOffBudgetModal(false);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'One Off Budget Delete Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
        if (editOneOffBudgetScreen) {
          navigate(`/BudgetSummary/${uuid}`);
        }
        fetchOneOffBudget();
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div className="modal-content popup-width-color">
          <button onClick={() => setDeleteOneOffBudgetModal(false)} className="close-modal-button">
            <i className="fa fa-times"></i>
          </button>
          <div className="setting_background_color">
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                <span className="bold black popup-top-heading">Are you sure you want to remove this?</span>
              </div>
            </div>
          </div>
          <div className="setting_background_color">
            <div className="d-flex justify-content-between">
              <div class="button modal-popup-btn-wrp">
                <button type="submit" class="new-btn red-btn" onClick={() => setDeleteOneOffBudgetModal(false)}>
                  No, Keep it.
                </button>
              </div>
              <div class="button modal-popup-btn-wrp">
                <button
                  type="submit"
                  class="comn-btn common-btn-style"
                  onClick={() => handleDeleteOneOfBudget(oneOffBudgetUuid)}
                >
                  Yes, remove it.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PopupOneOffBudgetModal;
