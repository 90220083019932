import $ from 'jquery';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as Done } from '../../assests/images/all-set-done.svg';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import './setting.css';

const Email2FAVerifactionDone = () => {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const { uuid } = useParams();
  const toggleSidebar = () => {
    $(sidebarRef.current).toggleClass('sidebarnew');
  };


  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-setting-page-container">
              <ToastContainer position="top-right" autoClose={5000} closeOnClick />
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-3 bold big-head-new">Set up 2-factor authentication</div>
                  <div className="fst-normal setting_text_color mt-2">
                    2FA adds an extra wall of defence, ensuring that only you can access your BudgetBuddie account, even
                    if someone gets hold of your password.
                  </div>
                </div>
                <div className="col-md-6 right-section your-setting-page-back-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Settings ⚙️"
                        description={[
                          'Manage all your details, preferences, and security settings here.',
                          'Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.',
                          'Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ',
                          'View when your next payment is being processed and what card you’re using.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row setting-page mt-5">
                <div className="row d-flex justify-content-center align-items-center h-100 mt-5">
                  <div className="col-xl-3 setting-left-section mt-4">
                    <div className="main-2fa-left">
                      <div className="heading-2fa bold black mt-5">
                        <h3>All set up!</h3>
                      </div>
                      <div className="fst-normal setting_2fa_text_color mb-4 all-set-box-padding">
                        2FA adds an extra wall of defence, ensuring that only you can access your BudgetBuddie account,
                        even if someone gets hold of your password.
                      </div>
                      <form className="row">
                        <div className="email-forget-icon-width text-center">
                          <Done />
                        </div>
                        <div class="modal-popup-btn-wrp verification-code-input no-padding mb-5">
                          <button className="comn-btn verify-btn" type="button" onClick={() => {}}>
                            Back to settings
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Email2FAVerifactionDone;
