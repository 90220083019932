import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Done } from '../../assests/images/payment-done.svg';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import '../pages/Login.css';
import '../Setting/setting.css';
import weburls from '../Weburls/weburls';
import { getWithExpiry } from './checkAndSetLoginExpried';

const SuccessPaymentDetail = () => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState('');
  const [apiCall, setApiCall] = useState(true);
  const { uuid } = useParams();
  const [counter, setCounter] = useState(5);
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const fetchedData = useCallback(async () => {
    const body = {
      email: userEmail,
    };
    try {
      const response = await axios.post(weburls.Get_Payment_Session_id, body);
      const session = response.data;
      setSessionId(session);
      const bodys = {
        sessionId: session,
        email: userEmail,
      };
      await axios.post(weburls.Payment_Detail_Success, bodys);

      setTimeout(() => {
        navigate(`/managePlan`);
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  return (
    <>
      <section class="login-section loginSectionWelcome">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div
              class="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center	mb-4">
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp">
                <div className="heading-2fa bold black mt-5">
                  <h3>Update successful 🎉</h3>
                </div>
                <div className="text-center">
                  <Done className="done-img" />
                </div>
                <div class="modal-popup-btn-wrp verification-code-input no-padding mb-5">
                  <button className="comn-btn verify-btn" type="button">
                    You will be redirected in {counter}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SuccessPaymentDetail;
