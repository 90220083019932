import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import weburls from '../../Weburls/weburls';
import SuperAdminHeader from '../SuperAdminHeader';
import SuperAdminSidebar from '../SuperAdminSidebar';

const schema = yup.object().shape({
  planName: yup
    .string()
    .required('*Plan name is required')
    .min(3, '*Plan name should have at least 3 characters')
    .matches(/^[A-Za-z]+$/, '*Plan name should contain only alphabetic characters'),
  planMode: yup.string().required('*Plan mode is required'),
  planAmount: yup
    .string()
    .required('*Plan amount is required')
    .matches(/^[0-9]+$/, '*Please enter only number.'),
  planDescription: yup
    .string()
    .required('*Please enter plan description.')
    .min(3, '*Plan description must be consist min 3 character.'),
});

const EditPlans = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [planid, planId] = useState('');

  const [formError, setformError] = useState('');

  // Edit Plan
  const fetchData = async () => {
    try {
      const response = await axios.get(weburls.Super_admin_edit_plans + `${id}`);
      setFormData(response.data);
      planId(response.data.planId);
    } catch (error) {
      toast.error(`Error fetching data: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  const [formData, setFormData] = useState({
    planName: '',
    planMode: '',
    planAmount: '',
    planDescription: '',
    planId: planid,
  });

  const validateInput = (fieldName, value) => {
    if (!value) {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`,
      }));
    } else {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  // Edit plans

  const onSubmitData = async (event) => {
    event.preventDefault();
    const updatedData = {
      ...formData,
      planName: event.target.planName.value,
      planMode: event.target.planMode.value,
      planAmount: event.target.planAmount.value,
      planDescription: event.target.planDescription.value,
    };
    try {
      const response = await axios.put(weburls.Super_admin_update_plans, updatedData);
      if (response.status === 200) {
        toast.success('Plan added successfully..', {
          position: 'top-right',
          autoClose: 1000,
        });
        setTimeout(() => {
          navigate('/Plans');
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      }
    } catch (error) {
      console.error('error', error);
    }
    reset();
  };

  // Delete Plan

  const handleDelete = async () => {
    try {
      const response = await axios.delete(weburls.Super_admin_delete_plans + `${id}`);
      if (response.status === 200) {
        toast.success('Plan deleted successfully..', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/Plans');
        }, 3000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />

        <div className="appmain">
          <SuperAdminSidebar />

          <div className="app-main__outer edit-client-details-page">
            <ToastContainer
              position="top-right"
              autoClose={5000} // Auto close after 5 seconds
              closeOnClick
            />
            <div className="row">
              <div className="col-md-12 back-to-dashboard">
                <h3 className="small-heading">
                  <Link to="/Plans">
                    <i class="fa-solid fa-arrow-left"></i> Back to plans
                  </Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color mt-3">
              <form onSubmit={onSubmitData}>
                <div className="d-flex justify-content-between">
                  <div className="fs-3">
                    <span className="bold black big-top-heading">Edit a plans details</span>
                    <div className="setting_text_color">Edit plans details. they'll get updated on the plans list.</div>
                  </div>
                </div>
                <hr />

                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter plan details.</div>
                  </div>
                  <div className="col-md-12 col-xl-7 col-sm-12 mb-5 plan-details">
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Enter a plan name"
                            id="planName"
                            defaultValue={formData?.planName}
                            name="planName"
                            {...register('planName')}
                            onChange={(e) => validateInput('planName', e.target.value)}
                          />
                        </div>
                        {formError.planName && <p className="error-message">{formError.planName}</p>}
                      </div>
                      <div className="col-xl-6 col-md-12 mb-3">
                        <select
                          class="form-select input_border"
                          name="planMode"
                          id="planMode"
                          // value={formData.planMode}
                          defaultValue={formData?.planMode}
                          {...register('planMode')}
                          onChange={(e) => validateInput('planMode', e.target.value)}
                        >
                          <option value={formData.planMode}>{formData.planMode}</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                          <option value="weekly">Weekly</option>
                        </select>
                        {/* <p className="error-message mt-1">{errors.planMode?.message}</p> */}
                      </div>
                      {formError.planMode && <p className="error-message">{formError.planMode}</p>}
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Plan amount"
                            id="planAmount"
                            defaultValue={formData?.planAmount}
                            name="planAmount"
                            {...register('planAmount')}
                            onChange={(e) => validateInput('planAmount', e.target.value)}
                          />
                        </div>
                        {formError.planAmount && <p className="error-message">{formError.planAmount}</p>}
                      </div>
                      <div className="col-md-12 col-xl-6 mb-3">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Currency name"
                            name="currencyName"
                            // {...register('planDescription')}
                            // defaultValue={formData.planDescription}
                            // onChange={(e) => validateInput('planDescription', e.target.value)}
                          />
                        </div>
                        {/* <p className="error-message">{errors.planDescription?.message}</p> */}
                      </div>
                      <div className="col-md-7 col-lg-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Plan description"
                            name="planDescription"
                            id="planDescription"
                            defaultValue={formData.planDescription}
                            {...register('planDescription')}
                            onChange={(e) => validateInput('planDescription', e.target.value)}
                          />
                        </div>
                        {formError.planDescription && <p className="error-message">{formError.planDescription}</p>}
                      </div>

                      <div className="button">
                        <button type="button" className="comn-btn button_white">
                          <Link to="/Plans" className="black">
                            Cancel
                          </Link>
                        </button>
                        <button type="submit" className="comn-btn save-details common-btn-style">
                          Save edits
                          <i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                        <button type="button" className="comn-btn delete-btn" onClick={handleDelete}>
                          Delete plan
                          <i class="fa-solid fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditPlans;
