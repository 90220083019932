import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import '../Setting/setting.css';

const PayementCancel = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(5);
  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      navigate(`/managePlan`);
    }, 5000);
  }, [navigate]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp" ref={formRef}>
                <div className="heading-2fa bold black mt-5">
                  <h3>Payment Cancelled 🙅‍♂️</h3>
                </div>
                <div className="modal-popup-btn-wrp verification-code-input no-padding mb-5 mt-5">
                  <button className="comn-btn delete-btn verify-btn" type="button">
                    You will be redirected in {counter}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PayementCancel;

