import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import '../pages/Login.css';
import '../Setting/setting.css';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(5);
  //   setTimeout(() => {
  //     navigate(`/managePlan`);
  //   }, 5000);

  //   useEffect(() => {
  //     const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //     return () => clearInterval(timer);
  //   }, [counter]);

  const handleBackToChange = () =>{
    navigate(`/manageAccountLogin`);
  }
  return (
    <>
      <section class="login-section loginSectionWelcome">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div
              class="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-5">
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp">
                <div className="heading-2fa bold black mt-5 mb-4">
                  <h3>404</h3>
                </div>
                <div className="text-center not-found-text mb-5">Ooops... Page not found 🤨</div>
                <div class="modal-popup-btn-wrp verification-code-input no-padding mb-5">
                  <button className="comn-btn verify-btn" type="button" onClick={handleBackToChange}>
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default NotFoundPage;
