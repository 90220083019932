import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Reactlogo from '../../assests/images/Black Logo 1.png';
import Profile from '../../assests/images/icons/image.png';

const SuperAdminHeader = ({ toggleBanner, Group }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <>
      <div class="row header-wrp-super-admin">
        <div class="col-md-6 col-sm-4 left">
          <div className="logo">
            <a class="navbar-brand" href="#">
              <img src={Reactlogo} alt="logo" />
              <h4 className="heading-super-admin inline black">BudgetBuddie</h4>
            </a>
          </div>
        </div>
        <div class="col-md-6 col-sm-4 right-section">
          <div className="profile-img-super">
            <Link to="/superAdmin">
              <img src={Profile} alt="profile" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminHeader;
