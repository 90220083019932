import React from 'react';
import { ReactComponent as LeftIcon } from '../../../assests/images/icons/left-icon.svg';
import { ReactComponent as RightIcon } from '../../../assests/images/icons/right-arr-icon.svg';

function CustomButtonGroup({ onPrevYear, onNextYear }) {
  return (
    <div className="custom-button-group">
      <span onClick={onPrevYear} className="left-btn-icon-calendar">
        <LeftIcon />
      </span>
      <span onClick={onNextYear} className="right-btn-icon-calendar">
        <RightIcon />
      </span>
    </div>
  );
}

export default CustomButtonGroup;
