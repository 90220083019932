import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as AmericanExpress } from '../../assests/images/AmericanExpress.svg';
import { ReactComponent as Discover } from '../../assests/images/Discover.svg';
import { ReactComponent as Jcb } from '../../assests/images/Jcb.svg';
import { ReactComponent as MasterCard } from '../../assests/images/MasterCard.svg';
import { ReactComponent as Visa } from '../../assests/images/VisaTest.svg';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const ManagePlan = () => {
  const [data, setData] = useState();
  const [paymentData, setPaymentData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  const brandIconMap = {
    visa: <Visa className="icon-div" />,
    mastercard: <MasterCard className="icon-div" />,
    amex: <AmericanExpress className="icon-div" />,
    discover: <Discover className="icon-div" />,
    jcb: <Jcb className="icon-div" />,
  };

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail, navigate]);

  const fetchedData = useCallback(async () => {
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Get_CurrentPlan, body);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  const fetchedPayment = useCallback(async () => {
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Get_Plan_Payment_Details, body);
      setPaymentData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  const handleUpdateChange = () => {
    navigate('/changePlans');
  };

  const handleEmailChange = () => {
    window.location.href = 'mailto:hello@budgetbuddie.co.nz';
  };

  const handleUpdateSelectPlan = () => {
    navigate('/selectNewPlan');
  };

  const onHandleLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    navigate('/manageAccountLogin');
  };

  const onChangePlan = async () => {
    setLoading(true);
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Update_Payment_Details, body);
      const session = response.data;
      window.location = session.url;
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else if (error.request) {
        toast.error('No response received from the server. Please try again.');
      } else {
        toast.error('Payment failed. Please try again.');
      }
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchedData();
    fetchedPayment();
  }, [fetchedData, fetchedPayment]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const formatDateToDDMMYYYY = (dateString) => {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <style>{`
        .slide-in {
          animation: slide-in 0.5s ease-out forwards;
        }

        @keyframes slide-in {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp" ref={formRef}>
                <p className="text-center welcomeText">Hello {userName} 👋🏻</p>
                <p className="text-center mb-3 tagtext" style={{ maxWidth: '100%', height: '36px' }}>
                  Below is your account name and email. You can update or cancel your current plan here.{' '}
                </p>
                <div className="input-header-text-manage">First name</div>
                <div className="form-outline">
                  <div
                    className="welcome-form-input-box"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    <div className="input-text-box-margin" style={{ width: '1000px', paddingLeft: '8px' }}>
                      <input
                        value={userName}
                        name="username"
                        type="text"
                        id="form3Example3"
                        className="register-focus"
                        style={{
                          fontWeight: 400,
                          fontSize: '14px',
                          border: 'none',
                          outline: 'none',
                          width: '100%',
                          fontFamily: 'Neurialgrotesk',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-header-text-manage">Email</div>
                <div className="form-outline">
                  <div
                    className="welcome-form-input-box"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                    }}
                  >
                    <div className="input-text-box-margin" style={{ width: '1000px', paddingLeft: '8px' }}>
                      <input
                        value={userEmail}
                        name="username"
                        type="text"
                        id="form3Example3"
                        className="register-focus"
                        style={{
                          fontWeight: 400,
                          fontSize: '14px',
                          border: 'none',
                          outline: 'none',
                          width: '100%',
                          fontFamily: 'Neurialgrotesk',
                        }}
                      />
                    </div>
                  </div>
                </div>
                {data?.amount !== 0 &&
                  data?.amount !== 1 &&
                  data?.amount !== 2 &&
                  data?.amount !== 3 &&
                  data?.amount !== 4 &&
                  data?.amount !== 5 &&
                  data?.amount !== null && (
                    <div className="renewal-payment-div" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className="payment-div" style={{ display: 'block', alignItems: 'center', height: '100%' }}>
                        <div className="input-header-text-manage">Payment method</div>
                        <div
                          className="welcome-form-input-box"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            paddingBottom: '3px',
                            paddingTop: '3px',
                          }}
                        >
                          <div
                            className="input-box-margin inner-payment-div"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              paddingBottom: '10px',
                              paddingTop: '10px',
                              paddingLeft: '8px',
                            }}
                          >
                            <div className="div-flex" style={{ height: '100%' }}>
                              {brandIconMap[paymentData && paymentData.cardName.toLowerCase()] || null}
                            </div>
                            <div className="div-flex" style={{ height: '100%' }}>
                              <p
                                className="inner-payment-text"
                                style={{ height: '100%', marginBottom: '0px', marginLeft: '5px' }}
                              >
                                {' '}
                                **** {paymentData && paymentData.cardLastDigit}
                              </p>
                            </div>
                            <div className="div-flex" style={{ height: '100%', paddingLeft: '10px' }}>
                              <button
                                type="submit"
                                className="change-btn"
                                onClick={onChangePlan}
                                style={{ height: '100%' }}
                              >
                                {'Change'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="renewal-div">
                        <div className="input-header-text-manage">Next bill</div>
                        <div className="form-outline">
                          <div
                            className="welcome-form-input-box"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              height: '100%',
                              paddingBottom: '3px',
                              paddingTop: '3px',
                            }}
                          >
                            <div
                              className="input-box-margin"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                paddingBottom: '10px',
                                paddingTop: '10px',
                                paddingLeft: '8px',
                              }}
                            >
                              <input
                                value={paymentData ? formatDateToDDMMYYYY(paymentData.renewalDate) : ''}
                                name="username"
                                type="text"
                                id="form3Example3"
                                className="register-focus text-label"
                                style={{ paddingBottom: '3px', paddingTop: '3px', fontWeight: '400' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div style={{ marginTop: '20px' }}>
                  <p className="plan-selected-text plan-text-sub">Current plan:</p>
                  <div style={{ float: 'right' }}>
                    {data?.amount !== null &&
                      data?.amount !== 2 &&
                      data?.amount !== 3 &&
                      data?.amount !== 4 &&
                      data?.amount !== 5 && (
                        <>
                          <Link to="/cancelPlan">
                            <p className="plan-selected-text cancel-btn">Cancel plan</p>
                          </Link>
                        </>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 account-assets-column box-radis-25">
                    {/* {data?.amount === 0 && (
                      <div className="plan-box-mobile-view-free-account">
                        <p className="select-plans-div-text-mobile">14 day free trial</p>
                        <p className="select-plans-text-mobile">
                          Get access to all features of our Pro plan for 14 days for free including, dashboard, budget,
                          cashflow, goals, net worth, retirement planner, and unlimited connections.{' '}
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#8914FF' }}
                            onClick={handleUpdateSelectPlan}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )} */}
                    {data?.amount === 0 && (
                      <div className="plan-box-mobile-view-14-day-trail-account">
                        <p className="select-plans-div-text-mobile">14 day free trial</p>
                        <p className="select-plans-text-mobile">
                          Get access to all features of our Pro plan for 14 days for free including, dashboard, budget,
                          cashflow, goals, net worth, retirement planner, and unlimited connections.{' '}
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#469B88' }}
                            onClick={handleUpdateSelectPlan}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 9.5 || data?.amount === 95) && (
                      <div className="plan-box-mobile-view-free-account">
                        <p className="select-plans-div-text-mobile">
                          Basic - {data?.amount === 9.5 ? '9.50' : data?.amount}
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 9.5 ? 'Month' : 'year'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          For the basic budgeter, limited features, including dashboard, budget, 2 goals, accounts, 6
                          months of transactions and 2 connections.{' '}
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#8914FF' }}
                            onClick={handleUpdateChange}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 103.5 || data?.amount === 10.35) && (
                      <div className="plan-box-mobile-view-standard-account">
                        <p className="select-plans-div-text-mobile">
                          Standard - ${data?.amount}
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 10.35 ? 'Month' : 'year'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          Our most popular plan with the essentials. Includes access to most features such as dashboard,
                          budget, cashflow, 4 goals, and 5 connections.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#145BFF', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleUpdateChange}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 138 || data?.amount === 13.8) && (
                      <div className="plan-box-mobile-view-pro-account">
                        <p className="select-plans-div-text-mobile">
                          Pro - ${data?.amount}
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 13.8 ? 'Month' : 'year'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          Ideal for families and finance fanatics, full access to all features including, dashboard,
                          budget, cashflow, goals, net worth and unlimited connections.{' '}
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#56D77A', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleUpdateChange}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {data?.amount === 82.8 && (
                      <div className="plan-box-mobile-view-ealry-account">
                        <p className="select-plans-div-text-mobile">
                          Early Bird - ${data?.amount}
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 13.8 ? 'Month' : 'year'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          Thanks for being an early bird! Enjoy full access to all features in our pro plan including,
                          dashboard, budget, cashflow, goals, net worth and unlimited connections.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#CD56D7', backgroundColor: 'rgba(254, 254, 254)' }}
                          >
                            {'Contact support to update'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 2 || data?.amount === 3) && (
                      <div className="plan-box-mobile-view-standard-account">
                        <p className="select-plans-div-text-mobile">
                          Standard
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 2 ? 'Monthly' : 'annual'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          Our most popular plan with the essentials. Includes access to most features such as dashboard,
                          budget, cashflow, 4 goals, and 5 connections.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#145BFF', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleEmailChange}
                          >
                            {'Contact support to update'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 4 || data?.amount === 5) && (
                      <div className="plan-box-mobile-view-pro-account">
                        <p className="select-plans-div-text-mobile">
                          Pro
                          <span className="select-plans-div-sub-text-mobile">
                            /{data?.amount === 4 ? 'Monthly' : 'annual'}
                          </span>
                        </p>
                        <p className="select-plans-text-mobile">
                          Ideal for families and finance fanatics, full access to all features including, dashboard,
                          budget, cashflow, goals, net worth and unlimited connections.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#56D77A', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleEmailChange}
                          >
                            {'Contact support to update'}
                          </button>
                        </div>
                      </div>
                    )}
                    {data?.amount === 1 && (
                      <div className="plan-box-mobile-view-freemium-account">
                        <p className="select-plans-div-text-mobile">Free</p>
                        <p className="select-plans-text-mobile">
                          Take control of your finances, for free, forever. Enjoy access to the dashboard, budget, money
                          insights, create 1 goal, view transactions and accounts.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#43B9F6', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleUpdateSelectPlan}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {data?.amount === null && (
                      <div className="plan-box-mobile-view-no-plan-account">
                        <p className="select-plans-div-text-mobile">No plan selected</p>
                        <p className="select-plans-text-mobile">
                          You have no plan currently selected. Upgrade your plan to get started and unlock your
                          financial future.
                        </p>
                        <div className="select-button-box">
                          <button
                            className="select-plan-button-green"
                            style={{ color: '#D75656', backgroundColor: 'rgba(254, 254, 254)' }}
                            onClick={handleUpdateSelectPlan}
                          >
                            {'Update Plan'}
                          </button>
                        </div>
                      </div>
                    )}
                    {(data?.amount === 2 || data?.amount === 3 || data?.amount === 4 || data?.amount === 5) && (
                      <div className="text-cancel-plan">
                        <strong>NOTE :</strong> The cost of this plan is covered by a BudgetBuddie partner, so you can
                        enjoy all the benefits we offer for free. If you wish to cancel your plan, please contact us at{' '}
                        <a href="mailto:hello@budgetbuddie.co.nz" className="text-green" style={{ fontWeight: 600 }}>
                          hello@budgetbuddie.co.nz
                        </a>
                      </div>
                    )}
                    {data?.amount === 0 && (
                      <div className="text-cancel-plan">
                        <strong>NOTE :</strong> If you haven't upgraded to a paid plan after your free trial expires, we
                        will automatically update you to our free plan. Please note that we delete all your account,
                        transaction, and merchant data when this update is made.
                      </div>
                    )}

                    <div className="text-center mt-2 mb-3 link-logout" onClick={onHandleLogout}>
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManagePlan;
