import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Reactlogo from '../../assests/images/Black Logo 1.png';
import weburls from '../Weburls/weburls';

const Header = ({ toggleBanner, Group, edit }) => {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState();
  const id = JSON.parse(localStorage.getItem('userData'));

  const settingApi = async () => {
    const response = await axios.get(weburls.Advisor_Details + `${id.userUuid}`);
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Role Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    settingApi();
  }, [edit]);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };
  return (
    <>
      <div class="row header-wrp">
        <div class="col-md-6 col-sm-4 left">
          <div className="logo">
            <a class="navbar-brand" href="#">
              <img src={Reactlogo} alt="logo" />
              <h4 className="heading inline black">BudgetBuddie</h4>
            </a>
            <div
              className="super-advice"
              style={{
                width: '200px',
                height: '80px',
                overflow: 'hidden',
              }}
            >
              <a href="#">
                <img
                  src={
                    data?.companyLogo === null || data?.companyLogo === ''
                      ? 'https://i.pinimg.com/736x/71/b3/e4/71b3e4159892bb319292ab3b76900930.jpg'
                      : `${data?.companyLogo}`
                  }
                  alt="advice"
                  style={{
                    objectFit: 'contain',
                  }}
                />
              </a>
            </div>
            <div class="topnav">
              <div id="myLinks" className={isActive ? 'bg-salmon' : ''}>
                <Link to="/"> Dashborad</Link>
                <Link to="/Clients"> Clients</Link>
                <Link to="/Groups"> Group</Link>
                <Link to="/Setting"> Setting</Link>
              </div>
              <div className="button-grp">
                <Link type="button" className="comn-btn" to="/Clients/Newclient" onClick={toggleBanner}>
                  New client
                  <i class="fa-solid fa-plus plus-icon-style"></i>
                </Link>
                <Link type="button" className="comn-btn purple" to="/Groups/Newgroup" onClick={Group}>
                  New group
                  <i class="fa-solid fa-plus plus-icon-style"></i>
                </Link>
              </div>
              <a href="javascript:void(0);" class="icon" onClick={handleClick}>
                <i class="fa fa-bars"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-4 right-section">
          <div className="button-grp">
            <Link type="button" className="comn-btn" to="/Clients/Newclient" onClick={toggleBanner}>
              New client
              <i class="fa-solid fa-plus plus-icon-style"></i>
            </Link>
            <Link type="button" className="comn-btn purple" to="/Groups/Newgroup" onClick={Group}>
              New group
              <i class="fa-solid fa-plus plus-icon-style"></i>
            </Link>
          </div>
          <div
            className="profile-img"
            style={{
              width: '56px',
              height: '51px',
              overflow: 'hidden',
            }}
          >
            <Link to="/Setting">
              <img
                src={
                  data?.avatar === null || data?.avatar === ''
                    ? 'https://i.pinimg.com/736x/71/b3/e4/71b3e4159892bb319292ab3b76900930.jpg'
                    : `${data?.avatar}`
                }
                alt="profile"
                style={{
                  width: '50px',
                  height: '50px',
                  objectFit: 'cover',
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
