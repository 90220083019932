import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useEffect, useRef, useState } from 'react';
import './calendar.css';
import CustomButtonGroup from './CustomButton Group';

const MyCalendar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [eventDetails, setEventDetails] = useState({
    startDate: '',
    endDate: '',
    title: '',
    location: '',
    description: '',
  });
  const [eventData, setEventData] = useState([]);
  const calendarRef = useRef(null);

  const handleDateSelect = (selectInfo) => {
    setModalOpen(true);
    setEditModalOpen(false);
  };

  const inputHandler = (e) => {
    const name = e.target.name;
    const values = e.target.value;
    setEventDetails({ ...eventDetails, [name]: values });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handlePrevYear = () => {
    calendarRef.current.getApi().prev();
  };

  const handleNextYear = () => {
    calendarRef.current.getApi().next();
  };

  // Event edits
  const handleEventClick = (info) => {
    setEditModalOpen(true);
    setModalOpen(true);
    const clickedEvent = info.event;
    if (clickedEvent._instance.range.start && clickedEvent._instance.range.end) {
      setEventToEdit(clickedEvent);
      setEventDetails({
        startDate: clickedEvent._instance.range.start.toISOString().split('T')[0],
        endDate: clickedEvent._instance.range.end.toISOString().split('T')[0],
        title: clickedEvent.title,
        location: clickedEvent.extendedProps.location,
        description: clickedEvent.extendedProps.description,
      });
    }
  };

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    return date.toLocaleDateString();
  };

  const handleSaveEventEdit = () => {
    const events = getEventsFromLocalStorage();
    const eventIndex = events.findIndex((event) => event.title === eventToEdit.title);
    if (eventIndex !== -1) {
      const updatedEvent = {
        ...events[eventIndex],
        title: eventDetails.title,
        startDate: eventDetails.startDate,
        endDate: eventDetails.endDate,
        extendedProps: {
          location: eventDetails.location,
          description: eventDetails.description,
        },
      };

      events[eventIndex] = updatedEvent;
      saveEventsToLocalStorage(events);
      setEditModalOpen(false);
      setModalOpen(false);
      setEventToEdit(null);
    }
  };

  const handleDeleteEvent = () => {
    if (!eventToEdit) {
      return;
    }
    const events = getEventsFromLocalStorage();
    const updatedEvents = events && events?.filter((event) => event.title !== eventToEdit.title);
    saveEventsToLocalStorage(updatedEvents);
    setEditModalOpen(false);
    setModalOpen(false);
    setEventToEdit(null);
  };

  // Event edit end

  //Day header
  const today = new Date().getDay();
  const customDayHeader = (props) => {
    const { dow } = props;
    const day = dow;
    const classNames = ['fc-col-header-cell'];
    if (day === today) {
      classNames.push('active-day-header-calendar');
    }
    return <th className={classNames.join(' ')}>{props.text}</th>;
  };
  // Day header

  const saveEventsToLocalStorage = (events) => {
    localStorage.setItem('events', JSON.stringify(events));
  };

  const getEventsFromLocalStorage = () => {
    const storedEvents = localStorage.getItem('events');
    return storedEvents ? JSON.parse(storedEvents) : [];
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const newEvent = {
      start: eventDetails.startDate,
      end: eventDetails.endDate,
      title: eventDetails.title,
      location: eventDetails.location,
      description: eventDetails.description,
    };

    const events = getEventsFromLocalStorage();
    events.push(newEvent);
    saveEventsToLocalStorage(events);
    setModalOpen(false);
  };

  useEffect(() => {
    const NewEventsData = JSON.parse(localStorage.getItem('eventData'));
    setEventData([...eventData, NewEventsData]);
  }, []);

  return (
    <>
      <div className="calendar-container">
        {modalOpen && (
          <div className={`modal-background-calendar ${modalOpen ? 'open-modal' : ''} `}>
            <div className="modal-content-calendar">
              <div className="calander-popup-title">
                <div className="add-event-title">
                  <div className="d-flex justify-content-between">
                    <div className="fs-3">
                      <span className="bold black big-top-heading">
                        <i className="fa fa-calendar" aria-hidden="true"></i> Add Event
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <button onClick={handleClose} className="close-modal-button-calendar">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div className="setting_background_color">
                <form id="event-form" className="event-form" onSubmit={(e) => onSubmitHandler(e)}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xl-12">
                      <div className="row">
                        <div className="col-md-12 col-xl-6">
                          <label htmlFor="event-start" className="mb-3">
                            <i class="fa fa-bell" aria-hidden="true"></i>
                            From
                          </label>
                          <div class="input-group mb-3">
                            <input
                              type="date"
                              id="startDate"
                              className="form-control input_border"
                              placeholder="From"
                              name="startDate"
                              defaultValue={editModalOpen ? formatDate(eventDetails?.startDate) : ''}
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-6">
                          <label htmlFor="event-end" className="mb-3">
                            <i className="fa fa-bell-slash" aria-hidden="true"></i> To
                          </label>
                          <div class="input-group mb-3">
                            <input
                              id="endDate"
                              type="date"
                              name="endDate"
                              className="form-control input_border"
                              placeholder="To"
                              defaultValue={editModalOpen ? formatDate(eventDetails?.endDate) : ''}
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 col-xl-6">
                          <label htmlFor="event-title" className="mb-3">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            Event Name
                          </label>
                          <div class="input-group mb-3">
                            <input
                              id="title"
                              className="form-control input_border"
                              placeholder="Event name"
                              type="text"
                              name="title"
                              defaultValue={editModalOpen ? eventDetails?.title : ''}
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-6">
                          <label htmlFor="event-location" className="mb-3">
                            <i className="fa fa-map-marker" aria-hidden="true"></i> Location
                          </label>
                          <div class="input-group mb-3">
                            <input
                              id="location"
                              type="text"
                              name="location"
                              className="form-control input_border"
                              placeholder="Event location"
                              defaultValue={editModalOpen ? eventDetails?.location : ''}
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 col-xl-12">
                          <label htmlFor="event-title" className="mb-3">
                            <i class="fa fa-file-text" aria-hidden="true"></i>
                            Event Description
                          </label>
                          <div class="input-group mb-3">
                            <textarea
                              id="description"
                              type="text"
                              name="description"
                              className="form-control input_border event-description"
                              placeholder="Add your event description here.."
                              defaultValue={editModalOpen ? eventDetails?.description : ''}
                              onChange={inputHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {editModalOpen ? (
                    <div class="button calendar-modal-add">
                      <button type="submit" class="comn-btn common-btn-style" onClick={handleSaveEventEdit}>
                        Save edits
                      </button>
                      <button type="button" className="comn-btn delete-btn" onClick={handleDeleteEvent}>
                        Delete event
                      </button>
                    </div>
                  ) : (
                    <div class="button calendar-modal-add">
                      <button type="submit" class="comn-btn common-btn-style">
                        Save event
                      </button>{' '}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
        <div className="custom-btn-grp-container-main">
          <CustomButtonGroup onPrevYear={handlePrevYear} onNextYear={handleNextYear} />
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          firstDay="1"
          selectHelper={true}
          eventBackgroundColor="#469b88"
          selectMirror={true}
          dayMaxEvents={true}
          dayHeaderContent={customDayHeader}
          eventClick={handleEventClick}
          events={getEventsFromLocalStorage()}
          eventRender={(info) => {
            const date = info.event.start.toISOString().split('T')[0];
            const eventsOnDate = getEventsFromLocalStorage().filter((event) => event.start.split('T')[0] === date);
            if (eventsOnDate?.length > 0) {
              info.el.innerHTML = `<div class="event-count">${eventsOnDate?.length}</div>`;
            }
          }}
          dayHeaderFormat={{ weekday: 'long' }}
          select={handleDateSelect}
        />
      </div>
    </>
  );
};

export default MyCalendar;
