import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as EyeClose } from '../../assests/images/eye-close-icon.svg';
import { ReactComponent as EyeOpen } from '../../assests/images/eye-open-icon.svg';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import weburls from '../Weburls/weburls';
import { setWithExpiry } from './checkAndSetLoginExpried';
import './Register.css';

// .matches(
//   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
//   'Password must contain at least 8 characters, one letter, one number, and one special character',
// ),

const schema = yup.object().shape({
  name: yup.string().required('Please enter your name'),
  username: yup
    .string()
    .required('Please enter your email')
    .email('Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please enter a proper email'),
  password: yup
    .string()
    .required('Please enter your password')
    .min(8, 'Password must contain at least 8 characters')
    .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[@$!%*#?&]/, 'Password must contain at least one special character'),
});

const Register = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
      return;
    }
    setConfirmPasswordType('password');  
  };
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    name: '',
    confirmPassword: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    let trimmedValue;
    if (name === 'name') {
      trimmedValue = value;
    } else {
      trimmedValue = value.trim();
    }

    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    const body = {
      name: formData.name,
      email: formData.username,
    };    
    setLoading(true);
    try {
      const response = await axios.post(weburls.Send_email_otp, body);
      if (response.data.message && Number(response.data.code) === 0) {
        toast.error(response.data.message);
      }
      if (Number(response.data.code) === 1) {
                // toast.success('Your OTP send in your email successfully', {
        //   position: 'top-right',
        //   autoClose: 2000,
        // });
        setWithExpiry('signUpEmail', formData.username, 30 * 60 * 1000);
        setWithExpiry('signUpPassword', formData.password, 30 * 60 * 1000);
        setWithExpiry('signUpName', formData.name, 30 * 60 * 1000);
        setTimeout(() => navigate(`/emailVerifaction`), 1000);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <section className="login-section loginSectionWelcome">
        <div className="plan-div">
          <div className="row d-flex justify-content-center align-items-center">
            <div
              className="col-md-8 col-lg-6 col-xl-4 padding-none"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp" ref={formRef}>
                <p className="text-center registerText">Welcome to BudgetBuddie 👋🏻</p>
                <p className="text-center mb-4 tagtext">Complete the sign up to get started.</p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="input-header-text">First name</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <div className="input-text-box-margin" style={{ paddingTop: '13px', paddingBottom: '13px', width: '100%', paddingLeft: '8px' }}>
                        <input
                          {...register('name', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          value={formData.name}
                          name="name"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                          }}
                        />
                      </div>
                    </div>
                    {errors.name && <p className="t-1 error-message mt-1">{errors.name.message}</p>}
                  </div>
                  <div className="input-header-text">Email</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <div className="input-text-box-margin" style={{ paddingTop: '13px', paddingBottom: '13px', width: '100%', paddingLeft: '8px' }}>
                        <input
                          {...register('username', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          value={formData.username}
                          name="username"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                          }}
                        />
                      </div>
                    </div>
                    {errors.username && <p className="t-1 error-message mt-1">{errors.username.message}</p>}
                  </div>
                  <div className="input-header-text">Password</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <div className="input-text-box-margin" style={{ paddingTop: '13px', paddingBottom: '13px', width: '100%', paddingLeft: '8px' }}>
                        <input
                          {...register('password', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}                          
                          type={passwordType}
                          name="password"
                          id="form3Example4"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                          }}
                        />
                      </div>
                      <div className="" style={{ marginRight: '20px' }} onClick={togglePassword}>
                        {passwordType === 'password' ? <EyeClose /> : <EyeOpen />}
                      </div>
                    </div>
                    {errors.password && <p className="t-1 error-message mt-1">{errors.password.message}</p>}
                  </div>
                  <div className="form-outline" style={{ marginTop: '23px' }}>

                  {/* <div class="form-outline">
                    <div
                      className="welcome-form-input-box"
                      style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}
                    >
                      <div style={{ marginLeft: '20px', flex: '1' }}>
                        <span className="welcome-form-input-text">Confirm password</span> <br />
                        <input
                          {...register('confirmPassword', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          type={confirmPasswordType}
                          name="confirmPassword"
                          id="form3Example4"
                          className="register-focus"
                          style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                          }}
                        />
                      </div>
                      <div className="" style={{ marginRight: '20px' }} onClick={toggleConfirmPassword}>
                        {confirmPasswordType === 'password' ? (
                          // <i class="fa-solid fa-eye"></i>
                          <EyeClose />
                        ) : (
                          // <i class="fa-solid fa-eye-slash"></i>
                          <EyeOpen />
                        )}
                      </div>
                    </div>
                  </div> */}
                    <label className="form-check-label FormCheck" style={{ display: 'flex' }}>
                      <input type="checkbox" className="checkbox-welcome-new" required />
                      <p className="check-label-text">
                        By signing up, I agree to the{' '}
                        <a href="https://www.budgetbuddie.com/terms-of-use" className="text-green font-500">
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a href="https://www.budgetbuddie.com/privacy-policy" className="text-green font-500">
                          Privacy Policy
                        </a>
                      </p>
                    </label>
                  </div>
                  <div className="text-center pt-2" style={{ marginTop: '20px' }}>
                    <button type="submit" className={`comn-btn ${loading ? 'sign-btn-load' : 'sign-btn'}`} style={{ marginBottom: '20px' }}>
                      {loading ? <ClipLoader size={15} color={'#ffffff'} /> : 'Sign up'}
                    </button>
                    <div className="d-flex flex-row align-items-center justify-content-center mt-4 mb-4">
                      <span className="textContainer">
                        Want to manage your account?{' '}
                        <a href="/manageAccountLogin" className="text-green" style={{ fontWeight: 500 }}>
                          Manage
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;

