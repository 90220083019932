import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as Client } from '../../assests/images/icons/client.svg';
import { ReactComponent as Group } from '../../assests/images/icons/group.svg';
import { ReactComponent as Home } from '../../assests/images/icons/home.svg';
import { ReactComponent as Info } from '../../assests/images/icons/info.svg';
import { ReactComponent as Logout } from '../../assests/images/icons/logout.svg';
import { ReactComponent as Setting } from '../../assests/images/icons/setting.svg';
const Sidebar = () => {
  const navigate = useNavigate();
  const Logoutpg = () => {
    localStorage.removeItem('budget-token');
    localStorage.removeItem('userData');
    navigate('/');
  };

  return (
    <>
      <div className="app-sidebar sidebar-show">
        <div className="scrollbar-sidebar">
          <div className="app-sidebar__inner">
            <ul className="vertical-nav-menu metismenu">
              <li>
                <NavLink to="/Dashboard" exact activeClassName="navbar__link--active" className="navbar__link">
                  <Home />
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/Clients" exact activeClassName="navbar__link--active" className="navbar__link">
                  <Client />
                  Clients
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Groups"
                  id="purple-active"
                  exact
                  activeClassName="purple_navbar__link--active"
                  className="navbar__link"
                >
                  <Group />
                  Groups
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Setting"
                  activeClassName="navbar__link--active"
                  className="navbar__link"
                  // onClick={settingApi}
                >
                  <Setting />
                  Setting
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="bottom-list">
            <ul className="vertical-nav-menu metismenu">
              <li className="navbar__link">
                <a href="#">
                  <Info />
                  Help & info
                </a>
              </li>
              <li className="navbar__link" onClick={Logoutpg}>
                <a href="#">
                  <Logout />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
