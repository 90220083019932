import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = ({ allowedRoles }) => {
  let token = localStorage.getItem('budget-token');
  const userRoleId = JSON.parse(localStorage.getItem('roleId'));
  const isAllowed = allowedRoles.includes(userRoleId);

  return token && isAllowed ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
