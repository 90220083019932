import React from 'react';

const Inactivebutton = () => {
  return (
    <>
      <div className="amount-inactive">Inactive</div>
    </>
  );
};
export default Inactivebutton;
