import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Bleached from '../../assests/images/icons/Bleached Cafe.png';
import { ReactComponent as Filter } from '../../assests/images/icons/Filter.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as People } from '../../assests/images/icons/People.svg';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';

const Merchats = () => {
  const { uuid } = useParams();
  const sidebarRef = useRef(null);
  const [merchant, setMerchants] = useState([]);
  const [category, setCategory] = useState([]);
  const [value, setValue] = useState('');
  const [merchantTypeFilter, setMerchantTypeFilter] = useState('All');
  const [categoryFilter, setCategoryFilter] = useState('All');
  const [filterBySpend, setFilterBySpend] = useState('All');
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [merchantCategorized, setMerchantCategorized] = useState(0);
  const [merchantUnCategorized, setMerchantUnCategorized] = useState(0);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const getMerchantsByClientBy = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Merchats_By_ClientId}${uuid}`);
      setMerchants(response?.data?.merchants);
      setMerchantUnCategorized(response?.data?.merchantUncategorized);
      setMerchantCategorized(response?.data?.merchantCategorized);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);
  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Category}${uuid}`);
      setCategory(response?.data?.data?.Expense);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  useEffect(() => {
    getCategory();
    getMerchantsByClientBy();
  }, [getMerchantsByClientBy, getCategory]);

  const updateFilteredMerchants = () => {
    const sortedMerchants =
      merchant &&
      merchant?.length > 0 &&
      merchant.slice().sort((a, b) => b.total_transaction_amount - a.total_transaction_amount);

    return (
      sortedMerchants?.length > 0 &&
      sortedMerchants?.filter((it, index) => {
        const filterMerchants = it.name.toLowerCase().includes(value.toLowerCase());
        const matchesMerchantType = merchantTypeFilter === 'All' || it.categorie_name === merchantTypeFilter;
        const matchesCategory =
          categoryFilter === 'All' ||
          (it.categorie_name !== null && it.categorie_emoji !== null && categoryFilter === 'Categorised') ||
          (it.categorie_name === null && it.categorie_emoji === null && categoryFilter === 'Un-categorised');

        let matchesFilterBySpend = true;

        if (filterBySpend === 'Top 10 merchants') {
          matchesFilterBySpend = index < 10;
        } else if (filterBySpend === 'Top 5 merchants') {
          matchesFilterBySpend = index < 5;
        }

        return matchesMerchantType && matchesCategory && matchesFilterBySpend && filterMerchants;
      })
    );
  };

  const filteredMerchant = updateFilteredMerchants();

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-merchants-main-wrapper">
              <div className="row">
                <div className="col-lg-6">
                  <div className="bold big-head-new">Your Merchants</div>
                  <div className="fst-normal setting_text_color">
                    Group your spending categories to get more confidence with your expenses.
                  </div>
                </div>
                <div className="col-lg-6 right-section search-spacing your-merchants-back-to-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Merchants 🧾"
                        description={[
                          'Your merchants are every place where you spend your money. Instead of having to categorise multiple transactions for the same merchant, you can group them all here.',
                          'New merchants are added automatically, you’ll need to categorise them into an expense category you’ve created in your budget',
                          'You can filter and sort merchants by tapping the = icon. If you want to add a new merchant manually, you can tap the + icon.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-7 col-lg-7">
                  <div className="bold heading-new">Search</div>
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={value}
                          className="search search-color-white"
                          onChange={(e) => setValue(e.target.value)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-8 merchants-inner-wrp">
                  <div className="bold heading-new">Merchants</div>

                  {merchant &&
                    filteredMerchant.length > 0 &&
                    filteredMerchant.map((it) => (
                      <div className="merchant-box mt-3">
                        <Link to={`/Merchatsdetails/${uuid}/${it?.uuid}`}>
                          <div className="d-flex">
                            <div className="img-box">
                              <img
                                src={it.logo === '' || it.logo === null ? Bleached : it.logo}
                                alt=""
                                style={{
                                  borderRadius: '50%',
                                }}
                              />
                            </div>
                            <div className="ms-3">
                              <div className="text-merchant bold black">{it?.name}</div>
                              <div className="small-text-merchant">
                                <span className="number-merchant">${it?.total_transaction_amount?.toFixed(2)}</span>
                                <span className="black">|</span>
                                <h4 className="small-text-merchant space black">
                                  {it.total_transactions} Transactions
                                </h4>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <div className="button-blck">
                          <div className="icon-tag">
                            <div
                              className="icon-transaction-img"
                              style={{
                                backgroundColor: it?.categorie_colour,
                              }}
                            >
                              {it?.categorie_emoji}
                            </div>
                            <div className="content">
                              <h6 className="icon-content ">{it?.categorie_name}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="col-lg-4
                 search-spacing filter-merchants-wrp-side"
                >
                  <div className="d-flex justify-content-between">
                    <div className="bold heading-new">Filter</div>
                    <div>
                      <Filter />
                    </div>
                  </div>

                  <div className="merchants-background-color  mt-3">
                    <div className="bold live-connection mb-3">Categories</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <button
                          className={`group-button ${merchantTypeFilter === 'All' ? 'green' : ''}`}
                          onClick={() => setMerchantTypeFilter('All')}
                        >
                          All
                        </button>
                      </div>
                      {category?.length > 0 &&
                        category.map((it) => (
                          <div className="button-blck">
                            <button
                              className={`group-button ${merchantTypeFilter === it.name ? 'green' : ''}`}
                              onClick={() => setMerchantTypeFilter(it.name)}
                            >
                              {it.name}
                            </button>
                          </div>
                        ))}
                    </div>
                    <div className="bold live-connection mb-3">Merchant type</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <button
                          className={`group-button ${categoryFilter === 'All' ? 'green' : ''}`}
                          onClick={() => setCategoryFilter('All')}
                        >
                          All
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${categoryFilter === 'Categorised' ? 'green' : ''}`}
                          onClick={() => setCategoryFilter('Categorised')}
                        >
                          Categorised
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${categoryFilter === 'Un-categorised' ? 'green' : ''}`}
                          onClick={() => setCategoryFilter('Un-categorised')}
                        >
                          Un-categorised
                        </button>
                      </div>
                    </div>
                    <div className="bold live-connection mb-3">Filter by spend</div>
                    <div className="group-btn green-btn">
                      <div className="button-blck">
                        <button
                          className={`group-button ${filterBySpend === 'All' ? 'green' : ''}`}
                          onClick={() => setFilterBySpend('All')}
                        >
                          All
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${filterBySpend === 'Top 10 merchants' ? 'green' : ''}`}
                          onClick={() => setFilterBySpend('Top 10 merchants')}
                        >
                          Top 10 merchants
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${filterBySpend === 'Top 5 merchants' ? 'green' : ''}`}
                          onClick={() => setFilterBySpend('Top 5 merchants')}
                        >
                          Top 5 merchants
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                      <div className="categorised">
                        <div className="categorised-icon">
                          <People />
                        </div>
                        <div className="text-light text-center category-text">Categorised</div>
                        <div className="fs-1 fw-bold text-light text-center">{merchantCategorized}</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-lg-6 categories-block mt-4">
                      <div className="uncategorised">
                        <div className="uncategorised-icon">
                          <People />
                        </div>
                        <div className="text-light text-center category-text">Uncategorised</div>
                        <div className="fs-1 fw-bold text-light text-center">{merchantUnCategorized}</div>
                      </div>
                    </div>
                  </div>

                  <div className="merchants-background-color  mt-3">
                    <div className="bold live-connection mb-3">Categories</div>

                    <div className="group-backgroud-color mt-2">
                      <div className="group-btn green-btn ">
                        {category &&
                          category?.length > 0 &&
                          category.map((it) => (
                            <div key={it?.userCategoryId} className="button-blck">
                              <div className="icon-tag">
                                <div
                                  className="icon-transaction-img  "
                                  style={{
                                    backgroundColor: it?.colour,
                                  }}
                                >
                                  {it?.emoji}
                                </div>
                                <div className="content">
                                  <h6 className="icon-content ">{it?.name}</h6>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Merchats;
