import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../src/assests/bootstrap/css/bootstrap.css';
import './App.css';
import './assests/css/style.css';
import BudgetEdit from './Components/BudgetEdit';
import EditClient from './Components/ClientDetails/index';
import DetailsPage from './Components/DetailPage/index';
import Account from './Components/Innerpanel/Account';
import AccountBalances from './Components/Innerpanel/AccountBalances/index';
import AddFinancial from './Components/Innerpanel/AddNewGoals';
import Addwidgets from './Components/Innerpanel/Addwdigets/Addwidgets';
import BudgetSummary from './Components/Innerpanel/BudgetSummary';
import CashFlow from './Components/Innerpanel/CashFlow';
import Cashflowcart from './Components/Innerpanel/Cashflowcalender/Cashflowcalender';
import Dashboardinner from './Components/Innerpanel/Dashboardinner';
import Editbudget from './Components/Innerpanel/Editbudget';
import EditGoalBudget from './Components/Innerpanel/EditGoalBudget';
import Financial from './Components/Innerpanel/Financial';
import GoalDetails from './Components/Innerpanel/GoalDetails';
import Groceriesedit from './Components/Innerpanel/Groceriesedit/Groceriesedit';
import NetWorth from './Components/Innerpanel/NetWorth';
import EditOneOffBudget from './Components/Innerpanel/oneOffBudget/EditOneOfBudget';
import Retirement from './Components/Innerpanel/Retirement';
import SpendingsAccount from './Components/Innerpanel/SpendingsAccount';
import Merchats from './Components/Merchats/index';
import Merchantdetails from './Components/Merchats/MerchatsDetail';
import OAuthCallbackHandler from './Components/OAuthCallbackHandler';
import Activepage from './Components/pages/Activepage';
import Clients from './Components/pages/Clients';
import Dashboard from './Components/pages/Dashboard';
import EmailForgrtPassword from './Components/pages/EmailForgrtPassword';
import EmailVerifactionOTP from './Components/pages/EmailVerifactionOTP';
import ForgotPassword from './Components/pages/ForgotPassword';
import GroupDetail from './Components/pages/GroupDetail';
import Groups from './Components/pages/Groups';
// import Login from './Components/pages/Login';
import ManageAccountLogin from './Components/pages/ManageAccountLogin';
import Newclient from './Components/pages/Newclient';
import Newgroup from './Components/pages/Newgroup';
import NotFoundPage from './Components/pages/NotFoundPage';
import PayementCancel from './Components/pages/PayementCancel';
import PayementSuccess from './Components/pages/PayementSuccess';
import PaymentsUpgradeSuccess from './Components/pages/PaymentsUpgradeSuccess';
import Register from './Components/pages/Register';
import ResetPassword from './Components/pages/ResetPassword';
import Setting from './Components/pages/Settings';
import SuccessPaymentDetail from './Components/pages/SuccessPaymentDetail';
import TestPlan from './Components/pages/TestPlan';
import AccountSetup from './Components/Plans/AccountSetup';
import AddPayments from './Components/Plans/AddPayments';
import CancelFeedback from './Components/Plans/CancelFeedback';
import CancelPlan from './Components/Plans/CancelPlan';
import CancelPlanMessage from './Components/Plans/CancelPlanMessage';
import ChangePlan from './Components/Plans/ChangePlan';
import FeedbackManage from './Components/Plans/FeedbackManage';
import FeedbackManage1 from './Components/Plans/FeedbackManage1';
import FeedbackManage2 from './Components/Plans/FeedbackManage2';
import FeedbackManage3 from './Components/Plans/FeedbackManage3';
import FeedbackManage4 from './Components/Plans/FeedbackManage4';
import FeedbackManage5 from './Components/Plans/FeedbackManage5';
import FeedbackManage6 from './Components/Plans/FeedbackManage6';
import FeedbackManage7 from './Components/Plans/FeedbackManage7';
import FeedbackThankYou from './Components/Plans/FeedbackThankYou';
import ManagePlan from './Components/Plans/ManagePlan';
import SelectNewPlan from './Components/Plans/SelectNewPlan';
import SelectPlans from './Components/Plans/SelectPlans';
import UpdatePlan from './Components/Plans/UpdatePlan';
import PrivateRoutes from './Components/Private';
import Email2FAVerifaction from './Components/Setting/Email2FAVerifaction';
import Email2FAVerifactionDone from './Components/Setting/Email2FAVerifactionDone';
import Email2FAVerifactionOTP from './Components/Setting/Email2FAVerifactionOTP';
import SettingPage from './Components/Setting/index';
import EditCompany from './Components/SuperAdminDashboard/EditSuperAdmin/EditCompany';
import EditPlans from './Components/SuperAdminDashboard/EditSuperAdmin/EditPlans';
import NewCompany from './Components/SuperAdminDashboard/NewCompany';
import NewPlans from './Components/SuperAdminDashboard/NewPlans';
import SuperAdminDashboard from './Components/SuperAdminDashboard/SuperAdminDashboard';
import CompaniesList from './Components/SuperAdminDashboard/SuperAdminLists/CompaniesList';
import SuperAdminPlans from './Components/SuperAdminDashboard/SuperAdminLists/SuperAdminPlans';
import AddTransaction from './Components/Transactions/AddTransaction';
import Transactions from './Components/Transactions/index';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes allowedRoles={[2, 3, 4, 5]} />}>
            <Route path="/Dashboard" element={<Dashboard />}></Route>
            <Route path="/Clients" element={<Clients />}></Route>
            <Route path="/Groups" element={<Groups />}></Route>
            <Route path="/Setting" element={<Setting />}></Route>
            <Route path="/Clients/Newclient" element={<Newclient />}></Route>
            <Route path="/Clients/Activepage/:uuid" element={<Activepage />}></Route>
            <Route path="/Clients/Edit/:id" element={<EditClient />}></Route>
            <Route path="/Clients/Clientdetails" element={<Activepage />}></Route>
            <Route path="/Groups/GroupDetail/:id" element={<GroupDetail />}></Route>
            <Route path="/Groups/Details" element={<DetailsPage />}></Route>
            <Route path="/Groups/Newgroup" element={<Newgroup />}></Route>
            <Route path="/Dashbaordpanel/:uuid" element={<Dashboardinner />}></Route>
            <Route path="/Merchats/:uuid" element={<Merchats />}></Route>
            <Route path="/Merchatsdetails/:uuid/:merchantsId" element={<Merchantdetails />}></Route>
            <Route path="/Transactions/:uuid" element={<Transactions />}></Route>
            <Route path="/AddTransaction/:uuid" element={<AddTransaction />}></Route>
            <Route path="/AccountBalances/:uuid" element={<AccountBalances />}></Route>
            <Route path="/SettingPage/:uuid" element={<SettingPage />}></Route>
            <Route path="/Email2FAVerifaction/:uuid" element={<Email2FAVerifaction />}></Route>
            <Route path="/Email2FAVerifactionOTP/:uuid" element={<Email2FAVerifactionOTP />}></Route>
            <Route path="/Email2FAVerifactionDone/:uuid" element={<Email2FAVerifactionDone />}></Route>
            <Route path="/BudgetSummary/:uuid" element={<BudgetSummary />}></Route>
            <Route path="/Editbudget/:uuid" element={<Editbudget />}></Route>
            <Route path="/Groceriesedit" element={<Groceriesedit />}></Route>
            <Route path="/NetWorth/:uuid" element={<NetWorth />}></Route>
            <Route path="/Financial/:uuid" element={<Financial />}></Route>
            <Route path="/Retirement/:uuid" element={<Retirement />}></Route>
            <Route path="/CashFlow/:uuid" element={<CashFlow />}></Route>
            <Route path="/SpendingsAccount/:uuid" element={<SpendingsAccount />}></Route>
            <Route path="/Account/:uuid" element={<Account />}></Route>
            <Route path="/Clients/Cashflowcalender" element={<Cashflowcart />}></Route>
            <Route path="/Financial/AddNewFinancial/:uuid" element={<AddFinancial />}></Route>
            <Route path="/Financial/GoalDetails/:uuid/:goalUuid" element={<GoalDetails />}></Route>
            <Route path="/EditGoal/:goalUuid" element={<EditGoalBudget />}></Route>
            <Route path="/EditOneOffBudget/:oneOfBudgetUuid/:uuid" element={<EditOneOffBudget />}></Route>
            <Route path="/Addwidgets" element={<Addwidgets />}></Route>
            <Route path="/akahu" element={<OAuthCallbackHandler />}></Route>
            <Route path="/BudgetEdit/:uuid/:expenseUuid" element={<BudgetEdit />}></Route>
            {/* Super admin side */}
          </Route>
          <Route element={<PrivateRoutes allowedRoles={[1]} />}>
            <Route path="/SuperAdmin" element={<SuperAdminDashboard />} />
            <Route path="/Companies" element={<CompaniesList />}></Route>
            <Route path="/Companies/NewCompany" element={<NewCompany />}></Route>
            <Route path="/Plans" element={<SuperAdminPlans />}></Route>
            <Route path="/Plans/NewPlans" element={<NewPlans />}></Route>
            <Route path="/Companies/EditCompany" element={<EditCompany />}></Route>
            <Route path="/Plans/EditPlans/:id" element={<EditPlans />}></Route>
          </Route>
          {/* <Route path="/" element={<Login />}></Route> */}
          <Route path="/ForgotPassword" element={<ForgotPassword />}></Route>
          <Route path="/" element={<Register />}></Route>
          <Route path="/emailForgetPassword/:email" element={<EmailForgrtPassword />}></Route>
          <Route path="/resetPassword/:email" element={<ResetPassword />}></Route>
          <Route path="/selectPlans" element={<SelectPlans />}></Route>
          <Route path="/selectNewPlan" element={<SelectNewPlan />}></Route>
          <Route path="/changePlans" element={<ChangePlan />}></Route>
          <Route path="/updatePlan" element={<UpdatePlan />}></Route>
          <Route path="/payments" element={<AddPayments />}></Route>
          <Route path="/accountSetup" element={<AccountSetup />}></Route>
          <Route path="/feedbackManage" element={<FeedbackManage />}></Route>
          <Route path="/feedbackManage1" element={<FeedbackManage1 />}></Route>
          <Route path="/feedbackManage2" element={<FeedbackManage2 />}></Route>
          <Route path="/feedbackManage3" element={<FeedbackManage3 />}></Route>
          <Route path="/feedbackManage4" element={<FeedbackManage4 />}></Route>
          <Route path="/feedbackManage5" element={<FeedbackManage5 />}></Route>
          <Route path="/feedbackManage6" element={<FeedbackManage6 />}></Route>
          <Route path="/feedbackManage7" element={<FeedbackManage7 />}></Route>
          <Route path="/feedbackThankYou" element={<FeedbackThankYou />}></Route>
          <Route path="/managePlan" element={<ManagePlan />}></Route>
          <Route path="/cancelPlan" element={<CancelPlan />}></Route>
          <Route path="/cancelPlanFeedback" element={<CancelFeedback />}></Route>
          <Route path="/testPlan" element={<TestPlan />}></Route>
          <Route path="/success" element={<PayementSuccess />}></Route>
          <Route path="/successPaymentDetail" element={<SuccessPaymentDetail />}></Route>
          <Route path="/paymentSuccess" element={<PaymentsUpgradeSuccess />}></Route>
          <Route path="/cancel" element={<PayementCancel />}></Route>
          {/* <Route path="/login" element={<Login />}></Route> */}
          <Route path="/manageAccountLogin" element={<ManageAccountLogin />}></Route>
          <Route path="/emailVerifaction" element={<EmailVerifactionOTP />}></Route>
          <Route path="/cancelPlanMessage" element={<CancelPlanMessage />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
