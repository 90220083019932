import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Tableimg from '../../assests/images/icons/profile.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import { statusArray } from '../../assests/mock/Status';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import weburls, { BudgetBuddie } from '../Weburls/weburls';
import Newclient from './Newclient';

const Clients = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [isValue, setValue] = useState('');
  const [tableFilter, setTableFilter] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const navigate = useNavigate();
  const toggleBanner = () => {
    setShowBanner(showBanner);
  };
  const companyId = JSON.parse(localStorage.getItem('userData'));
  const activeChange = (UUID) => {
    navigate(`/Clients/Activepage/${UUID}`);
  };
  const handleGroupClick = async (groupName) => {
    setSelectedGroup(groupName);
    const response = await axios.get(
      `${BudgetBuddie}api/clients/${companyId.companyId}/filter/?groupName=${groupName}&planName=${
        selectedPlan ? selectedPlan : ''
      }&companyId=${companyId.companyId}&status=${selectedStatus ? selectedStatus : ''}`,
    );
    setTableFilter(response.data.clients);
  };

  const handlePlanClick = async (planId) => {
    setSelectedPlan(planId);
    const response = await axios.get(
      `${BudgetBuddie}api/clients/${companyId.companyId}/filter/?groupName=${
        selectedGroup ? selectedGroup : ''
      }&planId=${planId}&companyId=${companyId.companyId}&status=${selectedStatus ? selectedStatus : ''}`,
    );
    setTableFilter(response.data.clients);
  };

  const handleStatus = async (status) => {
    setSelectedStatus(status);
    const response = await axios.get(
      `${BudgetBuddie}api/clients/${companyId.companyId}/filter/?groupName=${
        selectedGroup ? selectedGroup : ''
      }&planId=${selectedPlan ? selectedPlan : ''}&companyId=${companyId.companyId}&status=${status}`,
    );
    setTableFilter(response.data.clients);
  };
  const filteredClients =
    tableFilter &&
    tableFilter?.length > 0 &&
    tableFilter?.filter((cl) => cl.firstName.toLowerCase().includes(isValue.toLowerCase()));

  const getPlanList = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data);
  };
  const fetchedData = useCallback(async () => {
    try {
      const response = await axios.get(weburls.Get_clients + `${companyId.companyId}`);
      localStorage.setItem('client-userUuid', JSON.stringify(response.data.clients));
      if (response?.data?.clients?.length > 0) {
        setTableFilter(response.data.clients);
      }
    } catch (error) {}
  }, [companyId.companyId]);

  const getGroups = useCallback(async () => {
    try {
      const response = await axios.post(weburls.Group_list, {
        advisorId: companyId.advisorId,
        companyId: companyId.companyId,
      });
      if (response?.data?.length > 0) {
        setGroupList(response.data);
      }
    } catch (err) {
      toast.error(`Error fetching data: ${err}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [companyId.advisorId, companyId.companyId]);

  useEffect(() => {
    fetchedData();
    getGroups();
    getPlanList();
  }, [fetchedData, getGroups]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(filteredClients?.length > 0 && filteredClients?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data && data?.length > 0 && data.slice(indexOfFirstItem, indexOfLastItem);
  };

  const next = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const renderedClients = paginate(filteredClients);
  return (
    <>
      <div className="container-fluid">
        <Header toggleBanner={toggleBanner} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer your-clients-list-section-wrapper">
            <div className="row group-wrp">
              <div className="col-lg-8 top-heading-wrp-main">
                <div className="fs-3 bold black your-clients-title">Your Clients</div>
                <div className="setting_text_color">Here's a list of each of your client using BudgetBuddie.</div>
              </div>

              <div className="col-lg-4 search-spacing">
                <div className="search-block">
                  <form>
                    <Search />
                    <input
                      type="text"
                      className="search"
                      value={isValue}
                      placeholder="Search for a client here"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-3 heading-new">Filter Clients</div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Group</div>
                  <div className="group-btn">
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedGroup === '' ? 'purple' : ''}`}
                        onClick={() => handleGroupClick('')}
                      >
                        All
                      </button>
                    </div>
                    {groupList &&
                      groupList?.length > 0 &&
                      groupList?.map((it) => (
                        <div className="button-blck" key={it.groupName}>
                          <button
                            className={`group-button ${selectedGroup === it.groupName ? 'purple' : ''}`}
                            onClick={() => handleGroupClick(it.groupName)}
                          >
                            {it.groupName}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 plan-section-area">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Plan</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedPlan === '' ? 'dark-yellow' : ''}`}
                        onClick={() => handlePlanClick('')}
                      >
                        All
                      </button>
                    </div>
                    {planList &&
                      planList.length > 0 &&
                      [...new Set(planList.map((item) => item.planId))].map((planId) => (
                        <div className="button-blck" key={planId}>
                          <button
                            className={`group-button ${selectedPlan === planId ? 'dark-yellow' : ''}`}
                            onClick={() => handlePlanClick(planId)}
                          >
                            {planList.find((item) => item.planId === planId)?.planName}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3 mb-3">Status</div>
                  <div class="group-btn green-btn">
                    <div class="button-blck">
                      <button
                        class={`${selectedStatus === null ? 'green' : ''} group-button`}
                        onClick={() => handleStatus(null)}
                      >
                        All
                      </button>
                    </div>
                    {statusArray &&
                      statusArray.length > 0 &&
                      statusArray.map((item) => (
                        <div class="button-blck">
                          <button
                            class={`${selectedStatus === item.id ? 'green' : ''} group-button`}
                            onClick={() => handleStatus(item.id)}
                          >
                            {item.title}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp mt-2 your-clients-list-table-wrapper">
              <div class="row">
                <div class="col-12">
                  <table className="table-image">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Group</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Frequency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="space-table">
                      {renderedClients && renderedClients?.length > 0 ? (
                        renderedClients?.map((item) => (
                          <tr className="cursor-pointer" key={item.id}>
                            <th>
                              <div className="air-image">
                                <div className="air-image" onClick={() => activeChange(item.userUuid)}>
                                  <img src={Tableimg} alt="table-img" />
                                  <h3 className="table-content display">{item.firstName}</h3>
                                </div>
                                <Link className="amount-active dark-green" to={`/Dashbaordpanel/${item.userUuid}`}>
                                  Open <i class="fa-solid fa-arrow-right"></i>
                                </Link>
                              </div>
                            </th>
                            <td className="table-text-color">{item.groupName}</td>
                            <td className="table-text-color">{item.planName}</td>
                            <td className="table-text-color">{item.planFrequency}</td>
                            <td>
                              {item.status === 1 ? (
                                <div className="active-status">
                                  <button className="active-btn table-text-color">
                                    <i class="fa-solid fa-circle"></i> Active
                                  </button>
                                </div>
                              ) : item.status === 3 ? (
                                <div className="orange-status">
                                  <button className="not-inactive-button table-text-color">
                                    <i class="fa-solid fa-circle"></i> Not-Activated
                                  </button>
                                </div>
                              ) : (
                                <div className="in-active-status-div">
                                  <button className="inactive-button table-text-color">
                                    <i class="fa-solid fa-circle"></i> Inactive
                                  </button>
                                </div>
                              )}
                            </td>
                            <td className="table-text-color">{item.email}</td>
                            <td>
                              <Link to={`/Clients/Edit/${item.userUuid}`}>
                                <Edit />
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div className="no-data-found-td">No data found</div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="pagination"
              style={{
                justifyContent: 'end',
                marginRight: '32px',
                marginTop: '60px',
                width: '100%',
              }}
            >
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={prev}>
                    Previous
                  </button>
                </li>
                {pageNumbers &&
                  pageNumbers.length > 0 &&
                  pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={next}>
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Newclient show={showBanner} />
      </div>
    </>
  );
};

export default Clients;
