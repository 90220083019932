import React, { useEffect } from 'react';

function OverlayContainer(props) {
  // To lock scroll when the overlay is poped up
  useEffect(() => {
    document.body.classList.add('locked');
    return () => {
      document.body.classList.remove('locked');
    };
  }, []);
  return <div className="overlay">{props.children}</div>;
}

export default OverlayContainer;
