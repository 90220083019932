import axios from 'axios';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupColorModal from '../Popup/PopupColorModel';
import weburls from '../Weburls/weburls';

export const EditGoal = ({ goal, onCancel, onSave }) => {
  const [editedGoal, setEditedGoal] = useState({ ...goal });
  const [selectedTab, setSelectedTab] = useState(editedGoal.howOften);
  const [accountData, setAccountData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(editedGoal.accountUuid);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const { uuid } = useParams();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedGoal({ ...editedGoal, [name]: value });
  };
  const colorCss = (color) => {
    // Define colorCss
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
      case 'Red':
        return 'red-color';
      case 'Yellow':
        return 'yellow-color';
      case 'Blue':
        return 'blue-color';
      case 'Purple':
        return 'purple-color-color';
      case 'Orange':
        return 'orange-color';
      case 'Pink':
        return 'pink-color';
      case 'Light blue':
        return 'light-blue-color';
      case 'Lime green':
        return 'lime-green-color';
      case 'Dark red':
        return 'dark-red-color';
      case 'Green':
        return 'green-color';
      case 'Dark blue':
        return 'dark-blue-color';
      default:
        return 'green';
    }
  };
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const handleOptionColorClick = (option) => {
    setEditedGoal({ ...editedGoal, colour: option });
    setPopupOpenColor(false);
  };

  const handleSave = () => {
    onSave(editedGoal);
  };

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setEditedGoal({ ...editedGoal, howOften: tabName });
  };
  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
    setEditedGoal({ ...editedGoal, accountUuid: e.target.value });
  };
  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setAccountData(response?.data?.accounts);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);
  return (
    <>
      <div className="mt-3 edit-goal-ami active-edit-goal">
        <div>
          <div className="bold black sub-head-text inter">Edit Goal</div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Name</div>
            <input
              type="text"
              name="goalName"
              value={editedGoal.goalName}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Goal Dollar Value</div>
            <input
              type="text"
              name="value"
              value={editedGoal.value}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Contributions</div>
            <input
              type="text"
              name="contribution"
              value={editedGoal.contribution}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">How Often?</div>
            <div
              className="col-md-9 col-lg-9 togger-wrp-financial"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <ul className="nav nav-pills budget-tabs img-back" id="pills-tab" role="tablist">
                <li className={`nav-item-financial ${selectedTab === 'Weekly' ? 'active' : ''}`} role="presentation">
                  <button
                    className={`nav-link inter ${selectedTab === 'Weekly' ? 'active' : ''}`}
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected={selectedTab === 'Weekly'}
                    onClick={() => handleTabChange('Weekly')}
                  >
                    Weekly
                  </button>
                </li>
                <li
                  className={`nav-item-financial ${selectedTab === 'Fortnightly' ? 'active' : ''}`}
                  role="presentation"
                >
                  <button
                    className={`nav-link inter ${selectedTab === 'Fortnightly' ? 'active' : ''}`}
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected={selectedTab === 'Fortnightly'}
                    onClick={() => handleTabChange('Fortnightly')}
                  >
                    Fortnightly
                  </button>
                </li>
                <li className={`nav-item-financial ${selectedTab === 'Monthly' ? 'active' : ''}`} role="presentation">
                  <button
                    className={`nav-link inter ${selectedTab === 'Monthly' ? 'active' : ''}`}
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected={selectedTab === 'Monthly'}
                    onClick={() => handleTabChange('Monthly')}
                  >
                    Monthly
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="edit-goal-box d-flex justify-content-between align-items-center">
            <div className="fw-bold inter">Start Date</div>
            <input
              type="date"
              name="startDate"
              value={moment(editedGoal.startDate).format('YYYY-MM-DD')}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between align-items-center">
            <div className="fw-bold inter">End Date</div>
            <input
              type="date"
              name="endDate"
              value={moment(editedGoal.endDate).format('YYYY-MM-DD')}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
            />
          </div>

          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Start Balance</div>
            <input
              type="text"
              name="currentBalance"
              value={editedGoal.currentBalance}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Reference</div>
            <input
              type="text"
              name="reference"
              value={editedGoal.reference}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
              disabled
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Colour</div>
            <div
              className="color-option edit-goal-color"
              style={{
                width: '0%',
                margin: 'auto',
              }}
            >
              <div className="dropdown">
                <button
                  type="button"
                  className={`group-button ${colorCss(editedGoal.colour)} popoup-color`}
                  onClick={togglePopupColor}
                  aria-haspopup="true"
                  aria-expanded={isPopupOpenColor}
                >
                  {editedGoal.colour}
                </button>

                {isPopupOpenColor && (
                  <PopupColorModal
                    isOpen={isPopupOpenColor}
                    onClose={togglePopupColor}
                    title="Date Range"
                    options={[
                      'Dark green',
                      'Red',
                      'Yellow',
                      'Blue',
                      'Purple',
                      'Orange',
                      'Pink',
                      'Light blue',
                      'Lime green',
                      'Dark red',
                      'Green',
                      'Dark blue',
                    ]}
                    selectedOption={editedGoal.colorOption}
                    handleOptionClick={handleOptionColorClick}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Icon</div>
            <input
              type="text"
              name="icon"
              value={editedGoal.icon}
              onChange={handleInputChange}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <button onClick={handleSave} className="btn btn-primary">
              Save
            </button>
            <button onClick={onCancel} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
