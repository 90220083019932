import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Airnewzealand from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Download } from '../../assests/images/icons/download.svg';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import profileImage from '../../assests/images/icons/profileimage.png';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../Header/Header';
import weburls from '../Weburls/weburls';

const Activepage = ({ show }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [data, setData] = useState({});
  const { uuid } = useParams();

  const toggleBanner = () => {
    setShowBanner(showBanner);
  };

  const settingApi = useCallback(async () => {
    const response = await axios.get(weburls.Get_clients_by_uuid + `${uuid}`);
    if (response?.data) {
      setData(response?.data);
    }
  }, [uuid]);

  useEffect(() => {
    settingApi();
  }, [settingApi]);

  return (
    <>
      <div className="container-fluid">
        <Header toggleBanner={toggleBanner} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer client-summary-page-main">
            <div className="row">
              <div className="col-md-12">
                <h3 className="small-heading">
                  <Link to="/Clients" className="grey-color">
                    <i class="fa-solid fa-arrow-left"></i> Back to clients
                  </Link>
                </h3>
              </div>
            </div>
            <div className="row top-wrapper-client-summary">
              <div className="col-md-6 mt-3 main-heading-profile-img">
                <div className="profile-dashboard ">
                  <div className="profile-dashboard">
                    <img src={profileImage} alt="" />
                  </div>
                  <div className="profile-dashboard">
                    <h4 className="client-name">{`${data.firstName} ${data.lastName}`}</h4>
                  </div>
                  <div className="profile-dashboard">
                    {data.status === 1 ? (
                      <div className="active-status">
                        <button className="active-btn table-text-color">
                          <i class="fa-solid fa-circle"></i> Active
                        </button>
                      </div>
                    ) : data.status === 2 ? (
                      <div className="orange-status">
                        <button className="inactive-button table-text-color">
                          <i class="fa-solid fa-circle"></i> Inactive
                        </button>
                      </div>
                    ) : (
                      <div className="in-active-status-div">
                        <button className="not-inactive-button table-text-color">
                          <i class="fa-solid fa-circle"></i> Not-Activated
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="profile-dashboard">
                    <Link to={`/Clients/Edit/${data.userUuid}`}>
                      <Edit />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-3 financial">
                <div className="view-financials">
                  <Link to={`/Dashbaordpanel/${uuid}`} className="white">
                    View their BudgetBuddie Financials
                  </Link>
                  <i class="fa-solid fa-arrow-right ms-4"></i>
                </div>
              </div>
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-6 col-sm-12 col-xl-4 first-card-details mb-4">
                <div className="total-clients ps-4">
                  <div className="bold card-title-client black">Details</div>
                  <div className="d-flex mt-3 card-inner-text">
                    <div className="bold">Name : &nbsp;</div>
                    <div>{`${data.firstName} ${data.lastName}`}</div>
                  </div>
                  <div className="d-flex mt-3 card-inner-text">
                    <div className="bold">Email : &nbsp;</div>
                    <div>{data.email}</div>
                  </div>
                  <div className="d-flex mt-3 card-inner-text">
                    <div className="bold">Phone number : &nbsp;</div>
                    <div>{data.phoneNumber}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xl-3 first-card-details mb-4">
                <Link to="/Groups/Details">
                  <div className="total-clients">
                    <div className="bold card-title-client black">Group</div>
                    <div className="air-new">
                      <div className="air-image">
                        <img
                          src={data.groupLogo === '' || data.groupLogo === null ? Airnewzealand : data.groupLogo}
                          alt="Air"
                          style={{
                            borderRadius: '50px',
                          }}
                        />
                      </div>
                      <div className="air-new-zealand">{data.groupName}</div>
                    </div>
                    <div className="mt-3">
                      <button className="active-btn bold">
                        <i class="fa-solid fa-circle"></i> Active
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-6 col-sm-12 col-xl-3 first-card-details mb-4">
                <div className="total-clients">
                  <div className="bold card-title-client black">Plan details</div>
                  <div className="pro-plan ">
                    <div className="air-new">{data.planName ? `${data.planName} Plan 💸` : ''} </div>
                  </div>
                  <div className="annual-pro-plan light-black">{data.planMode} </div>
                  <div className="d-flex">
                    <div className="bold renews-plan black  ">Renews</div>
                    <div className="plan-date black">29/10/2023</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xl-2 mb-4">
                <Link to={`/Dashbaordpanel/${uuid}`}>
                  <div className="total-clients share-status-wrp">
                    <div className="bold text-center card-title-client black">Share status</div>
                    <div className="share-number-open">Open</div>
                    <button className="comn-btn">View Financials</button>
                  </div>
                </Link>
              </div>
            </div>
            <div className="bold mt-5 main-heading-title-group black">Reports</div>
            <div className="setting_text_color">View and download reports about the clients financials.</div>
            <div className="row mt-2">
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Dashboard</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of the users Dashboard.</div>
                </div>
              </div>
              <div className="col-4 mb-3 report-card-main-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-7 report-card-inner">
                      <div className="bold report-card-title">Budget</div>
                    </div>
                    <div className="col-md-4 weekly-wrp">
                      <div className="slecet-wrp">
                        <select>
                          <option>This week</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their spend vs budget.</div>
                </div>
              </div>
              <div className="col-4 mb-3 report-card-main-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-7 report-card-inner">
                      <div className="bold report-card-title">Cashflow</div>
                    </div>
                    <div className="col-md-4 weekly-wrp">
                      <div className="slecet-wrp">
                        <select>
                          <option>Weekly</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their cashflow analysis.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Net Worth</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their net worth.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Goals</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their goals progress.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Retirement</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their retirement plan.</div>
                </div>
              </div>
              <div className="col-4 mb-3 report-card-main-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-7 report-card-inner">
                      <div className="bold report-card-title">Transactions</div>
                    </div>
                    <div className="col-md-4 weekly-wrp">
                      <div className="slecet-wrp">
                        <select>
                          <option>Last month</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their transactions.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Merchants</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their merchants.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Accounts</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their accounts.</div>
                </div>
              </div>
              <div className="col-4 mb-3 report-card-main-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-7 report-card-inner">
                      <div className="bold report-card-title">Income vs Expenses</div>
                    </div>
                    <div className="col-md-4 weekly-wrp">
                      <div className="slecet-wrp">
                        <select>
                          <option>Last month</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their income vs expenses.</div>
                </div>
              </div>
              <div className="col-4 mb-3 report-card-main-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-7 report-card-inner">
                      <div className="bold report-card-title">Spending</div>
                    </div>
                    <div className="col-md-4 weekly-wrp">
                      <div className="slecet-wrp">
                        <select>
                          <option>Last month</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their spending summary.</div>
                </div>
              </div>
              <div className="col-4 mb-3 first-report-card-wrp">
                <div className="report-card">
                  <div className="row">
                    <div className="col-md-11 title-dashboard">
                      <div className="bold report-card-title">Insurance, kiwisaver & mortgage</div>
                    </div>
                    <div className="col-md-1">
                      <div>
                        <Download />
                      </div>
                    </div>
                  </div>
                  <div className="setting_text_color">Get a report of their insurance, kiwisaver & mortgage.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activepage;
