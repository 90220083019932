import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tableimg from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../Header/Header';
import LoadingTxt from '../Loading/LoadingTxt';
import weburls, { BudgetBuddie } from '../Weburls/weburls';

const Groups = () => {
  const [showGroup, setShowGroup] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const companyId = JSON.parse(localStorage.getItem('userData'));

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [groupClients, setGroupWithClient] = useState([]);
  const [selectedBillFrequency, setSelectedBillFrequency] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const handleGroupClick = async (groupName) => {
    setSelectedGroup(groupName);
    const response = await axios.get(
      `${BudgetBuddie}api/groups/filter/?groupName=${groupName}&companyId=${companyId.companyId}&BillFrequencyName=${
        selectedBillFrequency ? selectedBillFrequency : ''
      }&status=${selectedStatus ? selectedStatus : ''}`,
    );
    setGroupWithClient(response.data);
    setLoading(false);
  };

  const handleBillFrequency = async (BillFrequencyName) => {
    setSelectedBillFrequency(BillFrequencyName);
    const response = await axios.get(
      `${BudgetBuddie}api/groups/filter/?groupName=${
        selectedGroup ? selectedGroup : ''
      }&companyId=${companyId.companyId}&BillFrequencyName=${BillFrequencyName}&status=${selectedStatus ? selectedStatus : ''}`,
    );

    setGroupWithClient(response.data);
    setLoading(false);
  };

  const handleStatus = async (status) => {
    setSelectedStatus(status);
    const response = await axios.get(
      `${BudgetBuddie}api/groups/filter/?groupName=${selectedGroup ? selectedGroup : ''}&companyId=${companyId.companyId}&BillFrequencyName=${
        selectedBillFrequency ? selectedBillFrequency : ''
      }&status=${status}`,
    );

    setGroupWithClient(response.data);
    setLoading(false);
  };

  const navigate = useNavigate();
  const activeChange = () => {
    navigate('/Groups/Details');
  };

  const Group = () => {
    setShowGroup(showGroup);
  };

  const filteredGroups =
    groupClients &&
    groupClients?.length > 0 &&
    groupClients?.filter((group) => group.group_name.toLowerCase().includes(searchText.toLowerCase()));

  const groupDetailsWithClients = async () => {
    const response = await axios.get(`${weburls.Get_Group_With_Clients}?companyId=${companyId.companyId}`);
    if (response?.data) {
      setGroupWithClient(response.data);
      setGroupList(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    groupDetailsWithClients();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(filteredGroups.length > 0 && filteredGroups?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginate = (data) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data && data?.length > 0 && data.slice(indexOfFirstItem, indexOfLastItem);
  };

  const next = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderedGroups = paginate(filteredGroups);
  return (
    <>
      <div className="container-fluid">
        <Header Group={Group} />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer your-groups-main-front-wrapper">
            <div className="row group-wrp">
              <div className="col-lg-8">
                <div className="fs-3 bold black main-heading-title-group">Your Groups</div>
                <div className="setting_text_color">Here’s a list of your groups using BudgetBuddie.</div>
              </div>
              <div className="col-xl-4 search-spacing col-md-12 col-sm-12">
                <div className="search-block">
                  <form>
                    <Search />
                    <input
                      type="text"
                      className="search"
                      placeholder="Search for a group here"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-3 heading-new">Filter Clients</div>
            <div className="row">
              <div className="col-lg-6 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Group</div>
                  <div className="group-btn">
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedGroup === '' ? 'purple' : ''}`}
                        onClick={() => handleGroupClick('')}
                      >
                        All
                      </button>
                    </div>
                    {groupList &&
                      groupList?.length > 0 &&
                      groupList?.map((it) => (
                        <div className="button-blck" key={it.group_name}>
                          <button
                            className={`group-button ${selectedGroup === it.group_name ? 'purple' : ''}`}
                            onClick={() => handleGroupClick(it.group_name)}
                          >
                            {it.group_name}
                          </button>
                        </div>
                      ))}
                  </div>

                  <div className="group-btn"></div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Bill Frequency</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedBillFrequency === '' ? 'dark-yellow' : ''}`}
                        onClick={() => handleBillFrequency('')}
                      >
                        All
                      </button>
                    </div>
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedBillFrequency === 'monthly' ? 'dark-yellow' : ''}`}
                        onClick={() => handleBillFrequency('monthly')}
                      >
                        Monthly
                      </button>
                    </div>
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedBillFrequency === 'yearly' ? 'dark-yellow' : ''}`}
                        onClick={() => handleBillFrequency('yearly')}
                      >
                        Annual
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 filter-clients-top-wrapper">
                <div className="group-backgroud-color">
                  <div className="bold mb-3 m-left">Status</div>
                  <div className="group-btn green-btn">
                    <div className="button-blck">
                      {/* handleStatus */}
                      <button
                        className={`group-button ${selectedStatus === '' ? 'green' : ''}`}
                        onClick={() => handleStatus('')}
                      >
                        All
                      </button>
                    </div>
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedStatus === 1 ? 'green' : ''}`}
                        onClick={() => handleStatus(1)}
                      >
                        Active
                      </button>
                    </div>
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedStatus === 2 ? 'green' : ''}`}
                        onClick={() => handleStatus(2)}
                      >
                        Inactive
                      </button>
                    </div>
                    <div className="button-blck">
                      <button
                        className={`group-button ${selectedStatus === 3 ? 'green' : ''}`}
                        onClick={() => handleStatus(3)}
                      >
                        Not-Activated
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp mt-2 your-groups-page-table-list">
              <div class="row">
                <div class="col-12">
                  <table class="table-image">
                    <thead className="purple">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Amount of users</th>
                        <th scope="col">Bill Frequency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    {filteredGroups?.length > 0 ? (
                      <tbody className="space-table">
                        {renderedGroups &&
                          renderedGroups?.length > 0 &&
                          renderedGroups.map((item, index) => {
                            return (
                              <tr className="cursor-pointer" key={index}>
                                <th>
                                  <div className="air-image" onClick={activeChange}>
                                    <img
                                      src={
                                        item.group_logo === '' || item.group_logo === null ? Tableimg : item.group_logo
                                      }
                                      alt="table-img"
                                      style={{
                                        borderRadius: '50%',
                                      }}
                                    />
                                    <h3 className="table-content display">{item.group_name}</h3>
                                  </div>
                                </th>
                                <td className="table-text-color">{item.client_count}</td>
                                <td className="table-text-color">
                                  {item?.plan_mode === 'monthly' ? 'Monthly' : 'Annual'}
                                </td>
                                <td>
                                  {item?.status === 1 && (
                                    <div className="active-status">
                                      <button className="active-btn table-text-color">
                                        <i class="fa-solid fa-circle"></i> Active
                                      </button>
                                    </div>
                                  )}
                                  {item?.status === 2 && (
                                    <div className="in-active-status-div">
                                      <button className="inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Inactive
                                      </button>
                                    </div>
                                  )}
                                  {item?.status === 3 && (
                                    <div className="in-active-status-div">
                                      <button className="not-inactive-button table-text-color">
                                        <i class="fa-solid fa-circle"></i> Not-Activated
                                      </button>
                                    </div>
                                  )}
                                </td>
                                <td className="table-text-color">{item.group_email}</td>
                                <td>
                                  <Link to={`/Groups/GroupDetail/${item.group_id}`}>
                                    <Edit />
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : loading ? (
                      <div className="no-data-found-td">
                        <LoadingTxt />
                      </div>
                    ) : (
                      <div className="no-data-found-td">No group found</div>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div
              className="pagination"
              style={{
                justifyContent: 'end',
                marginRight: '32px',
                marginTop: '60px',
                width: '100%',
              }}
            >
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={prev}>
                    Previous
                  </button>
                </li>
                {pageNumbers &&
                  pageNumbers.length > 0 &&
                  pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(number)}>
                        {number}
                      </button>
                    </li>
                  ))}
                <li className={`page-item ${currentPage === pageNumbers.length ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={next}>
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Groups;
