import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  assetsName: yup.string().required('*Please enter assets name'),
  value: yup
    .string()
    .required('*Please enter asset value')
    .matches(/^[0-9]+$/, '*Please enter number only'),

  // amount: yup
  //   .string()
  //   .required('*Please enter asset amount')
  //   .matches(/^[0-9]+$/, '*Please enter number only'),
});

const EditAssets = ({ modalOpen, setModalOpen, fetchAssets, assetsUuid, uuid }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [editAssetData, setEditAssetData] = useState({
    userUuid: '',
    assetsUuid: assetsUuid,

    assetsName: '',
    value: '',
    amount: '',
    assetImage: '',
    assetAvatar: '',
  });
  const [formError, setformError] = useState('');
  const [assetAvatar, setAssetAvatar] = useState();

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const fetchData = () => {
    axios
      .get(`${weburls.Get_Asset_By_Id}${assetsUuid}`)
      .then((response) => {
        setEditAssetData(response?.data);
      })
      .catch((error) => {
        console.error('Error fetching  data:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validateInput = (fieldName, value) => {
    if (!value) {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase()} is required`,
      }));
    } else {
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };
  const onSubmitData = async (event) => {
    event.preventDefault();
    const updatedData = {
      ...editAssetData,
      assetsName: event.target.assetsName.value,
      value: event.target.value.value,
      amount: event.target.amount.value,
    };
    try {
      const formData = new FormData();
      formData.append('amount', updatedData?.amount);
      formData.append('assetsId', updatedData?.assetsId);
      formData.append('assetsName', updatedData?.assetsName);
      formData.append('assetsUuid', updatedData?.assetsUuid);
      formData.append('userUuid', uuid);
      formData.append('value', updatedData?.value);
      formData.append('assetAvatar', assetAvatar);
      const response = await axios.put(weburls.Update_Asset, formData);
      if (response.status === 200) {
        toast.success(response.data.message || 'Assets update successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setModalOpen(false);
        fetchAssets();
      } else if (response.status === 500) {
        toast.error(`Something went wrong`, {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('error', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
    reset();
  };

  const handleDeleteAssets = async () => {
    try {
      const response = await axios.delete(`${weburls.Delete_Asset}${uuid}/${assetsUuid}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Assets delete successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setModalOpen(false);
        fetchAssets();
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  const upload = (file) => {
    setAssetAvatar(file.target.files[0]);
  };
  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Edit your assets</span>
            </div>
          </div>
          <hr />
          <form onSubmit={onSubmitData}>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Assets details</h5>
                <div className="setting_text_color popup-text">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="assetsName"
                        class="form-control input_border"
                        placeholder="Assets name"
                        defaultValue={editAssetData?.assetsName}
                        onChange={(e) => validateInput('assetsName', e.target.value)}
                      />
                    </div>
                    {formError.assetsName && <p className="error-message">{formError.assetsName}</p>}
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Value :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="value"
                        class="form-control input_border"
                        placeholder="Asset value"
                        defaultValue={editAssetData?.value}
                        {...register('value')}
                        onChange={(e) => validateInput('value', e.target.value)}
                      />
                    </div>
                    {formError.value && <p className="error-message">{formError.value}</p>}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="amount"
                        class="form-control input_border "
                        placeholder="Do you owe any money on this asset?"
                        defaultValue={editAssetData?.amount}
                        {...register('amount')}
                        onChange={(e) => validateInput('amount', e.target.value)}
                      />
                    </div>
                    {formError.amount && <p className="error-message">{formError.amount}</p>}
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Assets Image :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <div class="input-group">
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                          Assets image
                          <input
                            type="file"
                            class="form-control input_border "
                            placeholder="Assets Image"
                            id="file-format"
                            name="file-input"
                            onChange={upload}
                          />
                          <div className="upload">
                            <Upload className="upload-logo" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button modal-popup-btn-wrp">
                <button type="submit" class="comn-btn common-btn-style">
                  Save edits
                </button>
                <button type="button" className="comn-btn delete-btn" onClick={handleDeleteAssets}>
                  Delete assets
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAssets;
