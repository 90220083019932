import React from 'react';

const PopupModal = ({ isOpen, onClose, title, options, selectedOption, handleOptionClick }) => {
  return (
    isOpen && (
      <div className="modal-background open-modal">
        <div className="modal-content popup-width">
          <button onClick={onClose} className="close-modal-button">
            <i className="fa fa-times"></i>
          </button>
          <div className="setting_background_color">
            <div className="d-flex justify-content-between">
              <div className="fs-3">
                <span className="bold black popup-top-heading">{title}</span>
              </div>
            </div>
            <div className="col-lg-3 mb-3 popup-content-width">
              <div className="group-backgroud-color">
                <div className="bold black mb-3" style={{ float: 'left' }}>Periods</div>
                <div className="group-btn green-btn popup-padding-zero"  style={{ float: 'left' }}>
                  {options.map((option) => (
                    <div key={option} className="button-blck">
                      <a
                        href="#"
                        className={`group-button ${selectedOption === option ? 'green' : ''}`}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PopupModal;
