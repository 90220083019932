import React from 'react';

const Activebutton = () => {
  return (
    <>
      <div className="amount-active">Active</div>
    </>
  );
};
export default Activebutton;
