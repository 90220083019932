import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'nearest', // Set the mode to 'index'
      intersect: false,
    },
  },
  layout: {
    padding: {
      left: -10,
      bottom: -15,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      stacked: true,
      border: {
        display: false,
      },
      ticks: {
        display: false,
        padding: -19,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function CashFlowChart({ cashFlow }) {
  const cashFlowValue = cashFlow?.length > 0 && cashFlow[cashFlow?.length - 1];
  const chartData = {
    labels,
    datasets: [
      {
        fill: true,
        label: '',
        data: cashFlow,
        borderColor: cashFlowValue > 0 ? 'rgba(78, 195, 118, 1)' : 'rgba(255, 0, 0, 1)',
        backgroundColor: cashFlowValue > 0 ? 'rgba(78, 195, 118, 0.24)' : 'rgba(255, 0, 0, 0.24)',
        borderWidth: 1,
        pointRadius: 4,  // Set the radius of the data points
        pointHoverRadius: 10,  // Set the radius of the data points on hover
        tension: 0.2,
      },
    ],
  };
  return <Line options={options} data={chartData} className="barcart" />;
}
