import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  liabilityName: yup.string().required('*Please enter liability name'),
  value: yup
    .string()
    .required('*Please enter liability value')
    .matches(/^[0-9]+$/, '*Please enter number only'),

  assetLinkName: yup.string().required('*Please enter liability assets link name'),
});

const AddLiability = ({ liabilityOpen, setLiabilityOpen, fetchLiability, uuid }) => {
  const [liabilityData, setLiabilityData] = useState({
    userUuid: '',
    liabilityName: '',
    value: '',
    assetsId: '',
    liabilityAvatar: '',
  });
  const [assetData, setAssetData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [liabilityAvatar, setLiabilityAvatar] = useState();
  const handleClose = () => {
    setLiabilityOpen(!liabilityOpen);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLiabilityData({ ...liabilityData, userUuid: uuid, [name]: value });
  };
  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
    setLiabilityData({ ...liabilityData, assetsId: e.target.value });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('userUuid', uuid);
      formData.append('liabilityName', liabilityData.liabilityName);
      formData.append('value', liabilityData.value);
      formData.append('assetsId', liabilityData.assetsId);
      formData.append('liabilityAvatar', liabilityAvatar);
      const response = await axios.post(weburls.Add_Liability, formData);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setLiabilityOpen(false);
        fetchLiability();
      } else if (response.status === 404) {
        toast.error(`Something went wrong `, {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('error', error);
      setLiabilityOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }

    reset();
  };

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Assets}${uuid}`);

      if (response?.data?.assets?.length > 0) {
        setAssetData(response?.data?.assets);
      }
    } catch (error) {
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid, setAssetData]);

  const upload = (file) => {
    setLiabilityAvatar(file.target.files[0]);
  };
  useEffect(() => {
    fetchAssets();
  }, [fetchAssets]);

  return (
    <div className={`modal-background ${liabilityOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Add your liability</span>
            </div>
          </div>
          <hr />
          <form onSubmit={onSubmitHandler}>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Liability details</h5>
                <div className="setting_text_color popup-text">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="liabilityName"
                        class="form-control input_border"
                        placeholder="Liability name"
                        onChange={handleChange}
                        {...register('liabilityName', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.liabilityName?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Value :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="value"
                        class="form-control input_border"
                        placeholder="Liability value"
                        onChange={handleChange}
                        {...register('value', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.value?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Link to an asset :
                    </label>
                    <select
                      id="accountSelect"
                      name="account"
                      value={selectedAccount}
                      onChange={handleAccountChange}
                      className="m-control input_border"
                      style={{
                        backgroundColor: '#efeded',
                        color: 'black',
                        fontSize: '15px',
                        width: '100%',
                      }}
                    >
                      <option
                        value=""
                        style={{
                          color: 'black',
                        }}
                      >
                        Select an account
                      </option>
                      {assetData &&
                        assetData.length > 0 &&
                        assetData.map((asset) => (
                          <option
                            key={asset.assetsId}
                            value={asset.assetsId}
                            style={{
                              color: 'black',
                              marginLeft: '10px',
                            }}
                          >
                            {asset.assetsName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Liability Image :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <div class="input-group">
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                          Liability image
                          <input
                            type="file"
                            class="form-control input_border "
                            placeholder="Liability Image"
                            id="file-format"
                            name="file-input"
                            onChange={upload}
                          />
                          <div className="upload">
                            <Upload className="upload-logo" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button modal-popup-btn-wrp">
                <button type="submit" class="comn-btn common-btn-style">
                  Save liability
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddLiability;
