import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Bigplus } from '../../../assests/images/icons/Add_round.svg';
import { ReactComponent as Plus } from '../../../assests/images/icons/QuickModeOn.svg';
import EditExpense from '../../ModalForm/ReoccuringExpense/EditExpense';
import ReoccuringExpense from '../../ModalForm/ReoccuringExpense/ReoccuringExpense';
import weburls from '../../Weburls/weburls';
export const formatDate = (datetimeString) => {
  const date = new Date(datetimeString);
  return date.toLocaleDateString();
};
const Reoccuring = ({ uuid }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [expenseApiData, setExpenseApiData] = useState([]);
  const [editExpense, setEditExpense] = useState(false);
  const [expenseUuid, setExpenseUuid] = useState(null);

  const handleModalForm = () => {
    setModalOpen(true);
    setEditExpense(false);
  };

  const handleModalEditForm = (id) => {
    setExpenseUuid(id);
    setModalOpen(true);
    setEditExpense(true);
  };

  const fetchExpense = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Expense}${uuid}`);
      if (response?.data) {
        setExpenseApiData(response?.data?.expenses);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid, setExpenseApiData]);

  const handleDeleteExpense = async (id) => {
    try {
      const response = await axios.delete(`${weburls.Delete_Expense}${uuid}/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Expense Delete Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
        fetchExpense();
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    fetchExpense();
  }, [fetchExpense]);

  return (
    <>
      <div className="row reoccuring-wrp">
        <div className="col-xl-6">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget">Your reoccurring expenses</h3>
            <p>Your reoccurring expenses are what bills you pay each week, fortnight or month.</p>
          </div>
        </div>
        <div className="col-xl-6 right-section">
          <button className="new-btn">
            Auto expense <Plus />
          </button>
        </div>
      </div>
      <div className="mt-2">
        <div className="col-lg-12 new-table second-table-edit-budget">
          <div>
            <div className="table-horizontal">
              <div class="col-12 table-horizontal">
                <table class="table-image new-table">
                  <thead>
                    <tr>
                      <th scope="col">Expense Type</th>
                      <th scope="col">Expense amount</th>
                      <th scope="col">Last payment date</th>
                      <th scope="col">How Often? </th>
                      {/* <th scope="col">Account associated</th> */}
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="space-table">
                    {expenseApiData?.length > 0 &&
                      expenseApiData &&
                      expenseApiData.map((item, index) => {
                        return (
                          <tr className="cursor-pointer">
                            <th>
                              <div className="air-image">
                                <h3
                                  className="table-content display"
                                  style={{ fontSize: '20px' }}
                                  onClick={() => handleModalEditForm(item.expenseUuid)}
                                >
                                  {item.expenseName}
                                </h3>
                              </div>
                            </th>
                            <td className="table-text-color">
                              <div className="planner">{item.expenseAmount}</div>
                            </td>
                            <td className="table-text-color">
                              <div
                                className="expenseLastPaymentDate planner"
                                style={{
                                  width: '327px',
                                }}
                              >
                                {formatDate(item.lastPaymentDate)}
                              </div>
                            </td>

                            <td className="table-text-color">
                              <div
                                className="expenseHowOften"
                                style={{
                                  width: '133px',
                                }}
                              >
                                {item.howOften}
                              </div>
                            </td>
                            {/* <td className="table-text-color">
                              <div className="planner">{item.accountName}</div>
                            </td> */}
                            <td className="table-text-color">
                              {/* <Edit
                                style={{ marginLeft: '10px' }}
                                onClick={() => handleModalEditForm(item.expenseUuid)}
                              /> */}
                              <i
                                class="fa fa-times"
                                aria-hidden="true"
                                onClick={() => handleDeleteExpense(item.expenseUuid)}
                                style={{
                                  fontSize: '24px',
                                }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="income-btn-wrp col-xl-12">
                  <button className="new-btn red-btn" onClick={handleModalForm}>
                    Add Expense <Bigplus />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          {editExpense === true ? (
            <EditExpense
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              expenseUuid={expenseUuid}
              fetchExpense={fetchExpense}
              uuid={uuid}
            />
          ) : (
            <ReoccuringExpense
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              fetchExpense={fetchExpense}
              uuid={uuid}
            />
          )}
        </div>
      )}
    </>
  );
};
export default Reoccuring;
