import React from 'react';
import './loader.css';

function LoadingTxt() {
  return (
    <>
      <div class="loading loading03">
        <span>L</span>
        <span>o</span>
        <span>a</span>
        <span>d</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </>
  );
}

export default LoadingTxt;
