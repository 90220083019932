import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import AirNewZealand from '../../assests/images/icons/AirNewZealand.png';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Header from '../Header/Header';
import weburls from '../Weburls/weburls';
const GroupDetail = () => {
  const { id } = useParams();
  const schema = yup.object().shape({
    first_name: yup.string().min(3, '*Required').required('*Required'),
    last_name: yup.string().required('*Required'),
    phoneNumber: yup.string().required('*Required'),
    email: yup.string().email('*Please enter a valid email').required('*Required'),
    groupName: yup.string().required('*Required'),
    selectPlan: yup.string().min(3, '*Required').required('*Required'),
    billingFrequency: yup.string().required('*Required'),
    billTo: yup.string().required('*Required'),
    advisorName: yup.string().required('*Required'),
    advisorName2: yup.string().required('*Required'),
  });
  const [formError, setformError] = useState('');
  const [isValidation, setValidation] = useState(true);
  const [planlist, setPlanList] = useState([]);
  const [advisorlist, advisorList] = useState([]);
  const [groupLogo, setGroupLogo] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [groupid, groupId] = useState('');
  const [clientEdit, setClientEdit] = useState([]);
  const [advisor, setAdvisor] = useState([]);
  const getPlanlist = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data ?? []);
  };
  const companyAdvisorId = JSON.parse(localStorage.getItem('userData'));
  const getAdvisorList = async () => {
    const response = await axios.get(weburls.Advisor_list + `${companyAdvisorId.advisorId}`);
    advisorList(response?.data?.advisors ?? []);
  };

  const Getgroupdetails = async () => {
    const response = await axios.get(weburls.Get_group_details + `${id}`);
    groupId(response.data.groupId);
    setAdvisor(response.data.advisorId);
    setEdit(response.data);
    setData({
      countryCode: response.data.countryCode,
      planName: response.data.planName,
      planMode: response.data.planMode,
      status: response.data.status,
    });
    const updatedClients = response?.data?.clients.map((client) => ({
      ...client,
      disabled: true,
    }));
    setClientEdit(updatedClients);
  };
  const [edit, setEdit] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    groupWebsite: '',
    groupName: '',
    advisorId: '',
    countryCode: '+91',
    planName: '',
    planMode: 'monthly',
    status: 1,
    numberOfPlan: '',
    groupId: groupid,
  });

  const addNewRow = () => {
    setClientEdit((prevState) => [
      ...prevState,
      {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        mobileNo: '',
        disabled: false,
      },
    ]);
  };

  const deleteRow = (index) => {
    setClientEdit((prevState) => {
      const updatedClients = prevState.length > 0 && prevState?.filter((_, i) => i !== index);
      return updatedClients;
    });
  };

  const updateClientField = (index, field, value) => {
    setClientEdit((prevState) => {
      const updatedClients = [...prevState];
      updatedClients[index][field] = value;
      return updatedClients;
    });
  };

  useEffect(() => {
    getPlanlist();
    Getgroupdetails();
    getAdvisorList();
  }, []);

  const upload = (file) => {
    setGroupLogo(file.target.files[0]);
  };
  const validateInput = (fieldName, value) => {
    if (!value) {
      setValidation(false);
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`, // Generate a custom error message based on the field name
      }));
    } else {
      setValidation(true);
      setformError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };
  const handleAdvisorChange = (e) => {
    setAdvisor(e.target.value);
  };
  const Updategroup = async (event) => {
    event.preventDefault();
    if (isValidation) {
      const formData = new FormData();

      formData.append('firstName', event.target.firstName.value);
      formData.append('lastName', event.target.lastName.value);
      formData.append('email', event.target.email.value);
      formData.append('phoneNumber', event.target.phoneNumber.value);
      formData.append('countryCode', event.target.countryCode.value);
      formData.append('groupWebsite', event.target.groupWebsite.value);
      formData.append('groupName', event.target.groupName.value);
      formData.append('planName', event.target.planName.value);
      formData.append('planMode', event.target.planMode.value);
      formData.append('status', Number(event.target.status.value));
      formData.append('numberOfPlan', Number(event.target.numberOfPlan.value));
      formData.append('advisorId', advisor);
      formData.append('groupId', edit.groupId);
      formData.append('clients', edit.clients);
      formData.append('groupLogo', groupLogo);

      try {
        const update = await axios.put(weburls.Update_group, formData);
        if (update.status === 200) {
          toast.success('Group updated successfully..', {
            position: 'top-right',
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                navigate('/Groups');
              }, 2000); // Adjust the time as needed
            },
          });
        } else if (update.status === 500) {
          toast.error('Something went wrong please try again.');
        }
      } catch (error) {
        toast.error(`Something went wrong ${error}`, {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    }
  };

  const deleteGroup = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c63838',
      // cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(weburls.Delete_group + `${id}`);
          if (response.status === 200) {
            toast.success('Deleted group successfully..', {
              position: 'top-right',
              autoClose: 2000,
            });
            navigate('/Groups');
          } else if (response.status === 500) {
            toast.error('Something went wrong, please try again.');
          }
        } catch (error) {
          console.error('Error deleting group:', error);
          toast.error('An error occurred while deleting the group.');
        }
      }
    });
  };

  const [data, setData] = useState({
    countryCode: '+91',
    planName: '',
    planMode: 'monthly',
    status: 1,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer edit-group-page-main">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <i class="fa-solid fa-arrow-left"></i>
                  <Link to="/Groups">Back to groups</Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color">
              <div className="edit-group-title-wrapper col-lg-12 col-md-12">
                <div className="fs-3">
                  <span className="bold black edit-heading">Edit your group details</span>
                  <div className="setting_text_color">
                    Edit your groups details, they'll get an email once you're finished
                  </div>
                </div>
                <div className="mt-3">
                  <div className="profile-dashboard client-summary-page-main ">
                    <div className="profile-dashboard" style={{ height: '60px', width: '60px' }}>
                      <img
                        src={edit.groupLogo === '' || edit.groupLogo === null ? AirNewZealand : edit.groupLogo}
                        alt=""
                        style={{
                          objectFit: 'contain',
                          borderRadius: '50%',
                        }}
                      />
                    </div>

                    <div className="profile-dashboard">
                      <h4 className="bold client-name">{edit?.groupName}</h4>
                    </div>
                    <div className="profile-dashboard">
                      {edit?.status === 1 && (
                        <div className="active-status">
                          <button className="active-btn table-text-color">
                            <i class="fa-solid fa-circle"></i> Active
                          </button>
                        </div>
                      )}
                      {edit?.status === 2 && (
                        <div className="in-active-status-div">
                          <button className="inactive-button table-text-color">
                            <i class="fa-solid fa-circle"></i> Inactive
                          </button>
                        </div>
                      )}
                      {edit?.status === 3 && (
                        <div className="in-active-status-div">
                          <button className="not-inactive-button table-text-color">
                            <i class="fa-solid fa-circle"></i> Not-Activated
                          </button>
                        </div>
                      )}
                      {/* <button className="active-btn">
                        <i class="fa-solid fa-circle"></i> Active
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <form onSubmit={Updategroup}>
                <div className="row">
                  <ToastContainer position="top-right" autoClose={5000} closeOnClick />
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">
                      Main contact <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color">This is who to contact about their plan</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            defaultValue={edit?.firstName}
                            onChange={(e) => validateInput('firstName', e.target.value)}
                            name="firstName"
                          />
                        </div>
                        {formError.firstName && <p className="error-message">{formError.firstName}</p>}
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            defaultValue={edit?.lastName}
                            {...register('lastName')}
                            name="lastName"
                            onChange={(e) => validateInput('lastName', e.target.value)}
                          />
                        </div>
                        {formError.lastName && <p className="error-message">{formError.lastName}</p>}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Email"
                            defaultValue={edit?.email}
                            {...register('email')}
                            name="email"
                            onChange={(e) => validateInput('email', e.target.value)}
                          />
                        </div>
                        {formError.email && <p className="error-message">{formError.email}</p>}
                      </div>
                      <div className="col-xl-2 col-md-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            name="countryCode"
                            {...register('countryCode')}
                            onChange={(e) => validateInput('countryCode', e.target.value)}
                            value={edit?.countryCode}
                          >
                            <option value={'+91'} selected>
                              +91
                            </option>
                            <option value={'+61'}>+61</option>
                            <option value={'+64'}>+64</option>
                          </select>
                        </div>
                        <p className="error-message">{errors.countryCode?.message}</p>
                      </div>
                      <div className="col-xl-4 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="phone_number"
                            defaultValue={edit?.phoneNumber}
                            {...register('phoneNumber')}
                            name="phoneNumber"
                            onChange={(e) => validateInput('phoneNumber', e.target.value)}
                          />
                        </div>
                        {formError.phoneNumber && <p className="error-message">{formError.phoneNumber}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-xl-6 col-sm-12">
                    <h5 className="bold black heading-new">
                      Group details <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color">Enter in the details of the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row mt-2">
                      <div className="col-xl-6 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Group website"
                            defaultValue={edit?.groupWebsite}
                            {...register('groupWebsite')}
                            name="groupWebsite"
                            onChange={(e) => validateInput('groupWebsite', e.target.value)}
                          />
                        </div>
                        {formError.groupWebsite && <p className="error-message">{formError.groupWebsite}</p>}
                      </div>
                      <div className="col-xl-6 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Group name"
                            defaultValue={edit?.groupName}
                            {...register('groupName')}
                            name="groupName"
                            onChange={(e) => validateInput('groupName', e.target.value)}
                          />
                        </div>
                        {formError.groupName && <p className="error-message">{formError.groupName}</p>}
                      </div>
                    </div>
                    <div className="row mt-2 upload-csv-file">
                      <div class="col-md-10 col-lg-10">
                        <div className="input-group">
                          <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                            Profile image
                            <input
                              type="file"
                              class="form-control input_border "
                              placeholder="Profile Image"
                              id="file-format"
                              name="file-input"
                              onChange={upload}
                            />
                            <div className="upload">
                              <Upload className="upload-logo" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-2 col-lg-2">
                        <div className="profile-img">
                          <img
                            src={edit.groupLogo === '' || edit.groupLogo === null ? Profile : edit.groupLogo}
                            alt="profile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">
                      Assigned advisors <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color">Enter in the assigned advisor to this group</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            name="advisorId"
                            onChange={handleAdvisorChange}
                            value={advisor}
                          >
                            {advisorlist && advisorlist?.length > 0
                              ? advisorlist.map((item) => (
                                  <option key={item.advisorId} value={item.advisorId}>
                                    {`${item.firstName} ${item.lastName}`}
                                  </option>
                                ))
                              : 'No data found'}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the group is using</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 mb-4">
                        <input
                          type="text"
                          class="form-control input_border"
                          placeholder="Number of plans"
                          defaultValue={edit?.numberOfPlan}
                          name="numberOfPlan"
                          onChange={(e) => validateInput('numberOfPlan', e.target.value)}
                        />
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="planName"
                          onChange={handleChange}
                          value={data?.planName}
                        >
                          {planlist && planlist.length > 0
                            ? [...new Set(planlist.map((item) => item.planName))].map((planName) => (
                                <option key={planName} value={planName}>
                                  {planName}
                                </option>
                              ))
                            : 'No data found'}
                        </select>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="planMode"
                          onChange={handleChange}
                          value={data?.planMode}
                        >
                          <option value={'monthly'}> Monthly</option>
                          <option value={'yearly'}>Yearly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Status</h5>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="status"
                          onChange={handleChange}
                          value={data?.status}
                        >
                          <option value={1}>Active</option>
                          <option value={2}>Inactive</option>
                          <option value={3}>Not-Activated</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12 mt-5">
                    <h2 className="heading bold black edit-heading">Edit individual client details</h2>
                    <div className="setting_text_color">
                      Enter in your group details, they'll get an email once you're finished.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="table-wrp mt-3">
                    <div class="row">
                      <div class="col-12 table-edit-group-page">
                        <table class="table-image group-table">
                          <thead className="purple">
                            <tr>
                              <th scope="col">First Name</th>
                              <th scope="col">Last Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone number</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="space-table">
                            {clientEdit &&
                              clientEdit.map((client, index) => (
                                <tr key={index}>
                                  <th>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        value={client.firstName}
                                        className="form-control input_border"
                                        placeholder=""
                                        onChange={(e) => updateClientField(index, 'firstName', e.target.value)}
                                        disabled={client.disabled}
                                      />
                                    </div>
                                  </th>
                                  <td>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        value={client.lastName}
                                        className="form-control input_border"
                                        placeholder=""
                                        onChange={(e) => updateClientField(index, 'lastName', e.target.value)}
                                        disabled={client.disabled}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        value={client.email}
                                        className="form-control input_border"
                                        placeholder=""
                                        onChange={(e) => updateClientField(index, 'email', e.target.value)}
                                        disabled={client.disabled}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        value={client.mobileNo}
                                        className="form-control input_border"
                                        placeholder=""
                                        onChange={(e) => updateClientField(index, 'mobileNo', e.target.value)}
                                        disabled={client.disabled}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <a href="#" className="black" onClick={() => deleteRow(index)}>
                                      <i className="fa-solid fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <button
                          type="button"
                          className="comn-btn common-btn-style"
                          style={{ margin: '0px' }}
                          onClick={addNewRow}
                        >
                          Add Client <i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="setting_background_color mt-5">
                  <div className="">
                    <div className="fs-3">
                      <span className="bold black edit-heading"> Save your details</span>
                      <div className="setting_text_color">
                        Once you've send all of your users, they'll get an email notification to use BudgetBuddie.
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 col-sm-12"></div>
                    <div>
                      <div className="button mb-5">
                        <button type="button" onClick={() => navigate('/Groups')} className="comn-btn button_white">
                          Cancel
                        </button>
                        <button type="submit" className="comn-btn common-btn-style">
                          Save edits<i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                        {/* onClick={deleteGroup} */}
                        <button type="button" className="comn-btn delete-btn" onClick={deleteGroup}>
                          Delete group
                          <i class="fa-solid fa-minus minus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default GroupDetail;
