import React from 'react';
import { Bar } from 'react-chartjs-2';

const Smallcart = ({ data }) => {
  const chartData =
    data &&
    data?.map((item) => ({
      name: item?.name,
      total_income_amount: item?.total_income_amount,
      colour: item?.colour,
    }));

  const labels = chartData ? chartData?.map((item) => item?.name) : [];
  const chartValues = chartData ? chartData?.map((item) => item?.total_income_amount) : [];

  const totalValue = chartData && chartValues?.reduce((acc, value) => acc + value, 0);
  const bgColour = chartData ? chartData?.map((item) => item.colour) : [];
  return (
    <>
      <Bar
        data={{
          // Name of the variables on x-axies for each bar
          labels: [...labels],
          datasets: [
            {
              // Label for bars
              label: '',
              // Data or value of your each variable
              data: [...chartValues],
              // Color of each bar
              backgroundColor: bgColour,
              // Border color of each bar
              borderRadius: 13,
            },
          ],
        }}
        // Height of graph
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
                drawBorder: false,
                lineWidth: 0,
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        }}
      />
    </>
  );
};

export default Smallcart;
