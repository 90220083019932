import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Arrowright from '../assests/images/Expand_right.png';
import Profile from '../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../assests/images/icons/more.svg';
import { ReactComponent as Search } from '../assests/images/icons/Search.svg';
import Shape from '../assests/images/icons/shape.png';
import { Cart } from './Innerpanel/Cart';
import { progressbarColor } from './Innerpanel/Financial';
import MainSidebar from './MainSidebar';
import PopupColorModal from './Popup/PopupColorModel';
import PopupModal from './Popup/PopupModel';
import weburls from './Weburls/weburls';

const BudgetEdit = () => {
  const { uuid, expenseUuid } = useParams();
  const [active, setActive] = useState(false);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [isPopupOpenMonthly, setPopupOpenMonthly] = useState(false);
  const [selectedMonthlyOption, setSelectedMonthlyOption] = useState('Last 12 months');

  const [expenseData, setExpenseData] = useState();
  const [expenseTransactionData, setExpenseTransactionData] = useState();

  const [selectedColorOption, setSelectedColorOption] = useState(expenseData?.emoji || 'Red');
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const togglePopupMonthly = () => setPopupOpenMonthly(!isPopupOpenMonthly);

  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };

  const colorCss = (color) => {
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
      case 'Red':
        return 'red-color';
      case 'Yellow':
        return 'yellow-color';
      case 'Blue':
        return 'blue-color';
      case 'Purple':
        return 'purple-color-color';
      case 'Orange':
        return 'orange-color';
      case 'Pink':
        return 'pink-color';
      case 'Light blue':
        return 'light-blue-color';
      case 'Lime green':
        return 'lime-green-color';
      case 'Dark red':
        return 'dark-red-color';
      case 'Green':
        return 'green-color';
      case 'Dark blue':
        return 'dark-blue-color';
      default:
        return 'green';
    }
  };
  const activeChange = () => {
    setActive(true);
  };
  const fetchExpenseUsingExpenseId = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Expense_By_Id}${expenseUuid}/${uuid}`);
      if (response?.data) {
        setExpenseData(response?.data?.data);
        setSelectedColorOption(response?.data?.data.colour);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [expenseUuid, uuid]);
  const fetchTransactionUsingExpenseId = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Transaction_By_Expense_Id}${uuid}/${expenseUuid}/1000/1`);
      if (response?.data) {
        setExpenseTransactionData(response?.data?.transactions);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [expenseUuid, uuid]);

  useEffect(() => {
    fetchExpenseUsingExpenseId();
    fetchTransactionUsingExpenseId();
  }, [fetchExpenseUsingExpenseId, fetchTransactionUsingExpenseId]);

  function calculatePercentageCompletion(completedValue, totalValue) {
    const percentageCompletion = (completedValue / totalValue) * 100;
    return percentageCompletion;
  }
  const handleOptionMonthlyClick = (option) => {
    setSelectedMonthlyOption(option);
    setPopupOpenMonthly(false);
  };
  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper grocery-edit-main">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer">
              <div className="row top-nav grocery-edit-nav">
                <div className="col-md-4 main-title-heading">
                  <div className="big-head-new bold">{expenseData?.category_name} budget</div>
                  <div className="fst-normal setting_text_color">Make any changes to personalise your budget.</div>
                </div>

                <div className="col-lg-8 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <div class="buttonEditBudget modal-popup-btn-wrp">
                      <button
                        type="button"
                        class="comn-btn common-btn-style"
                        // onClick={onSubmitData}
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        class="comn-btn delete-btn"
                        // onClick={() => handleDeleteIncome(incomeUuid)}
                      >
                        Delete Budget
                      </button>
                    </div>
                    <div className="profile-dashboard">
                      <img src={Shape} alt="Mortage_img" style={{ width: '20px', marginLeft: '20px' }} />
                    </div>

                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton groceries-edit-table">
                <div className="col-lg-12 new-table">
                  <div>
                    <div className="table-horizontal">
                      <div class="col-12 table-horizontal">
                        <table class="table-image new-table">
                          <thead>
                            <tr>
                              <th scope="col">Expense type</th>
                              <th scope="col">Expense amount</th>
                              <th scope="col">Last payment date</th>
                              <th scope="col">How Often?</th>
                            </tr>
                          </thead>
                          <tbody className="space-table">
                            <tr onClick={activeChange} className="cursor-pointer">
                              <th>
                                <div className="air-image">
                                  <h3 className="table-content display inter">{expenseData?.category_name}</h3>
                                </div>
                              </th>
                              <td className="table-text-color">
                                <div className="new-planner planner">{expenseData?.expense_amount}</div>
                              </td>

                              <td className="table-text-color">
                                <div className="planner big-planner">
                                  {moment(expenseData?.last_payment_date).format('YYYY-MM-DD')}
                                </div>
                              </td>
                              <td className="table-text-color">
                                <button className={`group-button red-color popoup-color`} aria-haspopup="true">
                                  {expenseData?.how_often}
                                </button>
                              </td>

                              <td className="table-text-color">
                                <i
                                  class="fa fa-times"
                                  aria-hidden="true"
                                  // onClick={() => handleDeleteIncome(item.incomeUuid)}
                                  style={{
                                    fontSize: '24px',
                                  }}
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* start code */}
              <div className="row d-flex">
                <div className="col-md-8 col-sm-8">
                  <div className="recent-see-all">
                    <div
                      className="col-md-12 "
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <h5>Spend vs Budget</h5>
                      <div className="dropdown">
                        <button
                          className="group-button green"
                          onClick={togglePopupMonthly}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenMonthly}
                        >
                          {selectedMonthlyOption}
                        </button>
                        {isPopupOpenMonthly && (
                          <PopupModal
                            isOpen={isPopupOpenMonthly}
                            onClose={togglePopupMonthly}
                            title="Date Range"
                            options={[
                              'Last 12 months',
                              'Last 6 months',
                              'Last 3 months',
                              'Last month',
                              'This month',
                              'This week',
                            ]}
                            selectedOption={selectedMonthlyOption}
                            handleOptionClick={handleOptionMonthlyClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="main-barcart-wrapper">
                    <div className="title-legend-wrp col-xl-11 col-md-11">
                      <h3>April 2022</h3>
                      <div className="income-exp-legend">
                        <span className="pointer-green"></span>
                        <h5>Income</h5>
                        <span className="pointer-red"></span>
                        <h5>Expenses</h5>
                        <Link to="#">
                          <Edit />
                        </Link>
                      </div>
                    </div>

                    <div className="bar-cart-data-detail">
                      <h3 className="income-bar">
                        Income: <span>${400}</span>
                      </h3>
                      <h3 className="expense-bar">
                        Expenses: <span>${500}</span>
                      </h3>
                    </div>
                    <Cart income={[]} expense={[]} />
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 ">
                  <div className="col-md-12 col-xl-12  row groceries-edit-second-section">
                    <h3 className="air-new fw-bold">Personalise</h3>
                    <div className="col-md-12 col-xl-12">
                      <div
                        className="back-section"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="personalised inter">
                          <h3 className="bold">Personalised colour</h3>
                        </div>
                        <div className="color-option">
                          <div className="dropdown">
                            <button
                              className={`group-button ${colorCss(selectedColorOption)} popoup-color`}
                              onClick={togglePopupColor}
                              aria-haspopup="true"
                              aria-expanded={isPopupOpenColor}
                            >
                              {selectedColorOption}
                            </button>
                            {isPopupOpenColor && (
                              <PopupColorModal
                                isOpen={isPopupOpenColor}
                                onClose={togglePopupColor}
                                title="Date Range"
                                options={[
                                  'Dark green',
                                  'Red',
                                  'Yellow',
                                  'Blue',
                                  'Purple',
                                  'Orange',
                                  'Pink',
                                  'Light blue',
                                  'Lime green',
                                  'Dark red',
                                  'Green',
                                  'Dark blue',
                                ]}
                                selectedOption={selectedColorOption}
                                handleOptionClick={handleOptionColorClick}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="back-section"
                        style={{
                          marginTop: '15px',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="personalised inter">
                          <h3 className="bold">Personalised Emoji</h3>
                        </div>
                        <div className="color-option">
                          <div className="planner">{expenseData?.emoji}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-xl-12 col-sm-12 mt-3 big-section-block">
                    <h5 class="title main-title">Spend vs Budget</h5>
                    <Link className="groceries-main " to="/Groceriesedit">
                      <div className="income-merchant mt-3 col-lg-5 col-xl-5 col-sm-12 grocer-edit">
                        <div className="inner-blck">
                          <div className="merchantSummary">
                            <div
                              className="icon-img "
                              style={{
                                backgroundColor: expenseData?.category_colour,
                              }}
                            >
                              {expenseData?.emoji}
                            </div>
                            <div className="content">
                              <h6 className="small-content">{expenseData?.category_name}</h6>
                              <h5 className="bold black">${expenseData?.total_transaction_amount}</h5>
                            </div>
                          </div>
                          <div className="arrow">
                            <img src={Arrowright} />
                          </div>
                        </div>
                        <div className="left-block name-merchant mb-2">
                          <h6>
                            {(expenseData?.expense_amount - expenseData?.total_transaction_amount).toFixed(2)} remaining
                          </h6>
                        </div>
                        <div class="progress " style={{ height: '17px' }}>
                          <div
                            className={`progress-bar ${progressbarColor(expenseData?.colour)}`}
                            role="progressbar"
                            aria-valuenow={calculatePercentageCompletion(
                              expenseData?.total_transaction_amount,
                              expenseData?.adjusted_expense_amount,
                            ).toFixed(2)}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: `${calculatePercentageCompletion(
                                expenseData?.total_transaction_amount,
                                expenseData?.adjusted_expense_amount,
                              ).toFixed(2)}%`,
                              opacity: '2 !important',
                            }}
                          ></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 col-xl-12 col-sm-12 mt-5 bar-cart grocer-edit">
                  <div className="grocer-edit-bar-heading">
                    <div className="col-md-6 mb-4 left col-sm-6">
                      <h5 className="smal-trans">Transactions</h5>
                    </div>
                    <div className="col-md-6 right-section  col-sm-6">
                      <div className="">
                        <Search className="search-merchant" />
                      </div>
                    </div>
                  </div>
                  <div className="row table-horizontal">
                    {expenseTransactionData &&
                      expenseTransactionData?.length > 0 &&
                      expenseTransactionData.map((it) => (
                        <div className="col-lg-12 ">
                          <div className="merchant-box mt-3 transactions-table table-transaction merchant-detail-table">
                            <div
                              className="d-flex"
                              style={{
                                width: '20%',
                              }}
                            >
                              <div className="img-box">
                                <img src={it?.accountAvatar} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="text-merchant bold">{it?.transactionName}</div>
                                <div className="status-btn">
                                  <div className="button-blck">
                                    <div className="icon-tag">
                                      {it.masterCategoryColour !== null && (
                                        <div
                                          className="icon-transaction-img"
                                          style={{ backgroundColor: it.masterCategoryColour }}
                                        >
                                          {it.masterCategoryEmoji}
                                        </div>
                                      )}
                                      <div className="content">
                                        <h6 className="icon-content ">{it.masterCategoryName}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="transactions-text bold">
                              {moment(it.dateTime).format('DD/MM/YYYY')} &nbsp;&nbsp; | &nbsp;&nbsp;
                              {moment(it.dateTime).format('hh:mma')}
                            </div>
                            <div className={`transactions-text ${it.status === 'Completed' ? 'done' : 'pending'}`}>
                              {it?.status}
                            </div>
                            <div className="this-week-number light-red-trans">{it?.amount}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BudgetEdit;
