import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { statusArray } from '../../assests/mock/Status';
import Header from '../../Components/Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  firstName: yup.string().min(3, 'First Name is required').required('First Name is required'),
  lastName: yup.string().required('Last Name is Required'),
  phoneNumber: yup
    .string()
    .required('Phone Number is not valid')
    .min(7, 'Phone Number must be at least 7 digits')
    .max(10, 'Phone Number must be at most 10 digits'),
  email: yup
    .string()
    .required('Please Enter email')
    .email('Please Enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please Enter proper email'),
  groupId: yup.string().required('Group is Required'),
  advisorId: yup.string().required('Advisor is Required'),
  selectPlan: yup.string().min(3, '*Required').required('*Required'),
  planMode: yup.string().required('*Required'),
  billTo: yup.string().required('*Required'),
  advisorName: yup.string().required('*Required'),
  advisorName2: yup.string().required('*Required'),
});

const ClientDetails = () => {
  const {
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { id } = useParams();
  const companyId = JSON.parse(localStorage.getItem('userData'));
  const [advisorList, setAdvisorList] = useState([]);

  const getClientId = useCallback(async () => {
    const response = await axios.get(weburls.Get_clients_cliendid + `${id}`);
    setEdit(response.data, { groupId: response.data.groupId });
    setPlanId(response.data.planId);
    setPlanMode(response.data.planMode ? response.data.planMode : 'monthly');
    setAdvisor(response.data.advisorId);
    setSelectedOption(response.data.groupId);
    setBillTo(response.data.billTo);
    setStatus(response.data.status);
    setCountryCode(response.data.countryCode);
  }, [id]);
  const navigate = useNavigate();
  const [edit, setEdit] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    groupId: '',
    planMode: 'Monthly',
  });
  const [formError, setFormError] = useState('');
  const [groupList, setGroupList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [planName, setPlanId] = useState('');
  const [status, setStatus] = useState('');
  const [advisor, setAdvisor] = useState('');
  const [planMode, setPlanMode] = useState('Monthly');
  const [billTo, setBillTo] = useState('');
  const [countryCode, setCountryCode] = useState('+91');

  const [isSubmit, setSubmit] = useState(true);
  const validateInput = (fieldName, value) => {
    if (!value) {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`, // Generate a custom error message based on the field name
      }));
      setSubmit(false);
    } else {
      setFormError((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
      setSubmit(true);
    }
  };
  const advisorsDetail = JSON.parse(localStorage.getItem('userData'));

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSelectStatus = (e) => {
    setStatus(e.target.value);
  };
  const handlePlanModeChange = (event) => {
    setPlanMode(event.target.value);
  };
  const handleBillToChange = (event) => {
    setBillTo(event.target.value);
  };

  const handleAdvisorChange = (e) => {
    setAdvisor(e.target.value);
  };

  const handlePlanNameChange = (event) => {
    setPlanId(event.target.value);
  };

  const handleCountryCode = (event) => {
    setCountryCode(event.target.value);
  };

  const UpdateClient = async (event) => {
    event.preventDefault();
    if (isSubmit) {
      const updatedData = {
        ...edit,
        firstName: event.target.firstName.value,
        lastName: event.target.lastName.value,
        email: event.target.email.value,
        phoneNumber: event.target.phoneNumber.value,
        planName,
        planId: planName,
        planMode: planMode,
        advisorId: advisor,
        billTo: billTo,
        status: status,
        groupId: selectedOption,
        countryCode: countryCode,
        companyId: companyId,
      };
      try {
        const update = await axios.put(weburls.Update_clients, updatedData);
        if (update.status === 200) {
          toast.success('Client updated successfully..', {
            position: 'top-right',
            autoClose: 1000,
            onClose: () => {
              setTimeout(() => {
                navigate('/Clients');
              }, 2000); // Adjust the time as needed
            },
          });
        } else if (update.status === 500) {
          toast.error('Something went wrong please try again.');
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const getGroupList = useCallback(async () => {
    try {
      const response = await axios.post(weburls.Group_list, {
        advisorId: advisorsDetail.advisorId,
        companyId: companyId.companyId,
      });
      if (response?.data?.length > 0) {
        setGroupList(response.data);
      }
    } catch (err) {
      toast.error(err);
    }
  }, [advisorsDetail.advisorId, companyId.companyId]);

  const getPlanList = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data);
  };
  const getAdvisorList = useCallback(async () => {
    const response = await axios.get(weburls.Advisor_list + `${advisorsDetail.advisorId}`);
    setAdvisorList(response?.data?.advisors ?? []);
  }, [advisorsDetail.advisorId]);

  useEffect(() => {
    getClientId();
    getPlanList();
    getGroupList();
    getAdvisorList();
  }, [getAdvisorList, getClientId, getGroupList, id]);

  const deleteClient = async () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete the data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#c63838',
      // cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(weburls.deleteClientById + `${id}/${companyId.companyId}`);
          if (response.status === 200) {
            toast.success('Deleted group successfully..', {
              position: 'top-right',
              autoClose: 2000,
            });
            navigate('/Clients');
          } else if (response.status === 500) {
            toast.error('Something went wrong, please try again.');
          }
        } catch (error) {
          console.error('Error deleting group:', error);
          toast.error('An error occurred while deleting the group.');
        }
      }
    });
  };
  return (
    <>
      <div className="container-fluid">
        <Header />

        <div className="appmain">
          <Sidebar />
          <ToastContainer position="top-right" autoClose={5000} closeOnClick />
          <div className="app-main__outer edit-client-details-page">
            <div className="row">
              <div className="col-md-12 back-to-dashboard">
                <h3 className="small-heading">
                  <Link to="/Clients">
                    <i class="fa-solid fa-arrow-left"></i> Back to clients
                  </Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color mt-3">
              <form onSubmit={UpdateClient}>
                <div className="d-flex justify-content-between">
                  <div className="fs-3">
                    <span className="bold black big-top-heading">Edit a clients details</span>
                    <div className="setting_text_color">
                      Edit your clients details. they'll get updated on the clients list.
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Personal details </h5>
                    <div className="setting_text_color">This will be displayed on their profile</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            {...register('firstName')}
                            type="text"
                            class="form-control input_border"
                            defaultValue={edit?.firstName}
                            placeholder="First name"
                            name="firstName"
                            onChange={(e) => validateInput('firstName', e.target.value)}
                          />
                        </div>
                        {formError.firstName && <p className="error-message">{formError.firstName}</p>}
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            {...register('lastName')}
                            name="lastName"
                            defaultValue={edit?.lastName}
                            onChange={(e) => validateInput('lastName', e.target.value)}
                          />
                        </div>
                        {formError.lastName && <p className="error-message">{formError.lastName}</p>}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            {...register('email')}
                            type="email"
                            class="form-control input_border "
                            placeholder="Email"
                            name="email"
                            defaultValue={edit?.email}
                            onChange={(e) => validateInput('email', e.target.value)}
                          />
                        </div>
                        {formError.email && <p className="error-message">{formError.email}</p>}
                      </div>
                      <div className="col-xl-2 col-md-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            name="countryCode"
                            {...register('countryCode')}
                            onChange={handleCountryCode}
                            value={countryCode}
                          >
                            <option value={'+91'} selected>
                              +91
                            </option>
                            <option value={'+61'}>+61</option>
                            <option value={'+64'}>+64</option>
                          </select>
                        </div>
                        <p className="error-message">{errors.countryCode?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-4">
                        <div class="input-group mb-3">
                          <input
                            {...register('phoneNumber')}
                            type="number"
                            class="form-control input_border "
                            placeholder="Phone number"
                            defaultValue={edit?.phoneNumber}
                            onChange={(e) => validateInput('phoneNumber', e.target.value)}
                          />
                        </div>
                        {formError.phoneNumber && <p className="error-message">{formError.phoneNumber}</p>}
                      </div>
                      <div class="input-group mb-3">
                        <select
                          class="form-select input_border mb-4"
                          value={status}
                          name="status"
                          onChange={handleSelectStatus}
                        >
                          <option> Select a Status</option>
                          {statusArray && statusArray.length > 0
                            ? statusArray.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))
                            : 'No data found'}
                        </select>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Group details</h5>
                    <div className="setting_text_color">This is if they're part of a group package</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div class="input-group mb-3">
                      <select
                        class="form-select input_border mb-4"
                        name="grouplsit"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value=""> Select a group list</option>
                        {groupList && groupList.length > 0
                          ? groupList.map((item) => (
                              <option key={item.groupId} value={item.groupId}>
                                {item.groupName}
                              </option>
                            ))
                          : 'No data found'}
                      </select>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the user has selected</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <select
                            className="form-select input_border mb-4"
                            name="planId"
                            value={planName}
                            onChange={handlePlanNameChange}
                          >
                            <option value=""> Select a plan</option>
                            {planList &&
                              planList.length > 0 &&
                              [...new Set(planList.map((item) => item.planId))].map((planId) => (
                                <option key={planId} value={planId}>
                                  {planList.find((item) => item.planId === planId)?.planName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border"
                          {...register('planMode')}
                          name="planMode"
                          onChange={handlePlanModeChange}
                          value={planMode}
                        >
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                        <p className="error-message">{errors.planMode?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6"></div>
                      <div className="col-md-6">
                        <select
                          class="form-select input_border "
                          {...register('billTo')}
                          onChange={handleBillToChange}
                          value={billTo}
                        >
                          <option value="company">Company</option>
                          <option value="user">User</option>
                        </select>
                        <p className="error-message">{errors.billTo?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-4 edit-client-last-side">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Assigned advisors</h5>
                    <div className="setting_text_color">Enter in the assigned advisor to this group</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class=" mb-6">
                          <select class="form-select input_border mb-3" onChange={handleAdvisorChange} value={advisor}>
                            <option selected value={''}>
                              Choose Advisor
                            </option>
                            {advisorList && advisorList?.length > 0
                              ? advisorList.map((item) => (
                                  <option key={item.advisorId} value={item.advisorId}>
                                    {`${item.firstName} ${item.lastName}`}
                                  </option>
                                ))
                              : 'No data found'}
                          </select>
                        </div>
                        <p className="error-message">{errors.advisorName?.message}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="button mt-5 client-button edit-clients-buttons">
                      <button type="button" className="comn-btn button_white">
                        <Link to="/Clients" className="black">
                          Cancel
                        </Link>
                      </button>
                      <button type="submit" className="comn-btn save-details common-btn-style">
                        Save edits
                        <i class="fa-solid fa-plus"></i>
                      </button>
                      <button type="button" className="comn-btn delete-btn" onClick={deleteClient}>
                        Delete client
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ClientDetails;
