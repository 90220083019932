import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Email } from '../../assests/images/email-icon.svg';
import './Login.css';

const EmailForgrtPassword = () => {
  const { email } = useParams();
  const navigate = useNavigate();

  const onSubmitHandler = async () => {
    // setLoading(true);
  };

  return (
    <>
      <section class="login-section loginSectionWelcome">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-md-8 col-lg-6 col-xl-4">
              <div className="email-forget-icon-width text-center mb-5">
                <Email />
              </div>

              <div className="emailForm">
                <p className="text-center mb-3 emailText">We have sent an reset password link to your email</p>
                <p className="text-center mb-5 forgotDescriptionText">
                  Check your email {email} and click the link to Reset my password
                </p>

                <div class="text-center pt-2 ">
                  <button type="submit" class="comn-btn open-email-btn mt-5" onClick={onSubmitHandler}>
                    Open email
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EmailForgrtPassword;
