import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import { statusArray } from '../../assests/mock/Status';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import webUrls from '../Weburls/weburls';

const schema = yup.object().shape({
  firstName: yup.string().min(3, 'First Name is required').required('First Name is required'),
  lastName: yup.string().required('Last Name is Required'),
  phoneNumber: yup
    .string()
    .required('Phone Number is not valid')
    .min(7, 'Phone Number must be at least 7 digits')
    .max(10, 'Phone Number must be at most 10 digits'), // Adjust the maximum length as needed
  email: yup
    .string()
    .required('Please Enter email')
    .email('Please Enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please Enter proper email'),
});

const Newclient = ({ show }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const advisorsDetail = JSON.parse(localStorage.getItem('userData'));
  const [planList, setPlanList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [advisorList, setAdvisorList] = useState([]);
  const [billTo, setBillTo] = useState('');
  const companyId = JSON.parse(localStorage.getItem('userData'));
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    companyId: companyId.companyId,
    groupId: null,
    planId: null,
    planMode: '',
    advisorId: null,
    status: 1,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const addClient = async () => {
    try {
      const response = await axios.post(webUrls.Add_clients, {
        ...formData,
        billTo: billTo,
      });
      if (response.data) {
        const errors = response.data.errors;
        if (errors?.[0]?.code === 0) {
          toast.error(errors[0].detail, {
            position: 'top-right',
            autoClose: 2000,
          });
        } else {
          toast.success('Data added successfully', {
            position: 'top-right',
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                navigate('/Clients');
              }, 2000); // Adjust the time as needed
            },
          });
        }
      } else {
        toast.error('Something went wrong', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(`Something went wrong ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  const getPlanList = async () => {
    const response = await axios.get(webUrls.Super_admin_plans_list);
    setPlanList(response.data);
  };
  const getGroupList = async () => {
    try {
      const response = await axios.post(webUrls.Group_list, {
        advisorId: advisorsDetail.advisorId,
        companyId: companyId.companyId,
      });
      if (response?.data?.length > 0) {
        setGroupList(response.data);
      }
    } catch (err) {
      toast.error(`Something went wrong ${err}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  const getAdvisorList = async () => {
    const response = await axios.get(webUrls.Advisor_list + `${advisorsDetail.advisorId}`);
    setAdvisorList(response?.data?.advisors ?? []);
  };
  const handleBillToChange = (event) => {
    setBillTo(event.target.value);
  };

  useEffect(() => {
    getPlanList();
    getGroupList();
    getAdvisorList();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer">
            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <Link to="/Clients">
                    <i class="fa-solid fa-arrow-left"></i> Back to clients
                  </Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Add a new client</span>
                  <div className="setting_text_color">
                    Enter in your clients details, they'll get an email once you're finished.
                  </div>
                </div>
              </div>
              <hr />
              <ToastContainer
                position="top-right"
                autoClose={5000} // Auto close after 5 seconds
                closeOnClick
              />
              <form onSubmit={handleSubmit(addClient)}>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Personal details</h5>
                    <div className="setting_text_color">This will be displayed on their profile.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            {...register('firstName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="firstName"
                            value={formData.firstName}
                          />
                        </div>
                        <p className="error-message">{errors.firstName?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            {...register('lastName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="lastName"
                            value={formData.lastName}
                          />
                        </div>
                        <p className="error-message">{errors.lastName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Email"
                            {...register('email', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="email"
                            value={formData.email}
                          />
                        </div>
                        <p className="error-message">{errors.email?.message}</p>
                      </div>

                      <div className="col-xl-2 col-md-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            name="countryCode"
                            {...register('countryCode', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            value={formData.countryCode}
                          >
                            <option value={'+91'} selected>
                              +91
                            </option>
                            <option value={'+61'}>+61</option>
                            <option value={'+64'}>+64</option>
                          </select>
                        </div>
                        <p className="error-message">{errors.countryCode?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-4">
                        <div class="input-group mb-3">
                          <input
                            type="number"
                            class="form-control input_border "
                            placeholder="Phone number"
                            {...register('phoneNumber', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="phoneNumber"
                            value={formData.phoneNumber}
                          />
                        </div>
                        <p className="error-message">{errors.phoneNumber?.message}</p>
                      </div>
                      <div class="input-group mb-3">
                        <select
                          class="form-select input_border mb-4"
                          value={formData.status}
                          name="status"
                          onChange={(e) => handleChange({ target: { name: 'status', value: e.target.value } })}
                        >
                          {statusArray && statusArray.length > 0
                            ? statusArray.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.title}
                                </option>
                              ))
                            : 'No data found'}
                        </select>{' '}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Group details</h5>
                    <div className="setting_text_color">This is if they’re part of a group package.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row mt-3">
                      <div className="col-md-12 col-xl-12 col-sm-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-4"
                            value={formData.groupId}
                            name="groupId"
                            {...register('groupId')}
                            onChange={(e) => handleChange({ target: { name: 'groupId', value: e.target.value } })}
                          >
                            <option value=""> Select a group list</option>
                            {groupList && groupList.length > 0
                              ? groupList.map((item) => (
                                  <option key={item.groupId} value={item.groupId}>
                                    {item.groupName}
                                  </option>
                                ))
                              : 'No data found'}
                          </select>{' '}
                        </div>
                        <p className="error-message">{errors.groupId?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xl-5">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the user has selected.</div>
                  </div>

                  <div className="col-md-12 col-xl-7 col-sm-12 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-xl-6 col-md-12">
                        <select
                          class="form-select input_border mb-4"
                          value={formData.planId}
                          name="planId"
                          onChange={(e) => handleChange({ target: { name: 'planId', value: e.target.value } })}
                        >
                          <option value=""> Select a plan</option>
                          {planList &&
                            planList.length > 0 &&
                            [...new Set(planList.map((item) => item.planId))].map((planId) => (
                              <option key={planId} value={planId}>
                                {planList.find((item) => item.planId === planId)?.planName}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="col-xl-6 col-md-12">
                        <select
                          class="form-select input_border mb-4"
                          onChange={(e) => handleChange({ target: { name: 'planMode', value: e.target.value } })}
                        >
                          <option selected>Billing Frequency</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                      </div>

                      <div className="col-md-12 col-xl-6">
                        <select class="form-select input_border" onChange={handleBillToChange} value={billTo}>
                          <option value="company">Company</option>
                          <option value="user">User</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row mt-4">
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <h5 className="bold black heading-new">Assigned advisors</h5>
                    <div className="setting_text_color">Enter in the assigned advisor to this group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-6">
                    <div className="row">
                      <div className="col-xl-6 col-md-12"></div>

                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="advisorId"
                          {...register('advisorId')}
                          value={formData.advisorId}
                          onChange={(e) => handleChange({ target: { name: 'advisorId', value: e.target.value } })}
                        >
                          <option selected value={''}>
                            Choose Advisor
                          </option>
                          {advisorList && advisorList?.length > 0
                            ? advisorList.map((item) => (
                                <option key={item.advisorId} value={item.advisorId}>
                                  {`${item.firstName} ${item.lastName}`}
                                </option>
                              ))
                            : 'No data found'}
                        </select>
                      </div>
                      <div className="col-xl-6 col-md-12"></div>
                      <div className="col-md-12 col-xl-6">
                        {' '}
                        <p className="error-message">{errors.advisorId?.message}</p>
                      </div>
                    </div>
                  </div>
                  <div class="button">
                    <button type="button" class="comn-btn button_white">
                      Cancel
                    </button>
                    <button type="submit" class="comn-btn common-btn-style">
                      Add client<i class="fa-solid fa-plus plus-icon-style"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="setting_background_color mt-5">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Upload a list of clients</span>
                  <div className="setting_text_color">
                    If you have a bunch of clients to add, upload a csv file of all their details and we’ll handle it.
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-6">
                  <h5 className="bold black heading-new">Upload your file here</h5>
                  <div className="setting_text_color">Upload your csv file.</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-6">
                  <div className="row mt-3">
                    <div className="col-md-12 col-xl-12">
                      <div className="col-md-12">
                        <div
                          class="input-group mb-3"
                          // onClick={handleCsvFile}
                        >
                          <input
                            type="text"
                            className="form-control input_border select-csv-file-net "
                            placeholder="Click to select your csv file"
                          />
                          <div className="upload">
                            <input type="file" id="file" style={{ display: 'none' }} />

                            <Upload className="upload-logo" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="button">
                      <button type="button" class="comn-btn button_white">
                        Cancel
                      </button>
                      <button type="button" class="comn-btn common-btn-style">
                        Add clients<i class="fa-solid fa-plus plus-icon-style"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newclient;
