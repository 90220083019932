import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as ArrowRight } from '../../assests/images/icons/Arrow_right.svg';
import HomeValue from '../../assests/images/icons/Home-value.png';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import Mortgage from '../../assests/images/icons/Mortgage.png';
import Shape from '../../assests/images/icons/shape.png';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import MainSidebar from '../MainSidebar';
import AddAssets from '../ModalForm/AddAsset/AddAssets';
import EditAssets from '../ModalForm/AddAsset/EditAssets';
import AddLiability from '../ModalForm/AddLiability/AddLiability';
import DeleteLiability from '../ModalForm/AddLiability/DeleteLiability';
import EditLiability from '../ModalForm/AddLiability/EditLiability';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';
import { Linecart } from './Linecart';

const NetWorth = () => {
  const sidebarRef = useRef(null);
  const inputFile = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [liabilityOpen, setLiabilityOpen] = useState(false);

  const [editAssets, setEditAssets] = useState(false);
  const [editLiability, setEditLiability] = useState(false);

  const [deleteLiability, setDeleteLiability] = useState(false);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const [assetData, setAssetData] = useState([]);
  const [liabilityData, seLiabilityData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [assetsUuid, setAssetsUuid] = useState(null);
  const [liabilityUuid, setLiabilityUuid] = useState(null);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const allLiabilityTotal =
    liabilityData?.length > 0 ? liabilityData.reduce((acc, liability) => acc + liability.value, 0) : 0;
  const allAssetTotal = assetData?.length > 0 ? assetData.reduce((acc, asset) => acc + asset.value, 0) : 0;
  const { uuid } = useParams();

  const handleCsvFile = () => {
    inputFile.current.click();
  };

  const handleModalForm = () => {
    setModalOpen(true);
    setEditAssets(false);
  };

  const handleModalLiabilityForm = () => {
    setLiabilityOpen(true);
    setEditLiability(false);
  };

  const handleEditForm = (id) => {
    setAssetsUuid(id);
    setEditAssets(true);
    setModalOpen(true);
  };

  const handleEditLiability = (id) => {
    setLiabilityUuid(id);
    setEditLiability(true);
    setLiabilityOpen(true);
  };

  const handleDeleteLiability = (id) => {
    setLiabilityUuid(id);
    setDeleteLiability(true);
  };
  function getAssetsNameById(dataArray, assetsId) {
    if (dataArray?.length > 0) {
      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i].assetsId === assetsId) {
          return dataArray[i].assetsName;
        }
      }
    }

    return null;
  }

  const fetchAssets = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Assets}${uuid}`);
      if (response?.data?.assets?.length > 0) {
        setAssetData(response?.data?.assets);
        localStorage.setItem('assetsId', JSON.stringify(response?.data?.assets[0]?.assetsId));
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  }, [setAssetData, setLoading, uuid]);

  const fetchLiability = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Liability}${uuid}`);
      if (response?.data?.liability?.length > 0) {
        seLiabilityData(response?.data?.liability);
      }
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [seLiabilityData, setLoading, uuid]);
  const [netWorth, setNetWorth] = useState();
  const fetchNetWorth = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_NetWorth_Progress}${uuid}`);
      setNetWorth(response.data);
    } catch (error) {
      console.error('error', error);
    }
  }, [setNetWorth, uuid]);

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
  });
  useEffect(() => {
    fetchAssets();
    fetchLiability();
    fetchNetWorth();
  }, [fetchAssets, fetchLiability, fetchNetWorth]);

  function calculatePercentage(asset) {
    return (asset?.value / allAssetTotal) * 100;
  }

  function getTop4Assets() {
    const sortedAssets =
      assetData?.length > 0 &&
      assetData?.slice().sort((a, b) => {
        return calculatePercentage(b) - calculatePercentage(a);
      });

    return sortedAssets?.length > 0 && sortedAssets?.slice(0, 4);
  }
  const top4Assets = getTop4Assets();

  function calculatePercentageLiabilities(liabilitie) {
    return (liabilitie?.value / allLiabilityTotal) * 100;
  }

  function getTop4Liabilities() {
    const sortedLiabilities =
      liabilityData?.length > 0 &&
      liabilityData?.slice().sort((a, b) => {
        return calculatePercentage(b) - calculatePercentage(a);
      });

    return sortedLiabilities?.length > 0 && sortedLiabilities?.slice(0, 4);
  }
  const top4Liabilities = getTop4Liabilities();

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer net-worth-main">
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-3 bold big-head-new">Net worth</div>
                  <div className="fst-normal setting_text_color">
                    See what you own & what your owe to know your net worth.
                  </div>
                </div>
                <div className="col-md-6 right-section search-spacing">
                  <div className="setting_background_color right-section back-portal-btn">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Net Worth 💰"
                        description={[
                          'Your net worth is your assets (what you own) minus your liabilities (what you owe).',
                          'Assets can be physical things, like a house you own. A liability is typically money you owe someone, such as a bank.',
                          'You can enter all the assets you own and the value of each asset. You can then do the same for your liabilities and enter in what’s owed.',
                          'You can see a full summary and track your progress using the widgets at the top. ',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 col-lg-5 left-section total-bal-main-first-wrp">
                  <div className="total-balance-wrp">
                    <div className="balance-wrp-heading">
                      <h4 className="med-head">Current Net Worth</h4>
                      <h2 className="big-head"> ${(allAssetTotal - allLiabilityTotal).toLocaleString()}</h2>
                    </div>
                    <div className="row mt-3 balance-wrp-sub-heading">
                      <div className="text-white med-new-font">
                        Assets: <span className="new-green">${allAssetTotal.toLocaleString()}</span> | Liabilities:
                        <span className="text-danger new-light-red"> ${allLiabilityTotal.toLocaleString()}</span>
                      </div>
                      <div className="col-md-7 img-sec"></div>
                      <div className="col-md-5 right mt-5 full-breakdown-arrow">
                        <h4>
                          Full Breakdown <ArrowRight />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="net-worth-line-cart-canvas col-lg-7 col-md-12">
                  <div className="line-cart-net">
                    <div className="bar-cart-heading-wrp col-lg-12 col-md-4">
                      <div className="top-heading-canvas-price">
                        <h4 className="bold black">Net Worth Progress</h4>
                        <h5 style={{ color: allAssetTotal - allLiabilityTotal < 0 ? 'red' : '' }}>
                          ${(allAssetTotal - allLiabilityTotal).toLocaleString()}
                        </h5>
                      </div>
                      <h6>{formattedDate}</h6>
                    </div>
                    <Linecart netWorth={netWorth} />
                  </div>
                </div>
              </div>
              <div className="row mt-5 assets-wrp">
                <h5>Your assets </h5>
                <div className="text-14px">These are the things you own.</div>
                <div className="col-lg-9 col-md-9 asset-wrp-first-side">
                  <div className="col-lg-12 new-table">
                    <div>
                      <div className="table-horizontal net-worth-table">
                        <div class="col-12 table-horizontal">
                          <table class="table-image new-table">
                            <thead>
                              <tr>
                                <th scope="col">Asset name</th>
                                <th scope="col">Value</th>
                                <th scope="col">Do you owe any money on this asset?</th>
                                <th scope="col">Image</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              {assetData &&
                                assetData?.length > 0 &&
                                assetData.map((item, index) => {
                                  return (
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image new-img">
                                          <div className="img-box">
                                            <img
                                              src={item.avatar === '' || item.avatar === null ? HomeValue : item.avatar}
                                              alt=""
                                            />
                                          </div>
                                          <h3 className="table-content display">{item.assetsName}</h3>
                                        </div>
                                      </th>
                                      <td className="table-text-color">
                                        <div className="planner">{item.value}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner big-planner-new">{item.amount}</div>
                                      </td>
                                      <td className="table-text-color">
                                        <div className="planner position-relative" onClick={handleCsvFile}>
                                          <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                          <Upload className="upload-logo" />
                                        </div>
                                      </td>
                                      <td className="table-text-color">
                                        <Edit
                                          style={{ marginLeft: '10px' }}
                                          onClick={() => handleEditForm(item.assetsUuid)}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 left-section asset-wrp-second-side">
                  <div className="total-balance-wrp d-block saving-head">
                    <h4 className="text-white">Total Assets</h4>
                    <h2 className="big-head-new light-green-new">${allAssetTotal.toLocaleString()}</h2>
                    <div className="row mt-3">
                      {top4Assets?.length > 0 &&
                        top4Assets?.map((it) => (
                          <div className="text-white mt-2 this-week">
                            {it.assetsName}: <span>{calculatePercentage(it).toFixed(0)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-dashboard mt-2 ms-0 add-asset-btn-first">
                <Link to="">
                  <button className="comn-btn" onClick={handleModalForm}>
                    Add asset
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </Link>
              </div>
              <div className="row big-margin liability-wrp">
                <h5>Your liabilities </h5>
                <div className="text-14px">These are the things you own.</div>
                <div className="col-lg-9 col-md-9 liability-wrp-first-side">
                  <div>
                    <div className="table-horizontal ">
                      <div>
                        <div className="table-horizontal net-worth-table-second">
                          <div class="col-12 table-horizontal">
                            <table class="table-image new-table">
                              <thead>
                                <tr>
                                  <th scope="col">Liability name</th>
                                  <th scope="col">Value</th>
                                  <th scope="col">Link to an asset?</th>
                                  <th scope="col">Image</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody className="space-table">
                                {liabilityData?.length > 0 &&
                                  liabilityData &&
                                  liabilityData?.map((item, index) => {
                                    return (
                                      <tr className="cursor-pointer">
                                        <th>
                                          <div className="air-image new-img">
                                            <div className="img-box">
                                              <img
                                                src={item.avatar === '' || item.item === null ? Mortgage : item.avatar}
                                                alt=""
                                              />
                                            </div>
                                            <h3 className="table-content display">{item.liabilityName}</h3>
                                          </div>
                                        </th>
                                        <td className="table-text-color">
                                          <div className="planner">{item.value}</div>
                                        </td>
                                        <td className="table-text-color">
                                          <div className="planner" style={{ width: '100%' }}>
                                            {getAssetsNameById(assetData, item.assetsId)}
                                          </div>
                                        </td>
                                        <td className="table-text-color">
                                          <div className="planner position-relative" onClick={handleCsvFile}>
                                            <input type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                                            <Upload className="upload-logo" />
                                          </div>
                                        </td>
                                        <td className="table-text-color">
                                          <Edit
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => handleEditLiability(item.liabilityUuid)}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 left-section liability-wrp-second-side">
                  <div className="total-balance-wrp d-block button-assets saving-head">
                    <h4 className="text-white">Total Liabilities</h4>
                    <h2 className="button-assets-text big-head-new">${allLiabilityTotal.toLocaleString()}</h2>
                    <div className="row mt-3">
                      {top4Liabilities?.length > 0 &&
                        top4Liabilities.map((it) => (
                          <div className="text-white mt-2 this-week">
                            {it.liabilityName}: <span>{calculatePercentageLiabilities(it).toFixed(0)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-dashboard mt-3 ms-0 add-liability-btn-first">
                <Link to="">
                  <button className="comn-btn add-liability" onClick={handleModalLiabilityForm}>
                    Add liability
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen === true && (
        <div>
          {editAssets === true ? (
            <EditAssets
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              fetchAssets={fetchAssets}
              assetsUuid={assetsUuid}
              uuid={uuid}
            />
          ) : (
            <AddAssets modalOpen={modalOpen} setModalOpen={setModalOpen} fetchAssets={fetchAssets} uuid={uuid} />
          )}
        </div>
      )}
      {liabilityOpen === true && (
        <div>
          {editLiability === true ? (
            <EditLiability
              liabilityOpen={liabilityOpen}
              setLiabilityOpen={setLiabilityOpen}
              liabilityUuid={liabilityUuid}
              fetchLiability={fetchLiability}
              uuid={uuid}
            />
          ) : (
            <AddLiability
              liabilityOpen={liabilityOpen}
              setLiabilityOpen={setLiabilityOpen}
              fetchLiability={fetchLiability}
              uuid={uuid}
            />
          )}
        </div>
      )}

      {deleteLiability && (
        <div>
          <DeleteLiability
            liabilityOpen={deleteLiability}
            setLiabilityOpen={setDeleteLiability}
            liabilityUuid={liabilityUuid}
            fetchLiability={fetchLiability}
          />
        </div>
      )}
    </>
  );
};
export default NetWorth;
