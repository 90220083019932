import axios from 'axios';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const DeleteLiability = ({ liabilityOpen, setLiabilityOpen, liabilityUuid, fetchLiability }) => {
  let clientUuid = null;

  try {
    const clientUserUuidData = localStorage.getItem('client-userUuid');
    if (clientUserUuidData) {
      const parsedData = JSON.parse(clientUserUuidData);
      clientUuid = parsedData[0]?.userUuid;
    }
  } catch (error) {
    console.error('Error parsing client-userUuid data:', error);
  }

  const handleClose = () => {
    setLiabilityOpen(!liabilityOpen);
  };

  const handleDeleteLiability = async () => {
    try {
      const response = await axios.delete(`${weburls.Delete_Liability}${clientUuid}/${liabilityUuid}`);
      if (response.status === 200) {
        toast.success(response?.data?.message || 'Liability delete successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          setLiabilityOpen(false);
          fetchLiability();
        }, 1000);
      } else if (response.status === 500) {
        toast.error('Something went wrong please try again.');
      } else {
        console.error('Delete request failed please try again', response);
      }
    } catch (error) {
      console.error('Error:', error);
      setLiabilityOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  return (
    <div className={`modal-background ${liabilityOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content" style={{ width: '450px' }}>
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black ">Are you sure you want to remove this?</span>
            </div>
          </div>
          <div className="row add_income-wrp">
            <div class="button modal-popup-btn-wrp">
              <button type="submit" class="comn-btn common-btn-style delete-btn" onClick={handleClose}>
                No, keep it.
              </button>
              <button type="submit" className="comn-btn common-btn-style " onClick={handleDeleteLiability}>
                Yes, remove it
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteLiability;
