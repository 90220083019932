import React from 'react';
import { Link } from 'react-router-dom';
import Profile from '../../../assests/images/icons/image.png';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import SuperAdminHeader from '../SuperAdminHeader';
import SuperAdminSidebar from '../SuperAdminSidebar';

const EditCompany = () => {
  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />

        <div className="appmain">
          <SuperAdminSidebar />

          <div className="app-main__outer edit-client-details-page">
            <div className="row">
              <div className="col-md-12 back-to-dashboard">
                <h3 className="small-heading">
                  <Link to="/Companies">
                    <i class="fa-solid fa-arrow-left"></i> Back to companies
                  </Link>
                </h3>
              </div>
            </div>
            <div className="setting_background_color mt-3">
              <div className="d-flex justify-content-between">
                <div className="fs-3">
                  <span className="bold black big-top-heading">Edit a company details</span>
                  <div className="setting_text_color">
                    Edit your company details. they'll get updated on the companies list.
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xl-5">
                  <h5 className="bold black heading-new">Contact details </h5>
                  <div className="setting_text_color">This will be displayed on their profile</div>
                </div>
                <div className="col-md-12 col-sm-12 col-xl-7">
                  <div className="row">
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border" placeholder="First name" />
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-6">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border" placeholder="Last name" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 col-xl-7">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border " placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12 col-xl-5">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border " placeholder="Phone number" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                    <div className="col-md-10 col-xl-10 profile-image-side">
                      <div class="input-group">
                        <input type="text" class="form-control input_border " placeholder="Profile Image" />
                        <div className="upload">
                          <Upload className="upload-logo" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 profile-logo-side">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-5 col-sm-12">
                  <h5 className="bold black heading-new">Company details</h5>
                  <div className="setting_text_color">Enter in the details of the company.</div>
                </div>
                <div className="col-md-12 col-xl-7 col-sm-12 your-contact-details-side">
                  <div className="row mt-2">
                    <div className="col-xl-7 col-md-12">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border" placeholder="Company website" />
                      </div>
                    </div>
                    <div className="col-xl-5 col-md-12">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control input_border " placeholder="Company name" />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 profile-div-wrp" style={{ alignItems: 'center' }}>
                    <div className="col-md-10 profile-image-side">
                      <div class="input-group">
                        <input type="text" class="form-control input_border " placeholder="Company Logo" />
                        <div className="upload">
                          <Upload className="upload-logo" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 profile-logo-side">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                    <div className="button mb-4">
                      <button type="button" className="comn-btn button_white">
                        <Link to="/Companies" className="black">
                          Cancel
                        </Link>
                      </button>
                      <button type="button" className="comn-btn save-details common-btn-style">
                        Save edits
                        <i class="fa-solid fa-plus plus-icon-style"></i>
                      </button>
                      <button type="button" className="comn-btn delete-btn">
                        Delete company
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditCompany;
