import React from 'react';
import Logo from '../../assests/images/icons/Icon-logo2.gif';

export const Loading = (props) => {
  return (
    <div className="loading">
      <img src={Logo} className="logo-class" alt="main-logo" />
      {/* <div></div>
      <div></div>
      <div></div> */}
    </div>
  );
};
