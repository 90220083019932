import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const UpdatePlan = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');
  const amount = getWithExpiry('updateAmount');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail, navigate]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const handleConfirmPlan = async () => {
    setLoading(true);
    const body = {
      name: userName,
      email: userEmail,
      amount: amount,
    };

    try {
      if (body.amount === 0) {
        const response = await axios.post(weburls.Free_trail_plan, body);
        if (response.data.code === 1) {
          setLoading(false);
          localStorage.setItem('planDescription', response.data.data.planDescription);
          localStorage.setItem('planDuration', response.data.data.planDuration);
          localStorage.setItem('amount', response.data.data.amount);
          navigate(`/managePlan`);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      } else {
        const response = await axios.post(weburls.Update_plan_payment_session, body);

        // Handle success
        // const session = response.data;
        // window.location = session.url;
        navigate(`/paymentSuccess`);

        // setFormData(response);
        setLoading(false);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data.error);
      } else if (error.request) {
        toast.error('No response received from the server. Please try again.');
      } else {
        toast.error('Payment failed. Please try again.');
      }

      console.error(error);
      setLoading(false);
    }
    // navigate(`/payments?duration=${selectedPlanDuration}&plan=${selectedPlan}`);
  };

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp mt-3" ref={formRef}>
                <p
                  className="text-center welcomeText mt-3"
                  style={{
                    fontSize: '30px',
                  }}
                >
                  Confirm change
                </p>
                <p className="text-center tagtext">Your plan will be changed immediately.</p>
                <div className="mt-4">
                  <div
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <p className="plan-selected-text">Your new plan:</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 account-assets-column box-radis-25">
                      {amount === 0 && (
                        <div className="plan-box-mobile-view-free-account">
                          <p className="select-plans-div-text-mobile">14 day free trial</p>
                          <p className="select-plans-text-mobile">
                            Get access to all features of our Pro plan for 14 days for free including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and unlimited connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#8914FF',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Your new plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(amount === 9.5 || amount === 95) && (
                        <div className="plan-box-mobile-view-free-account">
                          <p className="select-plans-div-text-mobile">
                            Basic - {amount === 9.5 ? '9.50' : amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount === 9.5 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            For the basic budgeter, limited features, including dashboard, budget, 2 goals, accounts, 6
                            months of transactions and 2 connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{ color: '#8914FF', backgroundColor: 'rgba(254, 254, 254)' }}
                            >
                              Your new plan
                            </button>
                          </div>
                        </div>
                      )}
                      {(amount === 103.5 || amount === 10.35) && (
                        <div className="plan-box-mobile-view-standard-account">
                          <p className="select-plans-div-text-mobile">
                            Standard - ${amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount === 10.35 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Our most popular plan with the essentials. Includes access to most features such as
                            dashboard, budget, cashflow, 4 goals, and 5 connections.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#145BFF',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Your new plan
                            </button>
                          </div>
                        </div>
                      )}

                      {(amount === 138 || amount === 13.8) && (
                        <div className="plan-box-mobile-view-pro-account">
                          <p className="select-plans-div-text-mobile">
                            Pro - ${amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount === 13.8 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and 10 connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#56D77A',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Your new plan
                            </button>
                          </div>
                        </div>
                      )}
                      {amount === 82.8 && (
                        <div className="plan-box-mobile-view-ealry-account">
                          <p className="select-plans-div-text-mobile">
                            Early Bird - ${amount}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount === 13.8 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and 10 connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#CD56D7',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                            >
                              Your new plan
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="text-cancel-plan">
                        <strong>NOTE :</strong> All plan upgrades or downgrades are prorated, you will receive a credit
                        for a downgrade and pay the difference between your current plan and your new plan for a
                        upgrade. Read more about this in our{' '}
                        <a
                          href="https://www.budgetbuddie.com/terms-of-use"
                          className="text-green"
                          style={{ fontWeight: 600 }}
                        >
                          Terms of Use
                        </a>
                      </div>
                    </div>

                    <div className="text-center mt-2 pt-2 mb-4">
                      <button type="submit" className="comn-btn verify-btn" onClick={handleConfirmPlan}>
                        Confirm change
                      </button>
                    </div>
                    {/* {loading === true ? (
                      <OverlayContainer>
                        <div>
                          <Loading />
                        </div>
                      </OverlayContainer>
                    ) : (
                      ''
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdatePlan;
