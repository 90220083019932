import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import weburls from '../Weburls/weburls';
import './Login.css';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Please enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please enter a proper email'),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };

  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(weburls.Forget_Password, formData);
      setLoading(false);
      if (response.data.code == 1) {
        setTimeout(() => {
          navigate(`/emailForgetPassword/${formData.email}`);
        }, 1000);
        toast.success('Reset Password link send into email successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
      }

      if (response.data.code == 0) {
        toast.error('User Not Found. Please enter vaild email address', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error('Please try again.');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login-section loginSectionWelcome">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-8 col-lg-6 col-xl-4">
            <div className="login-icon-width logoCss text-center	mb-3">
              <WordLogoBlack />
            </div>

            <div className="loginFormWrp">
              <p className="text-center	mb-3 LoginText">Forgot Password?</p>
              <p className="text-center	mb-5 forgotDescriptionText">
                Don’t worry, it happens to the best of us! Please enter your email below and we’ll send you a reset
                link.
              </p>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-outline mb-5">
                  <label className="form-label" for="form3Example3">
                    Email
                  </label>
                  <input
                    {...register('email', {
                      onChange: (e) => {
                        handleChange(e);
                      },
                    })}
                    value={formData.email}
                    name="email"
                    type="text"
                    id="form3Example3"
                    className="form-control form-control-lg"
                    placeholder="Enter a valid Email"
                  />
                  <p className="mt-1 error-message">{errors.email?.message}</p>
                </div>
                <ToastContainer
                  position="top-right"
                  autoClose={5000} // Auto close after 5 seconds
                  closeOnClick
                />
                {/* {loading === true ? (
                  <OverlayContainer>
                    <div>
                      <Loading />
                    </div>
                  </OverlayContainer>
                ) : (
                  ''
                )} */}
                <div className="text-center mt-4 pt-2">
                  <button type="submit" className="comn-btn sign-btn">
                    Submit email
                  </button>
                  <div className="d-flex flex-row align-items-center justify-content-center mt-4">
                    <span className="textContainer">
                      Remember your password?{' '}
                      <a href="/" className="text-green" style={{ fontWeight: 700 }}>
                        Go back
                      </a>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
