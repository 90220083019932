import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import * as yup from 'yup';
import EmailOtp from '../../assests/images/email-gif.gif';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import weburls from '../Weburls/weburls';
import { getWithExpiry, setWithExpiry } from './checkAndSetLoginExpried';
import './Login.css';
import OtpBox from './OtpBox';

const schema = yup.object().shape({
  username: yup
    .string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please enter a valid email'),
  password: yup.string().required('*Please enter your password'),
});

const EmailVerifactionOTP = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState('');

  const signUpName = getWithExpiry('signUpName');
  const signUpEmail = getWithExpiry('signUpEmail');
  const signUpPassword = getWithExpiry('signUpPassword');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const onSubmitHandler = async () => {
    if (emailOtp.length === 6) {
      const body = {
        otp: emailOtp,
        email: signUpEmail,
      };
      setLoading(true);
      try {
        const response = await axios.post(weburls.Check_email_otp, body);
        if (response.data.message && Number(response.data.code) === 0) {
          toast.error(response.data.message);
        }
        if (Number(response.data.code) === 1) {
          const body = {
            password: signUpPassword,
            firstName: signUpName,
            lastName: '',
            email: signUpEmail,
          };
          const response = await axios.post(weburls.Signup, body);
          if (response.data.message && Number(response.data.code) === 0) {
            toast.error(response.data.message);
          }
          if (Number(response.data.code) === 1) {
            setWithExpiry('userUuid', response.data.userData.userUuid, 2 * 60 * 60 * 1000);
            setWithExpiry('email', response.data.userData.username, 2 * 60 * 60 * 1000);
            setWithExpiry('name', signUpName, 2 * 60 * 60 * 1000);
            setTimeout(() => {
              navigate(`/selectPlans`);
            }, 1000);
          }
        }
      } catch (error) {
        toast.error('Something went wrong. Please try again......');
        console.error(error);
      } finally {
        setLoading(false);
      }
      console.log(emailOtp, 'emailOtp');
    } else {
      toast.error('Please enter 6 digit code');
    }
  };

  const handleOtpSubmit = (otp) => {
    console.log(otp);
    setEmailOtp(otp);
  };

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <ToastContainer position="top-right" autoClose={5000} closeOnClick />
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>

              <div className="loginFormWrp" ref={formRef}>
                <div className="text-center">
                  <img src={EmailOtp} className="email-gif" alt="Email OTP" /> 
                </div>
                <p className="text-center mt-3 emailText margin-botton-head">Lets verify that it’s you.</p>
                <p className="text-center mb-4 tagtext">
                  Check your email and enter the code below to verify it’s you.
                </p>
                <OtpBox length={6} onSubmit={handleOtpSubmit} className="verification-box" />
                <div className="text-center mt-4 pt-2 mb-3">
                  <button className={`comn-btn ${loading ? 'sign-btn-load' : 'sign-btn'}`} onClick={onSubmitHandler}>
                    {loading ? <ClipLoader size={15} color={'#ffffff'} /> : 'Submit'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailVerifactionOTP;



