import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Arrow } from '../../assests/images/icons/arrow.svg';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import AddIncomeForm from '../ModalForm/AddIncome/AddIncomeForm';
import ReoccuringExpense from '../ModalForm/ReoccuringExpense/ReoccuringExpense';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';

const CashFlow = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const [incomeApiData, setIncomeApiData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const [modalExpenseOpen, setExpenseModalOpen] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [weekly, setWeekly] = useState(true);
  const [monthly, setMonthly] = useState(false);
  const [quarterly, setQuarterly] = useState(false);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const weeklyHandler = () => {
    setWeekly(true);
    setMonthly(false);
    setQuarterly(false);
  };
  const monthlyHandler = () => {
    setWeekly(false);
    setMonthly(true);
    setQuarterly(false);
  };
  const quarterlyHandler = () => {
    setWeekly(false);
    setMonthly(false);
    setQuarterly(true);
  };
  const fetchIncome = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Income}${uuid}`);
      if (response?.data) {
        setIncomeApiData(response?.data.incomes);
      }
    } catch (error) {
      toast.error(`Something went wrong, please try again. ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const fetchExpenses = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Expense_And_Transfer_By_Id}${uuid}`);
      if (response?.data) {
        setExpenseData(response?.data);
      }
    } catch (error) {
      toast.error(`Something went wrong, please try again. ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);
  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setAccountData(response?.data?.accounts);
      }
    } catch (error) {
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchIncome();
    fetchCashAccount();
    fetchExpenses();
  }, [fetchIncome, fetchExpenses, fetchCashAccount]);

  const handleModalForm = () => {
    setModalOpen(true);
  };

  const handleExpenseModalForm = () => {
    setExpenseModalOpen(true);
  };
  const processIncomeData = () => {
    return (
      incomeApiData &&
      incomeApiData?.length > 0 &&
      incomeApiData?.map((income) => {
        const weeklyAmount = income?.incomeAmount;
        const fortnightlyAmount = income?.incomeAmount / 2;
        const monthlyAmount = income?.incomeAmount / 4;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const weekAmounts = Array.from({ length: 7 }, (_, index) => amounts[income?.howOften]);

        return {
          ...income,
          weekAmounts,
        };
      })
    );
  };

  const calculateTotalIncome = () => {
    const totalIncome = Array.from({ length: 7 }, () => 0);

    processedIncomeData &&
      processedIncomeData?.length > 0 &&
      processedIncomeData?.forEach((income) => {
        income.weekAmounts.length > 0 &&
          income?.weekAmounts?.forEach((amount, index) => {
            totalIncome[index] += amount;
          });
      });

    return totalIncome;
  };

  const processedIncomeData = processIncomeData();
  const totalIncome = calculateTotalIncome();
  const processIncomeDataMonthly = () => {
    return (
      incomeApiData &&
      incomeApiData?.length > 0 &&
      incomeApiData?.map((income) => {
        const weeklyAmount = income?.incomeAmount * 4;
        const fortnightlyAmount = income?.incomeAmount * 2;
        const monthlyAmount = income?.incomeAmount;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const monthAmounts = Array.from({ length: 4 }, (_, index) => amounts[income?.howOften]);

        return {
          ...income,
          monthAmounts,
        };
      })
    );
  };

  const calculateTotalIncomeMonthly = () => {
    const totalIncome = Array.from({ length: 4 }, () => 0);
    processedIncomeDataMonthly &&
      processedIncomeDataMonthly?.length > 0 &&
      processedIncomeDataMonthly?.forEach((income) => {
        income?.monthAmounts.length > 0 &&
          income?.monthAmounts?.forEach((amount, index) => {
            totalIncome[index] += amount;
          });
      });

    return totalIncome;
  };
  const processedIncomeDataMonthly = processIncomeDataMonthly();
  const totalIncomeMonthly = calculateTotalIncomeMonthly();

  const processIncomeDataQuarterly = () => {
    return (
      incomeApiData &&
      incomeApiData?.length > 0 &&
      incomeApiData?.map((income) => {
        const weeklyAmount = income?.incomeAmount * 12;
        const fortnightlyAmount = income?.incomeAmount * 6;
        const monthlyAmount = income?.incomeAmount * 3;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const quarterAmounts = Array.from({ length: 6 }, (_, index) => amounts[income?.howOften]);

        return {
          ...income,
          quarterAmounts,
        };
      })
    );
  };

  const calculateTotalIncomeQuarterly = () => {
    const totalIncome = Array.from({ length: 6 }, () => 0);
    processedIncomeDataQuarterly &&
      processedIncomeDataQuarterly?.length > 0 &&
      processedIncomeDataQuarterly?.forEach((income) => {
        income?.quarterAmounts.length > 0 &&
          income?.quarterAmounts?.forEach((amount, index) => {
            totalIncome[index] += amount;
          });
      });

    return totalIncome;
  };
  const processedIncomeDataQuarterly = processIncomeDataQuarterly();
  const totalIncomeQuarterly = calculateTotalIncomeQuarterly();

  // expenses data logic
  const processExpenseDataWeekly = () => {
    return (
      expenseData &&
      expenseData?.length > 0 &&
      expenseData?.map((it) => {
        const weeklyAmount = it.expenseUuid ? it?.expenseAmount : it.transferAmount;
        const fortnightlyAmount = it.expenseUuid ? it?.expenseAmount / 2 : it.transferAmount / 2;
        const monthlyAmount = it.expenseUuid ? it?.expenseAmount / 4 : it.transferAmount / 4;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const weekAmounts = Array.from({ length: 7 }, (_, index) => amounts[it?.howOften]);

        return {
          ...it,
          weekAmounts,
        };
      })
    );
  };

  const calculateTotalExpense = () => {
    const totalExpense = Array.from({ length: 7 }, () => 0);

    processedExpenseData &&
      processedExpenseData?.length > 0 &&
      processedExpenseData?.forEach((it) => {
        it.weekAmounts.length > 0 &&
          it?.weekAmounts?.forEach((amount, index) => {
            totalExpense[index] += amount;
          });
      });

    return totalExpense;
  };

  const processedExpenseData = processExpenseDataWeekly();
  const totalExpense = calculateTotalExpense();

  const processExpenseDataMonthly = () => {
    return (
      expenseData &&
      expenseData?.length > 0 &&
      expenseData?.map((it) => {
        const weeklyAmount = it.expenseUuid ? it?.expenseAmount * 4 : it.transferAmount * 4;
        const fortnightlyAmount = it.expenseUuid ? it?.expenseAmount * 2 : it.transferAmount * 2;
        const monthlyAmount = it.expenseUuid ? it?.expenseAmount : it.transferAmount;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const monthAmounts = Array.from({ length: 4 }, (_, index) => amounts[it?.howOften]);

        return {
          ...it,
          monthAmounts,
        };
      })
    );
  };

  const calculateTotalExpenseMonthly = () => {
    const totalExpense = Array.from({ length: 4 }, () => 0);

    processedExpenseDataMonthly &&
      processedExpenseDataMonthly?.length > 0 &&
      processedExpenseDataMonthly?.forEach((it) => {
        it.monthAmounts.length > 0 &&
          it?.monthAmounts?.forEach((amount, index) => {
            totalExpense[index] += amount;
          });
      });

    return totalExpense;
  };

  const processedExpenseDataMonthly = processExpenseDataMonthly();
  const totalExpenseMonthly = calculateTotalExpenseMonthly();

  const processExpenseDataQuarterly = () => {
    return (
      expenseData &&
      expenseData?.length > 0 &&
      expenseData?.map((it) => {
        const weeklyAmount = it.expenseUuid ? it?.expenseAmount * 13 : it.transferAmount * 4;
        const fortnightlyAmount = it.expenseUuid ? it?.expenseAmount * 6 : it.transferAmount * 6;
        const monthlyAmount = it.expenseUuid ? it?.expenseAmount * 3 : it.transferAmount * 3;

        const amounts = {
          Weekly: weeklyAmount,
          Fortnightly: fortnightlyAmount,
          Monthly: monthlyAmount,
        };

        const quarterAmounts = Array.from({ length: 6 }, (_, index) => amounts[it?.howOften]);

        return {
          ...it,
          quarterAmounts,
        };
      })
    );
  };

  const calculateTotalExpenseQuarterly = () => {
    const totalExpense = Array.from({ length: 6 }, () => 0);

    processedExpenseDataQuarterly &&
      processedExpenseDataQuarterly?.length > 0 &&
      processedExpenseDataQuarterly?.forEach((it) => {
        it.quarterAmounts.length > 0 &&
          it?.quarterAmounts?.forEach((amount, index) => {
            totalExpense[index] += amount;
          });
      });

    return totalExpense;
  };

  const processedExpenseDataQuarterly = processExpenseDataQuarterly();
  const totalExpenseQuarterly = calculateTotalExpenseQuarterly();

  const moneyLeftOverWeekly = totalIncome.map((income, index) => income - totalExpense[index]);
  const moneyLeftOverMonthly = totalIncomeMonthly.map((income, index) => income - totalExpenseMonthly[index]);
  const moneyLeftOverlyQuarterly = totalIncomeQuarterly.map((income, index) => income - totalExpenseQuarterly[index]);

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer edit-cashflow-container-main">
              <div className="row top-nav">
                <div className="col-md-4 edit-cashflow-title-heading">
                  <div className="bold big-head-new">Edit Cashflow</div>
                  <div className="fst-normal setting_text_color">
                    See a forecast of where your moneys coming in and going out.
                  </div>
                </div>
                <div className="col-md-8 right-section back-portal-edit-cashflow">
                  <div className=" right-section">
                    <div className="edit-btn" style={{ width: '327px', height: '60px', marginRight: '30px' }}>
                      <div className="left">
                        <h4>View Cashflow Calendar</h4>
                        <p style={{ color: '#04baff' }}>
                          <Link to="/Clients/Cashflowcalender">Get the full overview.</Link>
                        </p>
                      </div>

                      <div className="right">
                        <a href="#">
                          <Arrow />
                        </a>
                      </div>
                    </div>
                    <div className="profile-dashboard">
                      <img src={Shape} alt="Mortage_img" onClick={togglePopupInfo} style={{ width: '20px' }} />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Cashflow 🤑"
                        description={[
                          'Your cashflow is determined by the data you enter in your budget. Based on how often you get paid or spend money, this shows when either of these two will occur next in the future',
                          'You can change views for your cash flow to show weekly, monthly, or quarterly incomings and outgoings.',
                          'Review your balance left over per week, month, or quarter. If it’s in the negative, make some adjustments. If it’s in green, then you’re good to go.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton edit-cashflow-week-month-wrp">
                <div className="col-md-12 col-lg-12">
                  <ul
                    class="nav nav-pills mb-3 budget-tabs img-back tabs-background-color"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={weeklyHandler}
                      >
                        Weekly
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={monthlyHandler}
                      >
                        Monthly
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                        onClick={quarterlyHandler}
                      >
                        Quarterly
                      </button>
                    </li>
                  </ul>
                </div>
                {weekly && (
                  <div
                    class="tab-content col-xl-12 col-lg-12 col-sm-12 edit-cashflow-new-table-section"
                    id="pills-tabContent"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="row mt-3">
                        <div className="col-xl-12 top-head-section">
                          <div style={{ display: 'inline-block' }}>
                            <h3 className="bold">Your weekly income after tax</h3>
                            <p>Regular income is what you consistently get paid after tax each week.</p>
                          </div>

                          <div className="profile-dashboard " style={{ float: 'right' }}>
                            <Link to={`/SpendingsAccount/${uuid}`}>
                              <button className="comn-btn">
                                Select spendings account <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table className="table-image new-table income-after">
                                  <thead>
                                    <tr>
                                      <th scope="col">Income type</th>
                                      <th scope="col">This week</th>
                                      <th scope="col">Week 2</th>
                                      <th scope="col">Week 3</th>
                                      <th scope="col">Week 4</th>
                                      <th scope="col">Week 5</th>
                                      <th scope="col">Week 6</th>
                                      <th scope="col">Week 7</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedIncomeData &&
                                      processedIncomeData?.length > 0 &&
                                      processedIncomeData?.map((income) => (
                                        <tr key={income.incomeUuid} className="cursor-pointer">
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{income?.incomeType}</h3>
                                            </div>
                                          </th>
                                          {income.weekAmounts.map((amount, index) => (
                                            <td key={index} className="table-text-color">
                                              <div className="planner">{amount}</div>
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                  </tbody>
                                  {/* Total Income Row */}
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer income-type-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total income</h3>
                                        </div>
                                      </th>
                                      {totalIncome &&
                                        totalIncome?.length > 0 &&
                                        totalIncome?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn" onClick={handleModalForm}>
                                Add Income <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your weekly expenses & transfers</h3>
                          <p>Your reoccurring expenses are what bills you pay each week.</p>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Expense type</th>
                                      <th scope="col">This week</th>
                                      <th scope="col">Week 2</th>
                                      <th scope="col">Week 3</th>
                                      <th scope="col">Week 4</th>
                                      <th scope="col">Week 5</th>
                                      <th scope="col">Week 6</th>
                                      <th scope="col">Week 7</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedExpenseData &&
                                      processedExpenseData?.length > 0 &&
                                      processedExpenseData?.map((it) => (
                                        <tr
                                          key={it.expenseUuid ? it.expenseUuid : it.transferUuid}
                                          className="cursor-pointer"
                                        >
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{it?.expenseName}</h3>
                                            </div>
                                          </th>
                                          {it.weekAmounts.map((amount, index) => (
                                            <td key={index} className="table-text-color">
                                              <div className="planner">{amount}</div>
                                            </td>
                                          ))}
                                        </tr>
                                      ))}

                                    <tr className="cursor-pointer income-after-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total expenses & transfers</h3>
                                        </div>
                                      </th>

                                      {totalExpense &&
                                        totalExpense?.length > 0 &&
                                        totalExpense?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn add-expense" onClick={handleExpenseModalForm}>
                                Add expense <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your money left over</h3>
                          <p>
                            This shows what money you have left over after receiving your income and paying your
                            expenses
                          </p>
                        </div>
                        <div className="col-lg-12 new-table third-table-edit-cashflow">
                          <div>
                            <div className="table-horizontal">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">This week</th>
                                      <th scope="col">Week 2</th>
                                      <th scope="col">Week 3</th>
                                      <th scope="col">Week 4</th>
                                      <th scope="col">Week 5</th>
                                      <th scope="col">Week 6</th>
                                      <th scope="col">Week 7</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Balance left over</h3>
                                        </div>
                                      </th>

                                      {moneyLeftOverWeekly &&
                                        moneyLeftOverWeekly?.length > 0 &&
                                        moneyLeftOverWeekly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div
                                              className={`planner ${
                                                total > 0 ? 'income-type-background' : 'income-after-background'
                                              } `}
                                            >
                                              {total}
                                            </div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* monthly data  */}
                {monthly && (
                  <div
                    class="tab-content col-xl-12 col-lg-12 col-sm-12 edit-cashflow-new-table-section"
                    id="pills-tabContent"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="row mt-3">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your monthly income after tax</h3>
                          <p>Regular income is what you consistently get paid after tax each month.</p>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table className="table-image new-table income-after">
                                  <thead>
                                    <tr>
                                      <th scope="col">Income type</th>
                                      <th scope="col">This month</th>
                                      <th scope="col">Month 2</th>
                                      <th scope="col">Month 3</th>
                                      <th scope="col">Month 4</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedIncomeDataMonthly &&
                                      processedIncomeDataMonthly?.length > 0 &&
                                      processedIncomeDataMonthly?.map((income) => (
                                        <tr key={income.incomeUuid} className="cursor-pointer">
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{income?.incomeType}</h3>
                                            </div>
                                          </th>
                                          {income?.monthAmounts &&
                                            income?.monthAmounts.length > 0 &&
                                            income?.monthAmounts.map((amount, index) => (
                                              <td key={index} className="table-text-color">
                                                <div className="planner">{amount}</div>
                                              </td>
                                            ))}
                                        </tr>
                                      ))}
                                  </tbody>
                                  {/* Total Income Row */}
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer income-type-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total income</h3>
                                        </div>
                                      </th>
                                      {totalIncomeMonthly &&
                                        totalIncomeMonthly?.length > 0 &&
                                        totalIncomeMonthly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn" onClick={handleModalForm}>
                                Add Income <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your monthly expenses & transfers</h3>
                          <p>Your reoccurring expenses are what bills you pay each month.</p>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Expense type</th>
                                      <th scope="col">This month</th>
                                      <th scope="col">Month 2</th>
                                      <th scope="col">Month 3</th>
                                      <th scope="col">Month 4</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedExpenseDataMonthly &&
                                      processedExpenseDataMonthly?.length > 0 &&
                                      processedExpenseDataMonthly?.map((it) => (
                                        <tr
                                          key={it.expenseUuid ? it.expenseUuid : it.transferUuid}
                                          className="cursor-pointer"
                                        >
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{it?.expenseName}</h3>
                                            </div>
                                          </th>
                                          {it.monthAmounts.map((amount, index) => (
                                            <td key={index} className="table-text-color">
                                              <div className="planner">{amount}</div>
                                            </td>
                                          ))}
                                        </tr>
                                      ))}

                                    <tr className="cursor-pointer income-after-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total expenses & transfers</h3>
                                        </div>
                                      </th>

                                      {totalExpenseMonthly &&
                                        totalExpenseMonthly?.length > 0 &&
                                        totalExpenseMonthly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn add-expense" onClick={handleExpenseModalForm}>
                                Add expense <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your money left over</h3>
                          <p>
                            This shows what money you have left over after receiving your income and paying your
                            expenses
                          </p>
                        </div>
                        <div className="col-lg-12 new-table third-table-edit-cashflow">
                          <div>
                            <div className="table-horizontal">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">This month</th>
                                      <th scope="col">Month 2</th>
                                      <th scope="col">Month 3</th>
                                      <th scope="col">Month 4</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Balance left over</h3>
                                        </div>
                                      </th>

                                      {moneyLeftOverMonthly &&
                                        moneyLeftOverMonthly?.length > 0 &&
                                        moneyLeftOverMonthly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div
                                              className={`planner ${
                                                total > 0 ? 'income-type-background' : 'income-after-background'
                                              } `}
                                            >
                                              {total}
                                            </div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Quarter data  */}

                {quarterly && (
                  <div
                    class="tab-content col-xl-12 col-lg-12 col-sm-12 edit-cashflow-new-table-section"
                    id="pills-contact"
                  >
                    <div
                      class="tab-pane fade show active"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="row mt-3">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your quarterly income after tax</h3>
                          <p>Regular income is what you consistently get paid after tax each quarter.</p>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table className="table-image new-table income-after">
                                  <thead>
                                    <tr>
                                      <th scope="col">Income type</th>
                                      <th scope="col">This quarter</th>
                                      <th scope="col">Quarter 2</th>
                                      <th scope="col">Quarter 3</th>
                                      <th scope="col">Quarter 4</th>
                                      <th scope="col">Quarter 5</th>
                                      <th scope="col">Quarter 6</th>
                                      {/*<th scope="col">Month 7</th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedIncomeDataQuarterly &&
                                      processedIncomeDataQuarterly?.length > 0 &&
                                      processedIncomeDataQuarterly?.map((income) => (
                                        <tr key={income.incomeUuid} className="cursor-pointer">
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{income?.incomeType}</h3>
                                            </div>
                                          </th>
                                          {income?.quarterAmounts &&
                                            income?.quarterAmounts.length > 0 &&
                                            income.quarterAmounts.map((amount, index) => (
                                              <td key={index} className="table-text-color">
                                                <div className="planner">{amount}</div>
                                              </td>
                                            ))}
                                        </tr>
                                      ))}
                                  </tbody>
                                  {/* Total Income Row */}
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer income-type-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total income</h3>
                                        </div>
                                      </th>
                                      {totalIncomeQuarterly &&
                                        totalIncomeQuarterly?.length > 0 &&
                                        totalIncomeQuarterly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn" onClick={handleModalForm}>
                                Add Income <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your quarterly expenses & transfers</h3>
                          <p>Your reoccurring expenses are what bills you pay each quarter.</p>
                        </div>
                        <div className="col-lg-12 new-table">
                          <div>
                            <div className="table-horizontal first-table-edit-cashflow">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col">Expense type</th>
                                      <th scope="col">This quarter</th>
                                      <th scope="col">Quarter 2</th>
                                      <th scope="col">Quarter 3</th>
                                      <th scope="col">Quarter 4</th>
                                      <th scope="col">Quarter 5</th>
                                      <th scope="col">Quarter 6</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    {processedExpenseDataQuarterly &&
                                      processedExpenseDataQuarterly?.length > 0 &&
                                      processedExpenseDataQuarterly?.map((it) => (
                                        <tr
                                          key={it.expenseUuid ? it.expenseUuid : it.transferUuid}
                                          className="cursor-pointer"
                                        >
                                          <th>
                                            <div className="air-image">
                                              <h3 className="table-content display">{it?.expenseName}</h3>
                                            </div>
                                          </th>
                                          {it.quarterAmounts.map((amount, index) => (
                                            <td key={index} className="table-text-color">
                                              <div className="planner">{amount}</div>
                                            </td>
                                          ))}
                                        </tr>
                                      ))}

                                    <tr className="cursor-pointer income-after-background">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Total expenses & transfers</h3>
                                        </div>
                                      </th>

                                      {totalExpenseQuarterly &&
                                        totalExpenseQuarterly?.length > 0 &&
                                        totalExpenseQuarterly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div className="planner bg-white">{total}</div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="profile-dashboard mt-3">
                            <Link to="">
                              <button className="comn-btn add-expense" onClick={handleExpenseModalForm}>
                                Add expense <i class="fa-solid fa-plus"></i>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row expenses-wrp">
                        <div className="col-xl-12 top-head-section">
                          <h3 className="bold">Your money left over</h3>
                          <p>
                            This shows what money you have left over after receiving your income and paying your
                            expenses
                          </p>
                        </div>
                        <div className="col-lg-12 new-table third-table-edit-cashflow">
                          <div>
                            <div className="table-horizontal">
                              <div class="col-12 table-horizontal">
                                <table class="table-image new-table income-after ">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">This quarter</th>
                                      <th scope="col">Quarter 2</th>
                                      <th scope="col">Quarter 3</th>
                                      <th scope="col">Quarter 4</th>
                                      <th scope="col">Quarter 5</th>
                                      <th scope="col">Quarter 6</th>
                                    </tr>
                                  </thead>
                                  <tbody className="space-table">
                                    <tr className="cursor-pointer">
                                      <th>
                                        <div className="air-image">
                                          <h3 className="table-content display">Balance left over</h3>
                                        </div>
                                      </th>

                                      {moneyLeftOverlyQuarterly &&
                                        moneyLeftOverlyQuarterly?.length > 0 &&
                                        moneyLeftOverlyQuarterly?.map((total, index) => (
                                          <td key={index} className="table-text-color">
                                            <div
                                              className={`planner ${
                                                total > 0 ? 'income-type-background' : 'income-after-background'
                                              } `}
                                            >
                                              {total}
                                            </div>
                                          </td>
                                        ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen === true && (
        <div>
          <AddIncomeForm
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            fetchIncome={fetchIncome}
            accountData={accountData}
            uuid={uuid}
          />
        </div>
      )}
      {modalExpenseOpen === true && (
        <ReoccuringExpense
          modalOpen={modalExpenseOpen}
          setModalOpen={setExpenseModalOpen}
          fetchExpense={fetchExpenses}
          uuid={uuid}
        />
      )}
    </>
  );
};
export default CashFlow;
