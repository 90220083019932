import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Chevron } from '../../assests/images/icons/chevron-left.svg';
import Profile from '../../assests/images/icons/image.png';
import weburls from '../Weburls/weburls';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import EditAdvisor from '../ModalForm/Advisor/EditAdvisor';
const AdvisorList = () => {
  const id = JSON.parse(localStorage.getItem('userData'));
  const [advisor, SetAdvisorList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmit, setSubmit] = useState(false);

  const [advisorDetail, SetAdvisorDetail] = useState({});
    const settingApi = async () => {
    const response = await axios.get(weburls.Advisor_list + `${id.advisorId}`);
    if (response?.data?.advisors) {
      SetAdvisorList(response?.data?.advisors)
    } else {
    }
  };

  useEffect(() => {
    settingApi();
    if(isSubmit){
       settingApi();
    }
  }, [isSubmit]);

  const handleModalForm = (item) => {
    SetAdvisorDetail(item)
    setModalOpen(true);
    setSubmit(false)
  };

  return (
    <>
        <div className="col-md-12">
          {Array.isArray(advisor) && advisor.length > 0
            ? advisor &&
            advisor?.map((item, index) => (
                <div className="setting-your-advisor mt-3" key={index}>
                  <div className="setting-nw d-flex">
                    <div className="setting-img">
                      <img src={Profile} alt="profile" />

                    </div>
                    <div className="setting-your-advisor-text bold black">
                      {item?.firstName}  {item?.lastName}
                    </div>
                  </div>

                  <div className="d-flex mt-3">
                    <Edit className="cursor-pointer" onClick={()=>handleModalForm(item)}/>
                  </div>

                  <div className="d-flex">
                    <div className="setting-your-advisor-view ">{item?.roleName}</div>
                    <div className="chevron">
                      <Chevron className="chevron-logo" />
                    </div>
                  </div>

                </div>
              ))
            : ('No advisor found')}
            {modalOpen === true && (
        <div>
            <EditAdvisor
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              setSubmit={setSubmit}
              data={advisorDetail}
            />
        </div>
      )}
        </div>
    </>
  );
};
export default AdvisorList;
