import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PopupHowOftenModal from '../../Popup/PopupHowOften';
import weburls, { BudgetBuddie } from '../../Weburls/weburls';

const Accountransfer = ({ uuid, accountData }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [editAccount, setEditAccount] = useState(false);
  const [accountApiData, setAccountApiData] = useState([]);
  const [transferUuid, setTransferUuid] = useState(null);
  const [isPopupOpenMoneyLeftOver, setPopupOpenMoneyLeftOver] = useState(false);
  const [selectedAccounTransferOption, setSelectedAccounTransferOption] = useState('Weekly');
  const [selectedMoneyLeftOverOption, setSelectedMoneyLeftOverOption] = useState('Weekly');
  const [totalExpenseData, setTotalExpenseData] = useState();
  const [totalBudgetData, setTotalBudgetData] = useState();
  const [isPopupOpenHowOften, setPopupOpenHowOften] = useState(false);
  const [isPopupOpenHowOftenTotal, setPopupOpenHowOftenTotal] = useState(false);

  const togglesPopupOpenHowOften = () => setPopupOpenHowOften(!isPopupOpenHowOften);
  const togglesPopupOpenHowOftenTotal = () => setPopupOpenHowOftenTotal(!isPopupOpenHowOftenTotal);

  const handleOptionChange = (option) => {
    setSelectedAccounTransferOption(option);
    setPopupOpenHowOften(false);
  };
  const handleTotalValueOptionChange = (option) => {
    setSelectedMoneyLeftOverOption(option);
    setPopupOpenHowOftenTotal(false);
  };

  const fetchAccountTransfer = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Account_Transfer_Get_List}${uuid}`);
      if (response?.data) {
        setAccountApiData(response?.data?.transfers);
      }
    } catch (error) {
      toast.error(`Something went wrong ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    fetchAccountTransfer();
  }, [fetchAccountTransfer]);

  const fetchTotalExpense = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BudgetBuddie}api/budgetSummary/getAllExpense/${uuid}/?option=${selectedAccounTransferOption}`,
      );
      setTotalExpenseData(response?.data);
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [selectedAccounTransferOption, uuid]);

  const fetchTotalBudget = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BudgetBuddie}api/budgetSummary/getTotalBudget/${uuid}/?option=${selectedMoneyLeftOverOption}`,
      );
      setTotalBudgetData(response?.data);
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [selectedMoneyLeftOverOption, uuid]);

  useEffect(() => {
    fetchTotalExpense();
  }, [fetchTotalExpense]);

  useEffect(() => {
    fetchTotalBudget();
  }, [fetchTotalBudget]);

  return (
    <>
      {/* <div className="row reoccuring-wrp">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="col-xl-12 top-head-section">
            <h3 className="semi-heading-edit-budget">Your regular account transfers</h3>
            <p>
              Your transfers are things like transferring money regularly to a savings account. This allows you to
              forecast what your account balances will be in the future.
            </p>
          </div>
        </div>
      </div> */}
      <div className="mt-2">
        <div className="col-lg-12 new-table regular-account-transfer-wrapper">
          <div>
            {/* <div className="table-horizontal regular-account-transfer-main-side">
              <div class="col-12 table-horizontal">
                <table class="table-image new-table regular-account-transfer-inner">
                  <thead>
                    <tr>
                      <th scope="col">Expense name</th>
                      <th scope="col">Transfer amount</th>
                      <th scope="col">Last transfer date</th>
                      <th scope="col">How Often? </th>
                      <th scope="col">Transfer from</th>
                      <th scope="col">Transfer to</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="space-table">
                    {accountApiData?.length > 0 &&
                      accountApiData &&
                      accountApiData.map((item, index) => {
                        return (
                          <tr className="cursor-pointer">
                            <th>
                              <div className="air-image">
                                <h3 className="table-content display" style={{ fontSize: '20px' }}>
                                  {item.expenseType}
                                </h3>
                              </div>
                            </th>
                            <td className="table-text-color">
                              <div className="planner">{item.transferAmount}</div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner">{formatDate(item.lastTransferDate)}</div>
                            </td>

                            <td className="table-text-color">
                              <select className="planner">
                                <option selected>{item.howOften}</option>
                              </select>
                            </td>
                            <td className="table-text-color">
                              <div className="planner big-planner">{item.transferFromAccountName}</div>
                            </td>
                            <td className="table-text-color">
                              <div className="planner big-planner">{item.transferToAccountName}</div>
                            </td>
                            <td className="table-text-color" onClick={() => handleModalEditForm(item.transferUuid)}>
                              <Edit />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="income-btn-wrp col-xl-12">
                  <button className="new-btn red-btn" onClick={handleModalForm}>
                    Add Transfer <Bigplus />
                  </button>
                </div>
              </div>
            </div> */}
            <div
              className="col-xl-9 mt-5 total-income d-flex income-after-background edit-your-budget-total-income-expenses-wrapper"
              style={{ alignItems: 'center' }}
            >
              <div className="inline col-md-4">
                <h4 className="table-content bold">Total expenses & transfers</h4>
              </div>
              <div className="inline col-md-4 expenses-transfers-section">
                <div className="content-blck" style={{ alignItems: 'center' }}>
                  ${totalExpenseData?.totalExpense ?? 0}
                </div>
              </div>
              <div className="inline col-md-4 col-xl-6 total-inc-first">
                <div className=" planner-for-weekly edit-table">
                  <button
                    style={{
                      backgroundColor: '#D04F4F',
                      color: 'white',
                      borderRadius: '30px',
                      width: '335px',
                      height: '40px',
                    }}
                    type="button"
                    className={`group-button popoup-color`}
                    onClick={togglesPopupOpenHowOften}
                    aria-haspopup="true"
                    aria-expanded={isPopupOpenHowOften}
                  >
                    {selectedAccounTransferOption ? selectedAccounTransferOption : 'Weekly'}
                  </button>
                  {isPopupOpenHowOften && (
                    <PopupHowOftenModal
                      isOpen={isPopupOpenHowOften}
                      onClose={togglesPopupOpenHowOften}
                      options={['Weekly', 'Fortnightly', 'Monthly', 'Quarterly', 'Annually']}
                      handleOptionClick={handleOptionChange}
                      isExpense={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row reoccuring-wrp">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="col-xl-12 top-head-section">
                  <h3>Your money left over</h3>
                  <p>This shows what money you have left over after receiving your income and paying your expenses</p>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 mt-5 total-income d-flex edit-your-budget-total-income-expenses-wrapper"
              style={{ alignItems: 'center' }}
            >
              <div className="inline col-md-4 ">
                <h4 className="table-content bold">Total : ${totalBudgetData?.totalBudget ?? 0}</h4>
              </div>

              <div
                className="col-md-4  expenses-transfers-section"
                style={{ display: 'inline-flex', flexDirection: 'column-reverse' }}
              >
                <div className=" planner-for-weekly edit-table" style={{ borderRadius: '15px', marginLeft: '20px' }}>
                  <button
                    style={{
                      backgroundColor: '#469B88',
                      color: 'white',
                      borderRadius: '30px',
                      width: '207px',
                      height: '29px',
                    }}
                    type="button"
                    className={`group-button popoup-color`}
                    onClick={togglesPopupOpenHowOftenTotal}
                    aria-haspopup="true"
                    aria-expanded={isPopupOpenHowOftenTotal}
                  >
                    {selectedMoneyLeftOverOption ? selectedMoneyLeftOverOption : 'Weekly'}
                  </button>
                  {isPopupOpenHowOftenTotal && (
                    <PopupHowOftenModal
                      isOpen={isPopupOpenHowOftenTotal}
                      onClose={togglesPopupOpenHowOftenTotal}
                      options={['Weekly', 'Fortnightly', 'Monthly', 'Quarterly', 'Annually']}
                      handleOptionClick={handleTotalValueOptionChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Accountransfer;
