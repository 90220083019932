import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Backlog } from '../../assests/images/icons/back-goals.svg';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupColorModal from '../Popup/PopupColorModel';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  goalName: yup.string().min(3, '*goalName is Required').required('*goalName is Required'),
  value: yup.string().required('*Goal dollar value  is Required'),
  currentBalance: yup.number(),
  contribution: yup.string().required('*Contribution is Required'),
});
const AddNewFinancial = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [selectedTab, setSelectedTab] = useState('Weekly');
  const [accountData, setAccountData] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedAccountError, setSelectedAccountError] = useState('');
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const navigate = useNavigate();
  const [goalData, setGoalData] = useState({
    goalName: '',
    value: '',
    contribution: '',
    howOften: '',
    currentBalance: '',
    reference: '',
    colour: '',
    icon: '',
    startDate: null,
    endDate: null,
  });

  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const { uuid } = useParams();
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  // const fetchCashAccount = useCallback(async () => {
  //   try {
  //     const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
  //     if (response?.data) {
  //       setAccountData(response?.data?.accounts);
  //     }
  //   } catch (error) {
  //     console.error('error', error);
  //     toast.error('Something went wrong please try again.', {
  //       position: 'top-right',
  //       autoClose: 2000,
  //     });
  //   }
  // }, [uuid]);

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setGoalData({ ...goalData, howOften: tabName });
  };
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalData({
      ...goalData,
      [name]: value,
    });
  };

  const colorCss = (color) => {
    // Define colorCss
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
      case 'Red':
        return 'red-color';
      case 'Yellow':
        return 'yellow-color';
      case 'Blue':
        return 'blue-color';
      case 'Purple':
        return 'purple-color-color';
      case 'Orange':
        return 'orange-color';
      case 'Pink':
        return 'pink-color';
      case 'Light blue':
        return 'light-blue-color';
      case 'Lime green':
        return 'lime-green-color';
      case 'Dark red':
        return 'dark-red-color';
      case 'Green':
        return 'green-color';
      case 'Dark blue':
        return 'dark-blue-color';
      default:
        return 'green';
    }
  };

  const handleOptionColorClick = (option) => {
    setGoalData({ ...goalData, colour: option });
    setPopupOpenColor(false);
  };
  const addGoal = async () => {
    try {
      const response = await axios.post(weburls.Add_Goals, {
        ...goalData,
        userUuid: uuid,
        reference: goalData.goalName.slice(0, 12),
      });
      if (response.status === 200) {
        toast.success('Goal successfully..', {
          position: 'top-right',
          autoClose: 2000,
        });
        navigate(`/Financial/${uuid}`);
      } else if (response.status === 500) {
        toast.error('Goal details not saved. Please try again.');
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  // const handleAccountChange = (e) => {
  //   const selectedValue = e.target.value;
  //   if (selectedValue) {
  //     setSelectedAccountError('');
  //   }
  //   setSelectedAccount(selectedValue);
  //   setGoalData({ ...goalData, accountUuid: selectedValue });
  // };

  // useEffect(() => {
  //   fetchCashAccount();
  // }, [fetchCashAccount]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer financial-goals-main">
              <div className="row">
                <div className="col-lg-4 col-sm-12 main-heading-financial">
                  <div className="fs-3 bold black big-head-new">Your Financial Goals</div>
                  <div className="fst-normal setting_text_color">
                    Let's set some goals, it’s always good having goals and something to aim for.
                  </div>
                </div>

                <div className="col-lg-8 col-sm-12 col-md-12 right-section back-to-portal-financial-goals">
                  <div className="profile-dashboard">
                    <Link to={`/Financial/${uuid}`}>
                      <div className="edit-btn-col">
                        <div
                          className="edit-btn"
                          href="#"
                          style={{ width: '223px', height: '60px', marginRight: '32px' }}
                        >
                          <div>
                            <Backlog />
                          </div>
                          <div className="left">
                            <h4 className="bold">Back to Goals</h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="profile-dashboard">
                    <img src={Shape} alt="Mortage_img" onClick={togglePopupInfo} />
                  </div>
                  {isPopupOpenInfo && (
                    <PopupInfoModal
                      isOpen={isPopupOpenInfo}
                      onClose={togglePopupInfo}
                      title="Goals 🎯"
                      description={[
                        'Your goals are where you can set and track any financial milestones you’d like to achieve',
                        'This can be big or small. You can customise goals to exactly what you want.',
                        'Each goal widget predicts when you’re expected to achieve the goal and shows you how on track you are.Add a new goal by tapping ‘Add a new goal’ button.',
                        'You can also view and edit the details of each goal by tapping ‘See detail’.',
                      ]}
                    />
                  )}
                  <div className="profile-dashboard">
                    <div className="profile-img">
                      <img src={Profile} alt="profile" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-12 add-new-goal-wrpp">
                  <div className="mt-3 edit-goal-ami">
                    <form onSubmit={handleSubmit(addGoal)}>
                      <div>
                        <div className="bold sub-head-text">New goal</div>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <div className="fw-bold inter">Name</div>
                          <input
                            type="text"
                            name="goalName"
                            value={goalData.goalName}
                            {...register('goalName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            className="edit-goal-house w-75 py-2 inter add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        <p className="error-message">{errors.goalName?.message}</p>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <div className="fw-bold inter">Goal dollar value</div>
                          <input
                            type="text"
                            name="value"
                            value={goalData.value}
                            {...register('value', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            className="edit-goal-house w-50 py-2 inter add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        <p className="error-message">{errors.value?.message}</p>
                        <div className="edit-goal-box">
                          <div className="d-flex justify-content-between">
                            <div className="fw-bold inter mt-1">Contributions</div>
                            <input
                              type="text"
                              name="contribution"
                              value={goalData.contribution}
                              {...register('contribution', {
                                onChange: (e) => {
                                  handleChange(e);
                                },
                              })}
                              className="edit-goal-house w-75 py-2 inter add-goal"
                              style={{ border: 'none' }}
                            />
                          </div>
                          <p className="error-message">{errors.contribution?.message}</p>
                          <div className="d-flex justify-content-between mt-2 align-items-center">
                            <div className="fw-bold inter">How often?</div>
                            <div className="col-md-9 col-lg-9 togger-wrp-financial">
                              <ul className="nav nav-pills budget-tabs img-back" id="pills-tab" role="tablist">
                                <li
                                  className={`nav-item-financial ${selectedTab === 'Weekly' ? 'active' : ''}`}
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link inter"
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected={selectedTab === 'Weekly'}
                                    onClick={() => handleTabChange('Weekly')}
                                  >
                                    Weekly
                                  </button>
                                </li>
                                <li
                                  className={`nav-item-financial ${selectedTab === 'Fortnightly' ? 'active' : ''}`}
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link inter"
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected={selectedTab === 'Fortnightly'}
                                    onClick={() => handleTabChange('Fortnightly')}
                                  >
                                    Fortnightly
                                  </button>
                                </li>
                                <li
                                  className={`nav-item-financial ${selectedTab === 'Monthly' ? 'active' : ''}`}
                                  role="presentation"
                                >
                                  <button
                                    className="nav-link inter"
                                    id="pills-contact-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-contact"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-contact"
                                    aria-selected={selectedTab === 'Monthly'}
                                    onClick={() => handleTabChange('Monthly')}
                                  >
                                    Monthly
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <div className="fw-bold inter">Start Date</div>
                          <input
                            type="date"
                            name="startDate"
                            value={goalData.startDate}
                            onChange={handleChange}
                            className="edit-goal-house w-75 py-2 inter add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <div className="fw-bold inter">End Date</div>
                          <input
                            type="date"
                            name="endDate"
                            value={goalData.endDate}
                            onChange={handleChange}
                            className="edit-goal-house w-75 py-2 inter add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        {/* <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <label htmlFor="accountSelect" className="fw-bold inter">
                            Select an Account:
                          </label>
                          <select
                            id="accountSelect"
                            name="account"
                            value={selectedAccount}
                            onChange={handleAccountChange}
                            className="edit-goal-house py-2 inter house-savings add-goal"
                            style={{
                              backgroundColor: '#efeded',
                              color: 'black',
                              fontSize: '15px',
                            }}
                          >
                            <option
                              value=""
                              style={{
                                color: 'black',
                              }}
                            >
                              Select an account
                            </option>
                            {accountData &&
                              accountData.map((account) => (
                                <option
                                  key={account.accountUuid}
                                  value={account.accountUuid}
                                  style={{
                                    color: 'black',
                                  }}
                                >
                                  {account.accountName}
                                </option>
                              ))}
                          </select>
                        </div> */}
                        {/* <p className="error-message">{selectedAccountError}</p> */}
                        <div className="edit-goal-box d-flex justify-content-between align-items-center">
                          <div className="fw-bold inter">Start Balance</div>
                          <input
                            type="text"
                            name="currentBalance"
                            value={goalData.currentBalance}
                            {...register('currentBalance', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            className="edit-goal-house w-50 py-2 inter add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center w-75">
                          <div className="fw-bold inter-font-size inter">Reference</div>
                          <input
                            type="text"
                            name="reference"
                            value={goalData.goalName.slice(0, 12)}
                            onChange={handleChange}
                            className="edit-goal-house w-50 py-2 inter add-goal"
                            style={{ border: 'none' }}
                            disabled
                          />
                        </div>
                        <div className="edit-goal-box d-flex justify-content-between align-items-center w-75">
                          <div className="fw-bold inter">Personalized color</div>
                          <div className="color-option" style={{ marginRight: '90px' }}>
                            <div className="dropdown">
                              <button
                                type="button"
                                className={`group-button ${colorCss(goalData.colour)} popoup-color`}
                                onClick={togglePopupColor}
                                aria-haspopup="true"
                                aria-expanded={isPopupOpenColor}
                              >
                                {goalData.colour ? goalData.colour : 'Dark Green'}
                              </button>

                              {isPopupOpenColor && (
                                <PopupColorModal
                                  isOpen={isPopupOpenColor}
                                  onClose={togglePopupColor}
                                  title="Date Range"
                                  options={[
                                    'Dark green',
                                    'Red',
                                    'Yellow',
                                    'Blue',
                                    'Purple',
                                    'Orange',
                                    'Pink',
                                    'Light blue',
                                    'Lime green',
                                    'Dark red',
                                    'Green',
                                    'Dark blue',
                                  ]}
                                  selectedOption={goalData.colorOption}
                                  handleOptionClick={handleOptionColorClick}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="edit-goal-box d-flex justify-content-between w-75">
                          <div className="fw-bold inter">Personalized emoji</div>
                          <input
                            type="text"
                            name="icon"
                            value={goalData.icon}
                            onChange={handleChange}
                            className="edit-goal-house w-50 fs-4 add-goal"
                            style={{ border: 'none' }}
                          />
                        </div>
                        <div className="profile-dashboard mt-3 ms-0">
                          <button type="submit" className="comn-btn inter add-goals-btn">
                            Add Goal
                            <i className="fa-solid fa-plus plus-icon-style"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="add-goal-paragraph">
                    <div className="fw-bold inter" style={{ marginRight: '2px' }}>
                      Tip:
                    </div>
                    <div className=" inter">
                      Create separate bank accounts for each goal to keep on track of your progress.
                    </div>
                    <br />
                  </div>
                  <div
                    className=" inter"
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    Or if you have one account for multiple goals, use the reference provided above in your transfers
                    and we’ll keep track of your progress for you.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewFinancial;
