import React, { useState } from 'react';
import { ReactComponent as AkahuIcon } from '../../assests/images/icons/akahu-icon.svg';
import { ReactComponent as ImportIcon } from '../../assests/images/icons/import-icon.svg';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import { ReactComponent as NewUsers } from '../../assests/images/icons/new-users.svg';
import { ReactComponent as NoOfUser } from '../../assests/images/icons/number-of-users.svg';
import { ReactComponent as Revenue } from '../../assests/images/icons/revenue.svg';
import { ReactComponent as UsersGroups } from '../../assests/images/icons/users-groups.svg';
import { Linecart } from '../../Components/Innerpanel/Linecart';
import SmallCart from '../../Components/Innerpanel/Smallcart/Smallcart';
import GenderCart from '../../Components/Innerpanel/Smallcart/GenderCart';
import './superAdmin.css';
import SuperAdminHeader from './SuperAdminHeader';
import SuperAdminSidebar from './SuperAdminSidebar';

const SuperAdminDashboard = () => {
  const [usersByLocations] = useState([
    {
      id: 1,
      name: 'Auckland',
      value: 356,
    },
    {
      id: 2,
      name: 'New Plymouth',
      value: 256,
    },
    {
      id: 3,
      name: 'Lower hutt',
      value: 234,
    },
    {
      id: 4,
      name: 'Queenstown',
      value: 224,
    },
    {
      id: 5,
      name: 'Palmerston North',
      value: 197,
    },
    {
      id: 6,
      name: 'Dunedin',
      value: 186,
    },
    {
      id: 7,
      name: 'Nelson',
      value: 157,
    },
    {
      id: 8,
      name: 'Christchurch',
      value: 132,
    },
    {
      id: 9,
      name: 'Gisborne',
      value: 122,
    },
    {
      id: 10,
      name: 'Taupo',
      value: 115,
    },
  ]);
  return (
    <>
      <div className="container-fluid">
        <SuperAdminHeader />
        <div className="appmain">
          <SuperAdminSidebar />
          <div className="app-main__outer main-front-dashboard-container">
            <div className="fs-3 big-head-new">
              Good Morning,{' '}
              <span className="text-jeremy">
                Admin
                <div className="hand-img">👋</div>
              </span>
            </div>
            <div className="fst-normal light-grey">Here's your dashboard of super admin using BudgetBuddie.</div>
            <div className="row mt-3 dashboard-wrp">
              <div className="col-md-12 col-xl-3">
                <div className="total-clients">
                  <div className="d-flex justify-content-end gap-2 mb-3">
                    <div className="select-profile-days-wrp">
                      <Edit />
                    </div>
                  </div>
                  <div className="margin-amount">
                    <div className="profile-dashboard-image">
                      <UsersGroups />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="user-wrp">
                      <div className="setting_text_color mb-2">Get a report of their cashflow analysis.</div>
                      <div className="profile-count-number acc-num-head">146</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-3">
                <div className="total-clients">
                  <div className="d-flex justify-content-end gap-2 mb-3">
                    <div className="select-profile-days-wrp">
                      <Edit />
                    </div>
                    <div className="select-profile-days-wrp">
                      <select>
                        <option>7 Days</option>
                        <option>6 Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="margin-amount">
                    <div className="profile-dashboard-image">
                      <NoOfUser />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="user-wrp">
                      <div className="setting_text_color mb-2">Number of users over time period</div>
                      <div className="profile-count-number acc-num-head">1598</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-3">
                <div className="total-clients">
                  <div className="d-flex justify-content-end gap-2 mb-3">
                    <div className="select-profile-days-wrp">
                      <Edit />
                    </div>
                    <div className="select-profile-days-wrp">
                      <select>
                        <option>7 Days</option>
                        <option>6 Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="margin-amount">
                    <div className="profile-dashboard-image">
                      <NewUsers />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="user-wrp">
                      <div className="setting_text_color mb-2">New users</div>
                      <div className="profile-count-number acc-num-head">+456</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-3">
                <div className="total-clients">
                  <div className="d-flex justify-content-end gap-2 mb-3">
                    <div className="select-profile-days-wrp">
                      <Edit />
                    </div>
                    <div className="select-profile-days-wrp">
                      <select>
                        <option>7 Days</option>
                        <option>6 Days</option>
                      </select>
                    </div>
                  </div>
                  <div className="margin-amount">
                    <div className="profile-dashboard-image">
                      <Revenue />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="user-wrp">
                      <div className="setting_text_color mb-2">Revenue</div>
                      <div className="profile-count-number acc-num-head">$2,000</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 dashboard-wrp">
              <div className="col-md-12 col-xl-6">
                <div className="right-top-blck new-top-blck">
                  <div className="new-blck">
                    <div className="content">
                      <h3 className="title">Users by age group</h3>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mb-3">
                      <div className="select-profile-days-wrp">
                        <Edit />
                      </div>
                      <div className="select-profile-days-wrp">
                        <select>
                          <option>12 months</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="pie-blck">
                    <SmallCart />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-6">
                <div className="total-clients">
                  <div className="d-flex justify-content-between gap-2 mb-3">
                    <div className="fw-bold heading-main">Top 10 users by location</div>
                    <div className="select-profile-days-wrp">
                      <select>
                        <option>7 Days</option>
                        <option>6 Days</option>
                      </select>
                    </div>
                  </div>
                  <table className="user-location-table">
                    {usersByLocations.map((item, index) => {
                      return (
                        <tr>
                          <th>{item.id}.</th>
                          <td>{item.name}</td>
                          <th>{item.value}</th>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
            <div className="row mt-3 dashboard-wrp">
              <div className="col-md-12 bar-cart col-xl-12 col-sm-12 col-lg-12 net-worth-progress-side">
                <div className="line-cart-net">
                  <div className="bar-cart-heading-wrp col-lg-12 col-md-12">
                    <div className="top-heading-canvas-price">
                      <h4 className="bold black">New users per month</h4>
                      <div className="d-flex justify-content-end gap-2 mb-3">
                        <div className="select-profile-days-wrp">
                          <Edit />
                        </div>
                        <div className="select-profile-days-wrp">
                          <select>
                            <option>2022</option>
                            <option>2023</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="linecartbottom">
                    <Linecart netWorth={[10, 30, 40, 60, 30, 60, 30, 50, 100, 40, 50, 60]} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 dashboard-wrp">
              <div className="col-md-12 col-xl-6">
                <div className="col-md-12 col-xl-12 mt-3">
                  <div className="total-clients">
                    <div className="d-flex justify-content-end gap-2 mb-3">
                      <div className="select-profile-days-wrp">
                        <Edit />
                      </div>
                      <div className="select-profile-days-wrp">
                        <select>
                          <option>7 Days</option>
                          <option>6 Days</option>
                        </select>
                      </div>
                    </div>
                    <div className="margin-amount">
                      <div className="profile-dashboard-image">
                        <AkahuIcon />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="user-wrp">
                        <div className="setting_text_color mb-2">Number of users using Akahu for their data</div>
                        <div className="profile-count-number acc-num-head">1598</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-12 mt-3">
                  <div className="total-clients">
                    <div className="d-flex justify-content-end gap-2 mb-3">
                      <div className="select-profile-days-wrp">
                        <Edit />
                      </div>
                      <div className="select-profile-days-wrp">
                        <select>
                          <option>7 Days</option>
                          <option>6 Days</option>
                        </select>
                      </div>
                    </div>
                    <div className="margin-amount">
                      <div className="profile-dashboard-image">
                        <ImportIcon />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="user-wrp">
                        <div className="setting_text_color mb-2">Number of users using import for their data</div>
                        <div className="profile-count-number acc-num-head">120</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-6">
                <div className="right-top-blck new-top-blck">
                  <div className="new-blck">
                    <div className="content">
                      <h3 className="title">Users by gender</h3>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mb-3">
                      <div className="select-profile-days-wrp">
                        <Edit />
                      </div>
                      <div className="select-profile-days-wrp">
                        <select>
                          <option>12 months</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="pie-blck">
                    <GenderCart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminDashboard;
