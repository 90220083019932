import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../../assests/images/icons/upload.svg';
import weburls from '../../Weburls/weburls';
import '../AddIncome/addIncome.css';

const schema = yup.object().shape({
  assetsName: yup.string().required('*Please enter assets name'),
  value: yup
    .string()
    .required('*Please enter asset value')
    .matches(/^[0-9]+$/, '*Please enter number only'),

  // amount: yup
  //   .string()
  //   .required('*Please enter asset amount')
  //   .matches(/^[0-9]+$/, '*Please enter number only'),
});

const AddAssets = ({ modalOpen, setModalOpen, fetchAssets, uuid }) => {
  const [assetData, setAssetData] = useState({
    userUuid: '',
    assetsName: '',
    value: '',
    amount: '',
    assetImage: '',
    assetAvatar: '',
  });
  const [assetAvatar, setAssetAvatar] = useState();

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssetData({ ...assetData, userUuid: uuid, [name]: value });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = async () => {
    try {
      const formData = new FormData();
      formData.append('userUuid', assetData.userUuid);
      formData.append('assetsName', assetData.assetsName);
      formData.append('value', assetData.value);
      formData.append('amount', assetData.amount);
      formData.append('assetAvatar', assetAvatar);
      const response = await axios.post(weburls.Add_Assets, formData);
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 2000,
        });
        setModalOpen(false);
        fetchAssets();
      } else if (response.status === 404) {
        toast.error(`Something went wrong`, {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      setModalOpen(false);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }

    reset();
  };

  const upload = (file) => {
    setAssetAvatar(file.target.files[0]);
  };
  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content">
        <button onClick={handleClose} className="close-modal-button">
          <i class="fa fa-times"></i>
        </button>
        <div className="setting_background_color">
          <div className="d-flex justify-content-between">
            <div className="fs-3">
              <span className="bold black big-top-heading">Add your assets</span>
            </div>
          </div>
          <hr />
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="row add_income-wrp">
              <div className="col-md-12 col-sm-12 col-xl-4 mb-3">
                <h5 className="bold black heading-new">Assets details</h5>
                <div className="setting_text_color popup-text">This will be displayed on your profile.</div>
              </div>
              <div className="col-md-12 col-sm-12 col-xl-8">
                <div className="row">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Name :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="assetsName"
                        class="form-control input_border"
                        placeholder="Assets name"
                        onChange={handleChange}
                        {...register('assetsName', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.assetsName?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Value :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="value"
                        class="form-control input_border"
                        placeholder="Asset value"
                        onChange={handleChange}
                        {...register('value', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.value?.message}</p>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Asset Amount :
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        name="amount"
                        class="form-control input_border "
                        placeholder="Do you owe any money on this asset?"
                        onChange={handleChange}
                        {...register('amount', {
                          onChange: (e) => {
                            handleChange(e);
                          },
                        })}
                      />
                    </div>
                    <p className="error-message">{errors.amount?.message}</p>
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label htmlFor="event-start" className="mb-1 labels-text">
                      Assets Image :
                    </label>
                    <div class="input-group mb-3 date-picker-div">
                      <div class="input-group">
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                          Assets image
                          <input
                            type="file"
                            class="form-control input_border "
                            placeholder="Assets Image"
                            id="file-format"
                            name="file-input"
                            onChange={upload}
                          />
                          <div className="upload">
                            <Upload className="upload-logo" />
                          </div>
                        </label>
                      </div>
                    </div>
                    {/* <p className="error-message">{errors.lastPaymentDate?.message}</p> */}
                  </div>
                </div>
              </div>
              <div class="button modal-popup-btn-wrp">
                <button type="submit" class="comn-btn common-btn-style">
                  Save asset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAssets;
