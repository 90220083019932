import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Add } from '../../../assests/images/icons/Add_round.svg';
import Profile from '../../../assests/images/icons/image.png';
import Mortgage from '../../../assests/images/icons/Mortgage.png';
import Shape from '../../../assests/images/icons/shape.png';
import { accountTypeArray } from '../../../assests/mock/AccountType';
import MainSidebar from '../../MainSidebar';
import PopupColorModal from '../../Popup/PopupColorModel';
import PopupInfoModal from '../../Popup/PopupInfoModel';
import weburls from '../../Weburls/weburls';

const schema = yup.object().shape({
  accountName: yup.string().required('*Please enter account name'),
  value: yup.string().required('*Please enter value'),
});

const AccountBalances = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();
  const {
    register,
    // formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [cashAccountData, setCashAccountData] = useState({
    userUuid: '',
    accountName: '',
    value: '',
    avatar: '',
    accountType: '',
    accountNumber: '',
    personalisedColour: '',
  });

  const [apiData, setApiData] = useState([]);
  const [avatar, setAvatar] = useState();
  const [errors, setErrors] = useState({});
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);

  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCashAccountData({ ...cashAccountData, userUuid: uuid, [name]: value });
    const newErrors = { ...errors };
    if (name === 'accountName' && value.length < 3) {
      newErrors.name = 'Account Name must be at least 3 characters long';
    } else if (name === 'accountType' && !value) {
      newErrors.accountType = 'Please enter account type';
    } else if (name === 'value' && !value) {
      newErrors.accountBalance = 'Please enter account balance';
    } else {
      newErrors.name = '';
      newErrors.accountType = '';
      newErrors.accountBalance = '';
      delete newErrors[name];
    }
    setErrors(newErrors);
  };
  const colorCss = (color) => {
    // Define colorCss
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
      case 'Red':
        return 'red-color';
      case 'Yellow':
        return 'yellow-color';
      case 'Blue':
        return 'blue-color';
      case 'Purple':
        return 'purple-color-color';
      case 'Orange':
        return 'orange-color';
      case 'Pink':
        return 'pink-color';
      case 'Light blue':
        return 'light-blue-color';
      case 'Lime green':
        return 'lime-green-color';
      case 'Dark red':
        return 'dark-red-color';
      case 'Green':
        return 'green-color';
      case 'Dark blue':
        return 'dark-blue-color';
      default:
        return 'green';
    }
  };

  const handleOptionColorClick = (option) => {
    setCashAccountData({ ...cashAccountData, personalisedColour: option });
    setPopupOpenColor(false);
  };
  const fetchCashAccount = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
      if (response?.data) {
        setApiData(response?.data?.accounts);
        localStorage.setItem('accountUuid', JSON.stringify(response?.data?.accounts));
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);
  const clearFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';
    }
  };
  const submitHandler = async () => {
    try {
      const formData = new FormData();
      if (cashAccountData.accountName && cashAccountData.value && cashAccountData.accountType) {
        formData.append('accountName', cashAccountData.accountName);
        formData.append('avatar', avatar);
        formData.append('userUuid', uuid);
        formData.append('value', cashAccountData.value);
        formData.append('accountType', cashAccountData.accountType);
        formData.append('accountNumber', cashAccountData.accountNumber);
        formData.append('personalisedColour', cashAccountData.personalisedColour);
        const response = await axios.post(weburls.Add_Cash_Account, formData);
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 2000,
          });
          fetchCashAccount();
          setAvatar('');
          reset({ accountName: '', value: '', accountType: '', accountNumber: '', personalisedColour: '' });
          setCashAccountData({
            accountName: '',
            avatar: '',
            userUuid: '',
            value: '',
            accountType: '',
            accountNumber: '',
            personalisedColour: '',
          });
          clearFileInput();
        } else if (response.status === 404) {
          toast.error('Something went wrong please try again.', {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      } else {
        handleChange({ target: { name: 'accountName', value: cashAccountData.accountName } });
        handleChange({ target: { name: 'accountType', value: cashAccountData.accountType } });
        handleChange({ target: { name: 'value', value: cashAccountData.value } });
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    fetchCashAccount();
  }, [fetchCashAccount]);

  const upload = (file) => {
    setAvatar(file.target.files[0]);
  };
  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-account-balances-container-main">
              <ToastContainer position="top-right" autoClose={5000} closeOnClick />
              <div className="row top-nav">
                <div className="col-md-6 col-lg-4 top-heading-account">
                  <div className="fs-3 bold big-head-new">Your account balances</div>
                  <div className="fst-normal setting_text_color">See what your account balances are today.</div>
                </div>
                <div className="col-md-6 col-xl-4 col-lg-4 col-sm-12 add-new-account-wrappee">
                  <div className="edit-btn-col">
                    <Link to="#">
                      <div className="edit-btn" href-="#">
                        <div className="left">
                          <h4 className="bold">Add a new account</h4>
                          <p style={{ color: '#04baff' }}>Bring everything into one place.</p>
                        </div>

                        <div className="right">
                          <Link to="#">
                            <Add />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 right-section back-portal-account-balances">
                  <div className="setting_background_color right-section search-spacing">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Accounts 💵"
                        description={[
                          'View all your bank, Kiwisaver, and share portfolio account balances in one place.',
                          'Click the account widget to copy the account number.Add a new account by entering in the details on the right hand side.',
                          'If you’d like to connect a new account, go to the Connect Your Accounts screen',
                          'If you have cash laying around, create a cash account by tapping the button down at the bottom or entering in the details on the right as well.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-8 col-lg-4 col-md-6 acccount-balances-wrappper-left-side">
                  <div className="bold black account-bal-title">Account balances</div>
                  {apiData?.length > 0 ? (
                    apiData?.map((item) => (
                      <div className="merchant-box-parent mt-3">
                        <div
                          className="merchant-box "
                          style={{
                            boxShadow: ' 0px 5px 30px 0px #0000001A',
                          }}
                        >
                          <div className="d-flex">
                            <div className="your-account-balance-wrp-logo-img">
                              <img src={item.avatar === '' || item.avatar === null ? Mortgage : item.avatar} alt="" />
                            </div>
                            <div className="ms-3">
                              <div className="text-merchant fw-bold-600">{item.accountName}</div>
                              <div className="number-account inter"></div>
                            </div>
                          </div>
                          <div className="account-number-dark bold">{`$${item.value}`}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="merchant-box mt-3">No data found</div>
                  )}
                </div>

                <div className="col-lg-4 search-spacing your-account-bal-add-cash">
                  <div className="bold black account-bal-title">Add a cash account</div>
                  <div className="cash-account-box mt-3 shadow">
                    <div className="d-flex justify-content-between">
                      <div className="fw-bold">Name</div>
                      <div className="account-input">
                        <input
                          type="text"
                          onChange={handleChange}
                          {...register('accountName', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        />
                      </div>
                    </div>
                    {errors.name && (
                      <>
                        <br />
                        <p className="error-message">{errors.name}</p>
                      </>
                    )}
                    <div className="d-flex justify-content-between">
                      <div className="fw-bold">Account type</div>
                      <div className="account-input">
                        <select
                          class="form-select input_border mt-3"
                          name="accountType"
                          {...register('accountType', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        >
                          <option value="">Select Account Type</option>
                          {accountTypeArray &&
                            accountTypeArray.length > 0 &&
                            accountTypeArray.map((item) => <option value={item.id}>{item.title}</option>)}
                        </select>
                      </div>
                    </div>
                    {errors.accountType && (
                      <>
                        <br />
                        <p className="error-message">{errors.accountType}</p>
                      </>
                    )}
                    <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Account number</div>
                      <div className="account-input">
                        <input
                          type="text"
                          onChange={handleChange}
                          {...register('accountNumber', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Account balance</div>
                      <div className="account-input small-account">
                        <input
                          type="text"
                          onChange={handleChange}
                          {...register('value', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        />
                      </div>
                    </div>
                    {errors.accountBalance && (
                      <>
                        <br />
                        <p className="error-message">{errors.accountBalance}</p>
                      </>
                    )}
                    <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Image</div>
                      <div className="account-input">
                        <div className="upload">
                          <input type="file" onChange={upload} className="form-control input_border" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="fw-bold">Personalised colour</div>
                      {/* <div className="account-input">
                        <input
                          type="color"
                          onChange={handleChange}
                          {...register('personalisedColour', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        />
                      </div> */}
                      <div className="dropdown">
                        <button
                          type="button"
                          className={`group-button ${colorCss(cashAccountData.personalisedColour)} popoup-color`}
                          onClick={togglePopupColor}
                          aria-haspopup="true"
                          aria-expanded={isPopupOpenColor}
                        >
                          {cashAccountData.personalisedColour ? cashAccountData.personalisedColour : 'Dark Green'}
                        </button>

                        {isPopupOpenColor && (
                          <PopupColorModal
                            isOpen={isPopupOpenColor}
                            onClose={togglePopupColor}
                            title="Date Range"
                            options={[
                              'Dark green',
                              'Red',
                              'Yellow',
                              'Blue',
                              'Purple',
                              'Orange',
                              'Pink',
                              'Light blue',
                              'Lime green',
                              'Dark red',
                              'Green',
                              'Dark blue',
                            ]}
                            selectedOption={cashAccountData.personalisedColour}
                            handleOptionClick={handleOptionColorClick}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="add-account-button bold">
                    <button onClick={submitHandler}>Add Account</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccountBalances;
