//export const BudgetBuddie = 'http://localhost:5010/';
export const BudgetBuddie = 'https://account.budgetbuddie.com/';

// Advisor login api

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Signup: BudgetBuddie + 'api/clients/signup',
  Check_one_time_token: BudgetBuddie + 'api/clients/checkUserOneTimeToken/',
  Advisor_Login: BudgetBuddie + 'api/auth/login',
  Advisor_Details: BudgetBuddie + 'api/advisor/getAdvisorByUser/',
  Advisor_Details_Update: BudgetBuddie + 'api/advisor/updateAdvisor',
  Advisor_role_list: BudgetBuddie + 'api/role/getRoles',
  Advisor_list: BudgetBuddie + 'api/advisor/getAdvisorByCompany/',
  Add_advisor: BudgetBuddie + 'api/advisor/addAdvisor',
  updateAdvisorDashboard: BudgetBuddie + 'api/advisor/updateAdvisorDetail',
  //    super admin add company
  Super_admin_company_add: BudgetBuddie + 'api/super_admin/company',
  Super_admin_company_list: BudgetBuddie + 'api/super_admin/company',
  Super_admin_add_plan: BudgetBuddie + 'api/plan/addPlan',
  Super_admin_plans_list: BudgetBuddie + 'api/plan/list',
  Super_admin_edit_plans: BudgetBuddie + 'api/plan/getPlanById/',
  Super_admin_update_plans: BudgetBuddie + 'api/plan/updatePlan',
  Super_admin_delete_plans: BudgetBuddie + 'api/plan/deletePlan/',
  Super_admin_status_edit: BudgetBuddie + 'api/plan/updatePlanStatus',

  //Groups api
  Add_group: BudgetBuddie + 'api/groups/addGroup',
  Group_list: BudgetBuddie + 'api/groups/list',
  Get_group_details: BudgetBuddie + 'api/groups/getGroupById/',
  Update_group: BudgetBuddie + 'api/groups/updateGroup',
  Delete_group: BudgetBuddie + 'api/groups/delete/',
  Get_Group_With_Clients: BudgetBuddie + 'api/groups/getGroupWithClients',

  //Clients api

  Add_clients: BudgetBuddie + 'api/clients/addClient',
  Get_clients: BudgetBuddie + 'api/clients/getClientsByCompany/',
  Get_clients_cliendid: BudgetBuddie + 'api/clients/getClientsByUUId/',
  Update_clients: BudgetBuddie + 'api/clients/updateClient',
  Get_clients_by_uuid: BudgetBuddie + 'api/clients/getClientByUuid/',
  deleteClientById: BudgetBuddie + 'api/clients/deleteClients/',
  // Edit budget Page

  Add_income: BudgetBuddie + 'api/budgetSummary/incomes',
  Update_Income: BudgetBuddie + 'api/budgetSummary/updateIncome',
  Get_Income: BudgetBuddie + 'api/budgetSummary/incomes/',
  Get_Income_ById: BudgetBuddie + 'api/budgetSummary/incomes/getIncomeById/',
  Delete_income: BudgetBuddie + 'api/budgetSummary/incomes/',
  Get_Monthly_Income_Expense: BudgetBuddie + 'api/budgetSummary/getIncomeAndExpense/',
  Get_Income_Vs_Budget: BudgetBuddie + 'api/budgetSummary/getIncomeVsBudgetDetails/',
  Get_Income_BreakDown: BudgetBuddie + 'api/budgetSummary/getIncomeBreakDown/',

  Add_expense: BudgetBuddie + 'api/budgetSummary/addExpenses',
  Get_Expense: BudgetBuddie + 'api/budgetSummary/expenses/',
  Update_Expense: BudgetBuddie + 'api/budgetSummary/updateExpenses',
  Get_Expense_By_Id: BudgetBuddie + 'api/budgetSummary/expenses/getExpensById/',
  Get_Expense_And_Transfer_By_Id: BudgetBuddie + 'api/budgetSummary/getAllExpenseAndTransfer/',
  Delete_Expense: BudgetBuddie + 'api/budgetSummary/expenses/',
  Get_Spend_Vs_Budget: BudgetBuddie + 'api/budgetSummary/getSpendVsExpenseDetails/',
  Get_Expense_Break_Down: BudgetBuddie + 'api/budgetSummary/getExpenseBreakDown/',
  Get_Transaction_By_Expense_Id: BudgetBuddie + 'api/budgetSummary/getExpenseTransactions/',

  Account_Transfer_Add: BudgetBuddie + 'api/budgetSummary/addAccountTransfer',
  Update_Account_Transfer: BudgetBuddie + 'api/budgetSummary/updateAccountTransfer',
  Account_Transfer_Get_List: BudgetBuddie + 'api/budgetSummary/getAccountTransfer/',
  Get_Account_Transfer_By_Id: BudgetBuddie + 'api/budgetSummary/getAccountTransfer/getAccountTransferById/',
  Delete_Account_Transfer: BudgetBuddie + 'api/budgetSummary/deleteAccountTransfer/',

  How_Often: BudgetBuddie + 'api/howOften/getHowOften',

  // Assets

  Add_Assets: BudgetBuddie + 'api/networth/assets',
  Get_Assets: BudgetBuddie + 'api/networth/assets/',
  Get_Asset_By_Id: BudgetBuddie + 'api/networth/assets/getAssetById/',
  Update_Asset: BudgetBuddie + 'api/networth/updateAssets',
  Delete_Asset: BudgetBuddie + 'api/networth/assets/',
  Get_NetWorth_Progress: BudgetBuddie + 'api/networth/getNetWorthProgress/',

  // Liability

  Add_Liability: BudgetBuddie + 'api/networth/liability',
  Get_Liability: BudgetBuddie + 'api/networth/liability/',
  Get_Liability_By_Id: BudgetBuddie + 'api/networth/liability/getLiabilityById/',
  Update_Liability: BudgetBuddie + 'api/networth/updateLiability',
  Delete_Liability: BudgetBuddie + 'api/networth/liability/',

  // Cash Account

  Add_Cash_Account: BudgetBuddie + 'api/cashAccount/addCashAccount',
  Get_Cash_Account: BudgetBuddie + 'api/cashAccount/getCashAccount/',
  Delete_Cash_Account: BudgetBuddie + 'api/cashAccount/deleteCashAccount/',
  Update_Cash_Account: BudgetBuddie + 'api/cashAccount/updateCashAccount',

  // Akahu Accounts

  Get_Aku_Accounts: BudgetBuddie + 'api/auth/akahu/getAccounts/',

  // Goals

  Add_Goals: BudgetBuddie + 'api/goals/addGoals',
  Get_AllGoals: BudgetBuddie + 'api/goals/getAllGoals/',
  Delete_Goals: BudgetBuddie + 'api/goals/deleteGoals/',
  Update_Goals: BudgetBuddie + 'api/goals/updateGoals',
  Get_Goals_ById: BudgetBuddie + 'api/goals/getGoalById/',
  Get_Goals_ByBudget: BudgetBuddie + 'api/goals/getGoalByBudget/',

  Get_Merchats_By_ClientId: BudgetBuddie + 'api/merchants/getMerchantsByClientId/',
  Get_Category: BudgetBuddie + 'api/merchants/getCategory/',
  Get_Top_Merchants: BudgetBuddie + 'api/merchants/getTopFiveMerchants/',
  Get_Top_Expenses: BudgetBuddie + 'api/merchants/getTopFiveExpenses/',
  Get_Transaction_By_Merchants_Id: BudgetBuddie + 'api/merchants/getTransactionsUsingMerchantId/',

  Get_Transaction_By_ClientId: BudgetBuddie + 'api/transactions/getTransactionsByClientId/',
  Add_Transaction: BudgetBuddie + 'api/transactions/addTransactions',

  // change password
  Change_Password: BudgetBuddie + 'api/clients/changePassword',

  // Revoke Account
  Revoke_account: BudgetBuddie + 'api/clients/revokeAccount/',

  // akahu transaction
  Get_Akahu_Transaction: BudgetBuddie + 'api/auth/akahu/getTransactions/',

  // One Off Budget

  Add_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/createOneOffBudget/',
  Get_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/getOneOffBudget/',
  Update_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/updateOneOffBudget/',
  Delete_OneOffBudget: BudgetBuddie + 'api/oneOffBudget/deleteOneOffBudget/',
  Get_OneOffBudget_ById: BudgetBuddie + 'api/oneOffBudget/getOneOffBudgetByUuid/',

  // Forget password for all role
  Forget_Password: BudgetBuddie + 'api/clients/forgetPassword',
  Check_Reset_Password_Link: BudgetBuddie + 'api/clients/checkResetPassword',
  Set_New_Password: BudgetBuddie + 'api/clients/setNewPassword',
  Email_2FA_Verification: BudgetBuddie + 'api/clients/email2faVerification',
  Enabled_Email_2Fa_Verification: BudgetBuddie + 'api/clients/enabledEmail2faVerification',

  // Payment Plam
  Plan_payment_session: BudgetBuddie + 'api/plan/create-subscription-checkout',
  Cancel_current_plan: BudgetBuddie + 'api/plan/cancelCurrentPlan',
  Cancel_plan_feedback: BudgetBuddie + 'api/plan/cancelCurrentPlanFeedback',
  Update_plan_payment_session: BudgetBuddie + 'api/plan/update-subscription-checkout',
  Get_Subscription_id: BudgetBuddie + 'api/plan/getSubscriptionId',
  Get_Payment_Session_id: BudgetBuddie + 'api/plan/getPaymentSessionId',
  Payement_Success: BudgetBuddie + 'api/plan/payementSuccess',
  Payment_Detail_Success: BudgetBuddie + 'api/plan/updatePaymentDetailSuccess',
  Free_trail_plan: BudgetBuddie + 'api/plan/freeTrailPlan',
  Freemium_plan: BudgetBuddie + 'api/plan/freemiumPlan',
  Get_CurrentPlan: BudgetBuddie + 'api/plan/getPlanDetails',
  Get_Plan_Payment_Details: BudgetBuddie + 'api/plan/getPlanPaymentDetails',
  Update_Payment_Details: BudgetBuddie + 'api/plan/update-payment-detail',
  Free_Plan_Expire_Feedback: BudgetBuddie + 'api/plan/freePlanExpirefeedback',

  // Dashboard Api
  Dashboard_all_data: BudgetBuddie + 'api/dashboard/getAllDetails/',

  // Sign Up Email
  Send_email_otp: BudgetBuddie + 'api/developer-apis/clients/v1/user/signupEmailVerification',
  Check_email_otp: BudgetBuddie + 'api/developer-apis/clients/v1/user/checkSignUPEmailVerification',
};
