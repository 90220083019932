import axios from 'axios';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import appleIcon from '../../assests/images/App-Store-Black.png';
import googlePlayIcon from '../../assests/images/Google-Play-Black.png';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const AccountSetup = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail, navigate]);

  const fetchedData = useCallback(async () => {
    console.log(userEmail, 'userEmail');
    const body = {
      email: userEmail,
    };
    try {
      const response = await axios.post(weburls.Get_CurrentPlan, body);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  const amount = localStorage.getItem('amount');
  const handleUpdateChange = () => {
    navigate('/managePlan');
  };

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp" ref={formRef}>
                <p className="text-center welcomeText" style={{ fontSize: '25px' }}>
                  You’re good to go 😎
                </p>
                <p
                  className="text-center mb-3 tagtext"
                  style={{
                    maxWidth: '100%',
                    height: '36px',
                    marginLeft: '0px',
                  }}
                >
                  Download the app below and use your email & password to get started.
                </p>
                <form>
                  <div className="input-header-text-manage">First name</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box" style={{ height: '100%'}}>
                      <div className="input-text-box-margin">
                        <input
                          value={userName}
                          name="username"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                            paddingTop: '3px',
                            paddingBottom: '3px'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-header-text-manage">Email</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box" style={{ height: '100%'}}>
                      <div className="input-text-box-margin">
                        <input
                          value={userEmail}
                          name="username"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                            paddingTop: '3px',
                            paddingBottom: '3px'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <p className="plan-selected-text plan-text-sub">Download below</p>
                    <Link to="/managePlan">
                      <p className="plan-selected-manage-text manage-btn">Manage account</p>
                    </Link>
                  </div>
                  <div style={{ width: '100%', marginTop: '-10px', paddingLeft: '0px', paddingRight: '0px'}}>
                    <div className="col-lg-12 account-assets-column mb-4 box-radis-25">
                      <div className="store-image d-flex flex-row align-items-center justify-content-center mt-3 gap-3">
                        <a href="https://apps.apple.com/nz/app/budgetbuddie/id6452397222" target="_blank" className="">
                          <img src={appleIcon} className="app-logo-icon" />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.budgetbuddie.app"
                          target="_blank"
                          className=""
                          style={{ textDecoration: 'none' }}
                        >
                          <img src={googlePlayIcon} className="app-logo-icon" />
                                                    {/* <GooglePlayBlack /> */}
                        </a>
                      </div>
                                            {/* {data?.amount === 0 && (
                        <div className="plan-box-mobile-view-free-account">
                          <p className="select-plans-div-text-mobile">14 day free trial</p>
                          <p className="select-plans-text-mobile">
                            Get access to all features of our Pro plan for 14 days for free including, dashboard,
                            budget, cashflow, goals, net worth, retirement planner, and unlimited connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#8914FF',
                              }}
                              onClick={handleUpdateChange}
                            >
                              Update Plan
                            </button>
                          </div>
                        </div>
                      )}

                      {(data?.amount === 103.5 || data?.amount === 10.35) && (
                        <div className="plan-box-mobile-view-standard-account">
                          <p className="select-plans-div-text-mobile">
                            Standard - {amount == 10.35 ? '$10.35' : '$103.50'}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount == 10.35 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Our most popular plan with the essentials. Includes access to most features such as
                            dashboard, budget, cashflow, 4 goals, and 5 connections.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#145BFF',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                              onClick={handleUpdateChange}
                            >
                              Update Plan
                            </button>
                          </div>
                        </div>
                      )}

                      {(data?.amount === 138 || data?.amount === 13.8) && (
                        <div className="plan-box-mobile-view-pro-account">
                          <p className="select-plans-div-text-mobile">
                            Pro - {amount == 13.8 ? '$13.80' : '$138'}
                            <span className="select-plans-div-sub-text-mobile">
                              /{amount == 13.8 ? 'Month' : 'year'}
                            </span>
                          </p>
                          <p className="select-plans-text-mobile">
                            Ideal for families and finance fanatics, full access to all features including, dashboard,
                            budget, cashflow, goals, net worth and unlimited connections.{' '}
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#56D77A',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                              onClick={handleUpdateChange}
                            >
                              Update Plan
                            </button>
                          </div>
                        </div>
                      )}
                      {data?.amount === null && (
                        <div className="plan-box-mobile-view-no-plan-account">
                          <p className="select-plans-div-text-mobile">No plan selected</p>
                          <p className="select-plans-text-mobile">
                            You have no plan currently selected. Upgrade you plan to get started and unlock your
                            financial future.
                          </p>
                          <div className="select-button-box">
                            <button
                              className="select-plan-button-green"
                              style={{
                                color: '#D75656',
                                backgroundColor: 'rgba(254, 254, 254)',
                              }}
                              onClick={handleUpdateChange}
                            >
                              {'Update Plan'}
                            </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </form>
              </div>
                            {/*
              <div className="social-logos">
                <img
                  src={AppStore}
                  alt=""
                  className="store-logos-img"
                  style={{
                    width: '180px',
                    height: '66px',
                  }}
                />
                <img
                  src={PlayStore}
                  className="store-logos-img"
                  alt=""
                  style={{
                    width: '180px',
                    height: '66px',
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountSetup;

