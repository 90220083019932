import React from "react";
import { Bar } from "react-chartjs-2";

const GenderCart = () => {
  return (
    <>
      <Bar
        data={{
          labels: ["MALE", "FEMALE"],
          datasets: [
            {
              label: "",
              data: [500, 400],
              backgroundColor: [
                "#025ADD",
                "#FF4F79"
              ],
              borderRadius: 13,
            },
          ],
        }}
        // Height of graph
        height={105}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
            },

            y: {
              grid: {
                display: false,
                drawBorder: false, // <-- this removes y-axis line
                lineWidth: 0,
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
              },
            },
          },
        }}
      />
    </>
  );
};
export default GenderCart;
