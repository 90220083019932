import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Airnewzealand from '../../assests/images/icons/AirNewZealand.png';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Tableimg from '../../assests/images/icons/image.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Header from '../Header/Header';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Activebutton from '../Buttons/Activebutton';
import Inactivebutton from '../Buttons/Inactivebutton';
import Notactivatedbutton from '../Buttons/Notactivatedbutton';

const DetailsPage = () => {
  const [active, setActive] = useState(false);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const editHandleChange = () => {
    setEdit(true);
  };

  const activeChange = () => {
    setActive(true);
  };
  const clientPage = () => {
    navigate('/Clients/Clientdetails');
  };

  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer group-summary-page-main">
            <div className="row">
              <div className="col-lg-8 col-md-12 mt-3">
                <div className="profile-dashboard client-summary-page-main ">
                  <div className="profile-dashboard">
                    <img src={Airnewzealand} alt="" />
                  </div>

                  <div className="profile-dashboard">
                    <h4 className="bold black client-name">Air New Zealand</h4>
                  </div>
                  <div className="profile-dashboard">
                    <button className="active-btn">
                      <i class="fa-solid fa-circle"></i> Active
                    </button>
                  </div>
                  <div className="profile-dashboard">
                    <a href="#">
                      <Link to="/Clients/Edit">
                        <Edit />
                      </Link>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4 mt-3 search-wrapper">
                <div className="search-block">
                  <form>
                    <Search />
                    <input type="text" className="search" placeholder="Search for a client here" />
                  </form>
                </div>
              </div>
            </div>
            <div className="row mt-4 client-group-detail-page">
              <div className="col-md-12 col-xl-3 mb-3 details-wrapper-summary">
                <div className="total-clients">
                  <div className="d-flex justify-content-between">
                    <div className="air-new  bold">Amount spent</div>
                    <div className="slecet-wrp selecet-box d-flex">
                      <select>
                        <option>This year</option>
                      </select>
                    </div>
                  </div>
                  <div className="margin-nu d-flex">
                    <h3 className="number-heading">$5,000</h3>
                  </div>
                  <div className="text-client">This years spend using BudgetBuddie</div>
                </div>
              </div>
              <div className="col-md-12 col-xl-4 mb-3 details-wrapper-summary">
                <div className="total-clients">
                  <div className="bold amt-spent air-new">Amount of users</div>
                  <div className="margin-amount">
                    <h2 className="number-heading">317</h2>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="user-wrp">
                      <div className="amount-number">300</div>

                      <Activebutton />
                    </div>
                    <div className="user-wrp">
                      <div className="amount-number">12</div>

                      <Inactivebutton />
                    </div>
                    <div className="user-wrp">
                      <div className="amount-number">5</div>
                      <Notactivatedbutton />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-3 mb-3 details-wrapper-summary">
                <div className="total-clients">
                  <div className="bold amt-spent air-new">Plan details</div>
                  <div className="pro-plan mt-4">
                    <div className="air-new big-new-heading">Pro plan 💸</div>
                  </div>
                  <div className="retirement-planner-text light-black">Annual </div>
                  <div className="d-flex">
                    <div className="fw-bold mb-1 fs-5">Renews</div>
                    <div className="plan-date mb-1">29/10/2023</div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-2 mb-3 details-wrapper-summary">
                <div className="total-clients">
                  <div className="bold air-new  amt-spent text-center">Share requests</div>
                  <div className="share-number number-share-10">10</div>
                  <button className="comn-btn">View requests</button>
                </div>
              </div>
            </div>
            <div className="bold mt-4 mb-2 upload-file-here">Filter Clients</div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3">User</div>
                  <div className="group-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button purple">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 1
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 2
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 3
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 4
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 5
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 6
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 7
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 8
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 9
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 10
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 11
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        User 12
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3">Bill Frequency</div>
                  <div className="group-btn yellow-btn">
                    <div className="button-blck">
                      <a
                        href="#"
                        className="group-button 
                      dark-yellow"
                      >
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Monthly
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Annual
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="group-backgroud-color">
                  <div className="bold black ms-3">Status</div>
                  <div className="group-btn green-btn">
                    <div className="button-blck">
                      <a href="#" className="group-button green">
                        All
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Active
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Inactive
                      </a>
                    </div>
                    <div className="button-blck">
                      <a href="#" className="group-button">
                        Not-Activated
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrp mt-4">
              <div class="row">
                <div class="col-12 group-details-page group-summary-page-table">
                  <table class="table-image">
                    <thead className="purple">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Group</th>
                        <th scope="col">Plan</th>
                        <th scope="col">Frequency</th>
                        <th scope="col">Status</th>
                        <th scope="col">Email</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="space-table">
                      <tr onClick={clientPage}>
                        <th>
                          <div className="air-image">
                            <img src={Tableimg} alt="table-img" />
                            <h3 className="table-content display">Tim Preston</h3>
                            <button className="amount-active">
                              Open <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </th>
                        <td className="table-text-color">Individual</td>
                        <td className="table-text-color">Pro</td>
                        <td className="table-text-color">Monthly</td>
                        <td>
                          <div className="active-status">
                            <button className="active-btn table-text-color" onClick={activeChange}>
                              <i class="fa-solid fa-circle"></i> Active
                            </button>
                          </div>
                        </td>
                        <td className="table-text-color">
                          tim@budgetbuddie.co.nz
                        </td>
                        <td>
                        <a href="#">
                            <Edit onClick={editHandleChange} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="air-image">
                            <img src={Tableimg} alt="table-img" />
                            <h3 className="table-content display">Tim Preston</h3>
                            <button className="amount-active">
                              Open <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </th>
                        <td className="table-text-color">Individual</td>
                        <td className="table-text-color">Pro</td>
                        <td className="table-text-color">Monthly</td>
                        <td>
                          <div className="active-status">
                            <button className="active-btn table-text-color" onClick={activeChange}>
                              <i class="fa-solid fa-circle"></i> Active
                            </button>
                          </div>
                        </td>
                        <td className="table-text-color">
                          tim@budgetbuddie.co.nz
                        </td>
                        <td>
                        <a href="#">
                            <Edit onClick={editHandleChange} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="air-image">
                            <img src={Tableimg} alt="table-img" />
                            <h3 className="table-content display">Tim Preston</h3>
                            <button className="amount-active">
                              Open <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </th>
                        <td className="table-text-color">Individual</td>
                        <td className="table-text-color">Pro</td>
                        <td className="table-text-color">Monthly</td>
                        <td>
                          <div className="active-status">
                            <button className="active-btn table-text-color" onClick={activeChange}>
                              <i class="fa-solid fa-circle"></i> Active
                            </button>
                          </div>
                        </td>
                        <td className="table-text-color">
                          tim@budgetbuddie.co.nz
                        </td>
                        <td>
                        <a href="#">
                            <Edit onClick={editHandleChange} />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="air-image">
                            <img src={Tableimg} alt="table-img" />
                            <h3 className="table-content display">Tim Preston</h3>
                            <button className="amount-active">
                              Open <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </th>
                        <td className="table-text-color">Individual</td>
                        <td className="table-text-color">Pro</td>
                        <td className="table-text-color">Monthly</td>
                        <td>
                          <div className="active-status">
                            <button className="active-btn table-text-color" onClick={activeChange}>
                              <i class="fa-solid fa-circle"></i> Active
                            </button>
                          </div>
                        </td>
                        <td className="table-text-color">
                          tim@budgetbuddie.co.nz
                        </td>
                        <td>
                        <td>
                        <a href="#">
                            <Edit onClick={editHandleChange} />
                          </a>
                        </td>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <div className="air-image">
                            <img src={Tableimg} alt="table-img" />
                            <h3 className="table-content display">Tim Preston</h3>
                            <button className="amount-active">
                              Open <i class="fa-solid fa-arrow-right"></i>
                            </button>
                          </div>
                        </th>
                        <td className="table-text-color">Individual</td>
                        <td className="table-text-color">Pro</td>
                        <td className="table-text-color">Monthly</td>
                        <td>
                          <div className="active-status">
                            <button className="active-btn table-text-color" onClick={activeChange}>
                              <i class="fa-solid fa-circle"></i> Active
                            </button>
                          </div>
                        </td>
                        <td className="table-text-color">
                          tim@budgetbuddie.co.nz
                        </td>
                        <td>
                        <td>
                        <a href="#">
                            <Edit onClick={editHandleChange} />
                          </a>
                        </td>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailsPage;
