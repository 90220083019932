import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as yup from 'yup';
import { ReactComponent as Upload } from '../../assests/images/icons/upload.svg';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  firstName: yup.string().required('Please Enter first name').min(3, 'First name must be consist min 3 character'),
  lastName: yup.string().required('Please Enter last name').min(3, 'Last name must be consist min 3 character'),
  phoneNumber: yup
    .string()
    .required('Phone Number is not valid')
    .min(7, 'Phone Number must be at least 7 digits')
    .max(10, 'Phone Number must be at most 10 digits'), // Adjust the maximum length as needed
  email: yup
    .string()
    .required('Please Enter email')
    .email('Please Enter a valid email')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please Enter proper email'),
  groupName: yup.string().required('Please Enter group name').min(3, 'Group Name must be consist min 3 character.'),
  groupWebsite: yup.string().required('Please Enter group website.'),
  advisorId: yup.string().required('Please Select Assigned Advisors.'),
  countryCode: yup.string().required('Please Select Country Code.'),
});
const Newgroup = ({ show }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const advisorsId = JSON.parse(localStorage.getItem('userData'));
  const companyId = JSON.parse(localStorage.getItem('userData'));
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    planDescription: '',
    groupWebsite: '',
    groupName: '',
    advisorId: '',
    countryCode: '+91',
    planName: '',
    planMode: 'monthly',
    status: 1,
    numberOfPlan: '',
  });
  const [advisor, setAdvisor] = useState();
  const [advisorList, setAdvisorList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [groupLogo, setGroupLogo] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const saveGroup = async (data) => {
    try {
      const formData = new FormData();
      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('phoneNumber', data.phoneNumber);
      formData.append('email', data.email);
      formData.append('groupWebsite', data.groupWebsite);
      formData.append('groupName', data.groupName);
      formData.append('advisorId', data.advisorId);
      formData.append('countryCode', data.countryCode);
      formData.append('status', Number(data.status));
      formData.append('groupLogo', groupLogo);
      formData.append('numberOfPlan', Number(data.numberOfPlan));
      formData.append('planName', data.planName);
      formData.append('planMode', data.planMode);
      formData.append('companyId', companyId.companyId);
      const response = await axios.post(weburls.Add_group, formData);
      setData(response);
      reset();
      if (response.status === 200) {
        toast.success('Group added successfully!', {
          position: 'top-right',
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              navigate('/Groups');
            }, 2000); // Adjust the time as needed
          },
        });
      } else if (response.status === 404) {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };
  const getAdvisorList = async () => {
    const response = await axios.get(weburls.Advisor_list + `${advisorsId.advisorId}`);
    setAdvisorList(response?.data?.advisors ?? []);
  };
  const getPlanlist = async () => {
    const response = await axios.get(weburls.Super_admin_plans_list);
    setPlanList(response.data ?? []);
  };
  const upload = (file) => {
    setGroupLogo(file.target.files[0]);
  };
  useEffect(() => {
    getAdvisorList();
    getPlanlist();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="appmain">
          <Sidebar />
          <div className="app-main__outer add-new-group-main-section">
            <ToastContainer
              position="top-right"
              autoClose={5000} // Auto close after 5 seconds
              closeOnClick
            />

            <div className="row">
              <div className="col-md-12 mb-3">
                <h3 className="small-heading">
                  <Link to="/Groups">
                    <i class="fa-solid fa-arrow-left"></i> Back to groups
                  </Link>
                </h3>
              </div>
            </div>
            <form onSubmit={handleSubmit(saveGroup)}>
              <div className="setting_background_color">
                <div className="d-flex justify-content-between">
                  <div className="fs-3 main-heading-new-group">
                    <h2 className="bold black big-top-heading">Add a new group</h2>
                    <div className="setting_text_color">
                      Enter in your group details, they'll get an email once you're finished.
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">
                      Main contact <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color mb-3">This is who to contact about their plan.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="First name"
                            {...register('firstName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="firstName"
                            value={data.firstName}
                          />
                        </div>
                        <p className="error-message">{errors.firstName?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border"
                            placeholder="Last name"
                            {...register('lastName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="lastName"
                            value={data.lastName}
                          />
                        </div>
                        <p className="error-message">{errors.lastName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-xl-6 col-md-12">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Email"
                            {...register('email', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="email"
                            value={data.email}
                          />
                        </div>
                        <p className="error-message">{errors.email?.message}</p>
                      </div>
                      <div className="col-xl-2 col-md-12">
                        <div class="input-group mb-3">
                          <select
                            class="form-select input_border mb-3"
                            name="countryCode"
                            {...register('countryCode', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            value={data.countryCode}
                          >
                            <option value={'+91'} selected>
                              +91
                            </option>
                            <option value={'+61'}>+61</option>
                            <option value={'+64'}>+64</option>
                          </select>
                        </div>
                        <p className="error-message">{errors.countryCode?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-4">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Phone number"
                            {...register('phoneNumber', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="phoneNumber"
                            value={data.phoneNumber}
                          />
                        </div>
                        <p className="error-message">{errors.phoneNumber?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">
                      Group details <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color mb-3">Enter in the details of the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row mt-2">
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Group website"
                            {...register('groupWebsite', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="groupWebsite"
                            value={data.groupWebsite}
                          />
                        </div>
                        <p className="error-message">{errors.groupWebsite?.message}</p>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <div class="input-group mb-3">
                          <input
                            type="text"
                            class="form-control input_border "
                            placeholder="Group name"
                            {...register('groupName', {
                              onChange: (e) => {
                                handleChange(e);
                              },
                            })}
                            name="groupName"
                            value={data.groupName}
                          />
                        </div>
                        <p className="error-message">{errors.groupName?.message}</p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div class="col-md-10 col-lg-10 input-upload">
                        <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                          Select a File
                          <input
                            type="file"
                            class="form-control input_border "
                            placeholder="Profile Image"
                            id="file-format"
                            name="file-input"
                            onChange={upload}
                          />
                          <div className="upload">
                            <Upload className="upload-logo" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">
                      Assigned advisors <span style={{ color: 'red' }}>*</span>
                    </h5>
                    <div className="setting_text_color mb-3">Enter in the assigned advisor to the group.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7">
                    <div className="row">
                      <div className="col-md-12 col-xl-6"></div>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="advisorId"
                          {...register('advisorId', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          value={data.advisorId}
                        >
                          <option value={''}>Assigned Advisors</option>
                          {advisorList && advisorList?.length > 0
                            ? advisorList.map((item) => (
                                <option key={item.advisorId} value={item.advisorId}>
                                  {`${item.firstName} ${item.lastName}`}
                                </option>
                              ))
                            : 'No data found'}
                        </select>
                        <p className="error-message">{errors.advisorId?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Plan details</h5>
                    <div className="setting_text_color">Enter in the type of plan the group is using.</div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 col-xl-12 mb-4">
                        <input
                          type="number"
                          class="form-control input_border"
                          name="numberOfPlan"
                          {...register('numberOfPlan', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          placeholder="Number of plans"
                        />
                      </div>
                      <p className="error-message">{errors.numberOfPlan?.message}</p>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="planName"
                          {...register('planName', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        >
                          <option selected value={''}>
                            Select a plan
                          </option>
                          {planList && planList.length > 0
                            ? [...new Set(planList.map((item) => item.planName))].map((planName) => (
                                <option key={planName} value={planName}>
                                  {planName}
                                </option>
                              ))
                            : 'No data found'}
                        </select>
                      </div>
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          {...register('planMode', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          name="planMode"
                        >
                          <option selected value={'monthly'}>
                            {' '}
                            Monthly
                          </option>
                          <option value={'yearly'}>Yearly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h5 className="bold black heading-new">Status</h5>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xl-7 mb-3">
                    <div className="row mt-3 plan-wrp">
                      <div className="col-md-12 col-xl-6">
                        <select
                          class="form-select input_border mb-3"
                          name="status"
                          {...register('status', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                        >
                          <option value={1} selected>
                            Active
                          </option>
                          <option value={2}>Inactive</option>
                          <option value={3}>Not-Activated</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-12 mt-4">
                  <h2 className="bold black big-top-heading mt-3">Add individual client details</h2>
                  <div className="setting_text_color">
                    Enter in your clients details, they’ll get an email once you’re finished.
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-wrp mt-3 add-new-group-table-wrp">
                  <div class="row">
                    <div class="col-12 table-horizontal">
                      <table class="table-image group-table">
                        <thead className="purple">
                          <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone number</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody className="space-table">
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th className="">
                              <div class="input-group">
                                <input type="text" className="form-control input_border comn-field" placeholder="" />
                              </div>
                            </th>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <input type="text" class="form-control input_border comn-field" placeholder="" />
                              </div>
                            </td>
                            <td className="text-center">
                              <div class="input-group">
                                <a href="#" class="black">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting_background_color mt-5">
                <div className="d-flex justify-content-between">
                  <div className="fs-3 fw-bolder">
                    <h2 className="bold black big-top-heading">Upload a list of clients</h2>
                    <div className="setting_text_color">
                      If you have a bunch of clients to add, upload a csv file of all their details and we'll handle it.
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="bold black heading-new">Upload your file here</h5>
                    <div className="setting_text_color">Upload your csv file.</div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <div class="input-group mb-3">
                            <label id="file-input-label" htmlFor="file-input" class="form-control input_border ">
                              Select a File
                              <input
                                type="file"
                                class="form-control input_border "
                                placeholder="Profile Image"
                                id="file-format"
                                name="file-input"
                              />
                              <div className="upload">
                                <Upload className="upload-logo" />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting_background_color mt-5">
                <div className="">
                  <div className="fs-3 fw-bolder">
                    <h2 className="bold black big-top-heading">Save your details</h2>
                    <div className="setting_text_color">
                      Once you've send all of your users, they'll get an email notification to use BudgetBuddie.
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-3">
                    <div className="col-lg-6 col-sm-12"></div>
                    <div className="col-lg-6 col-sm-12 save-your-detail-btns">
                      <div class="button">
                        <button type="button" class="comn-btn button_white save-grp-btn w-236">
                          Cancel
                        </button>
                        <button type="submit" class="comn-btn save-grp-btn w-236">
                          Save group<i class="fa-solid fa-plus plus-icon-style"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newgroup;
