import axios from 'axios';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Editnw } from '../../assests/images/icons/edit.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/main-plus.svg';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';
import GoalDetailsShow from './GoalDetailShow';

export const colorCss = (color) => {
  switch (color) {
    case 'Dark green':
      return 'dark-green-color';
    case 'Red':
      return 'red-color';
    case 'Yellow':
      return 'yellow-color';
    case 'Blue':
      return 'blue-color';
    case 'Purple':
      return 'purple-color-color';
    case 'Orange':
      return 'orange-color';
    case 'Pink':
      return 'pink-color';
    case 'Light blue':
      return 'light-blue-color';
    case 'Lime green':
      return 'lime-green-color';
    case 'Dark red':
      return 'dark-red-color';
    case 'Green':
      return 'green-color';
    case 'Dark blue':
      return 'dark-blue-color';
    default:
      return 'green';
  }
};
export const progressbarColor = (color) => {
  switch (color) {
    case 'Dark green':
      return 'progress-dark-green-color';
    case 'Red':
      return 'progress-red-color';
    case 'Yellow':
      return 'progress-yellow-color';
    case 'Blue':
      return 'progress-blue-color';
    case 'Purple':
      return 'progress-purple-color-color';
    case 'Orange':
      return 'progress-orange-color';
    case 'Pink':
      return 'progress-pink-color';
    case 'Light blue':
      return 'progress-light-blue-color';
    case 'Lime green':
      return 'progress-lime-green-color';
    case 'Dark red':
      return 'progress-dark-red-color';
    case 'Green':
      return 'progress-green-color';
    case 'Dark blue':
      return 'progress-dark-blue-color';
    default:
      return 'progress-green';
  }
};

const GoalDetails = () => {
  const [active, setActive] = useState(false);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const { uuid, goalUuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const fetchGoalDetails = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Goals_ById}${goalUuid}`);
      if (response?.data) {
        setGoals(response?.data);
        setActive(true);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [goalUuid]);

  console.log('goalsgoals', goals);
  useEffect(() => {
    fetchGoalDetails();
  }, [fetchGoalDetails]);

  const handleEditGoal = (goal) => {
    setSelectedGoal(goal);
    setActive(true);
  };
  const handleDeleteGoal = async (goalUuid) => {
    try {
      await axios.delete(`${weburls.Delete_Goals}${uuid}/${goalUuid}`);
      fetchGoalDetails();
      toast.success('Goal successfully Deleted...', {
        position: 'top-right',
        autoClose: 1000,
      });
      setSelectedGoal(null);
      setActive(false);
      fetchGoalDetails();
    } catch (error) {
      toast.error('Goal details not update. Please try again.');
      console.error('error', error);
    }
  };

  const handleCancelEdit = () => {
    setSelectedGoal(null);
    setActive(false);
  };

  const handleSaveEdit = async (editedGoal) => {
    try {
      const response = await axios.put(weburls.Update_Goals, editedGoal);
      if (response) {
        toast.success('Goal successfully Updated...', {
          position: 'top-right',
          autoClose: 2000,
        });
        setSelectedGoal(null);
        setActive(false);
        fetchGoalDetails();
      } else if (response.status === 500) {
        toast.error('Goal details not update. Please try again.');
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer financial-goals-main">
              <div className="row new-finance">
                <div className="col-lg-4 col-sm-12 main-heading-financial">
                  <div className="fs-3 bold black big-head-new">Goal details</div>
                  <div className="fst-normal setting_text_color">
                    Let's set some goals, it’s always good having goals and something to aim for.
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 right-section back-to-portal-financial">
                  <div className="setting_background_color right-section">
                    <Link to={`/Financial/AddNewFinancial/${uuid}`}>
                      <div className="edit-btn-col">
                        <div
                          className="edit-btn"
                          href="#"
                          style={{ width: '327px', height: '60px', marginRight: '32px' }}
                        >
                          <div className="left">
                            <h4 className="bold">Add a new goal</h4>
                            <p style={{ color: '#04baff', textAlign: 'left' }}>Keep on track</p>
                          </div>
                          <div className="right">
                            <Edit />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Goals 🎯"
                        description={[
                          'Your goals are where you can set and track any financial milestones you’d like to achieve',
                          'This can be big or small. You can customise goals to exactly what you want.',
                          'Each goal widget predicts when you’re expected to achieve the goal and shows you how on track you are.Add a new goal by tapping ‘Add a new goal’ button.',
                          'You can also view and edit the details of each goal by tapping ‘See detail’.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8 financial-cards-wrp-div goal-grid-style">
                    <div className="row">
                      {/* {goals && goals.length > 0 ? (
                        goals.map((goals) => ( */}
                      <div className="col-lg-6 col-md-12 mt-4" key={goals?.goalUuid}>
                        <div className={`financial-holiday financial cursor-pointer ${colorCss(goals?.colour)}`}>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <div className="icon-holiday">{goals?.icon}</div>
                                <div className="ms-2 text-white category-text fw-bold inter">{goals?.goalName}</div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="financial-icon" style={{ marginRight: '15px' }}>
                                <Editnw onClick={() => handleEditGoal(goals)} />
                              </div>
                              {/* <div className="financial-icon">
                                <Delete onClick={() => handleDeleteGoal(goals?.goalUuid)} />
                              </div> */}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3 mb-2 align-items-center">
                            <div className="financial-text">Balance</div>
                            <div className="financial-text">{goals?.goalComplete}</div>
                          </div>
                          <div
                            className="progress red-progress"
                            style={{
                              height: '7px',
                            }}
                          >
                            <div
                              className={`progress-bar ${progressbarColor(goals?.colour)}`}
                              role="progressbar"
                              aria-valuenow={goals?.goalComplete}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{
                                width: goals?.goalComplete,
                                opacity: '2 !important',
                              }}
                            ></div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <div>
                              <span className="bold text-white">${goals?.currentBalance}</span>
                              <span className="financial-text">&nbsp; of &nbsp;${goals?.value}</span>
                              <div
                                className="mt-3"
                                style={{
                                  fontSize: '12px',
                                }}
                                onClick={() => setActive(!active)}
                              >
                                See detail
                              </div>
                            </div>
                            <div className="financial-text">
                              {goals?.endDate ? moment(goals?.endDate).format('Do MMMM YYYY') : ''}
                            </div>
                          </div>
                        </div>
                      </div>

                      {active && <GoalDetailsShow goal={goals} onCancel={handleCancelEdit} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalDetails;
