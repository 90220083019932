import axios from 'axios';
import moment from 'moment/moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Pagination from 'react-js-pagination';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Countdown from '../../assests/images/icons/Countdown.png';
import { ReactComponent as Filter } from '../../assests/images/icons/Filter.svg';
import Profile from '../../assests/images/icons/image.png';
import Refresh from '../../assests/images/icons/refresh.png';
import { ReactComponent as Search } from '../../assests/images/icons/Search.svg';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';

const Transactions = () => {
  const sidebarRef = useRef(null);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);

  const [selectedMerchantType, setSelectedMerchantType] = useState('All');
  const [selectedSpendRange, setSelectedSpendRange] = useState('All');
  const [selectedDateRange, setSelectedDateRange] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { uuid } = useParams();
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const fetchTransactions = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Transaction_By_ClientId}${uuid}`);
      setData(response?.data);
    } catch (error) {
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Category}`);
      setCategory(response.data);
    } catch (err) {
      toast.error(`Something went wrong ${err}`, {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, []);

  const fetchAkahuTransactions = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Akahu_Transaction}${uuid}`);
      if (response?.data.length > 0) {
        toast.success('Fetch Transaction Successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
      } else {
        toast.error('Transactions up to date', {
          position: 'top-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error('Something went wrong, please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    } finally {
      await fetchTransactions();
    }
  }, [uuid, fetchTransactions]);

  useEffect(() => {
    fetchTransactions();
    getCategory();
  }, [fetchTransactions, getCategory]);

  function isSameQuarter(date1, date2) {
    return date1?.format('Q') === date2?.format('Q') && date1?.format('YYYY') === date2?.format('YYYY');
  }

  const filteredData =
    data?.length > 0 &&
    data?.filter((item) => {
      if (selectedMerchantType !== 'All' && item.TransactionCategory !== selectedMerchantType) {
        return false;
      }

      if (selectedSpendRange !== 'All') {
        const spend = parseFloat(item.amount);
        switch (selectedSpendRange) {
          case '$10-$50':
            if (spend < 10 || spend > 50) return false;
            break;
          case '$50-$100':
            if (spend < 50 || spend > 100) return false;
            break;
          case '$100-$300':
            if (spend < 100 || spend > 300) return false;
            break;
          case '$300-$600':
            if (spend < 300 || spend > 600) return false;
            break;
          case '$600+':
            if (spend < 600) return false;
            break;
          default:
            break;
        }
      }

      if (selectedDateRange !== 'All') {
        const today = moment();
        const itemDate = moment(item.dateTime);
        const diffInDays = today.diff(itemDate, 'days');
        switch (selectedDateRange) {
          case 'This week':
            if (diffInDays > 6) return false;
            break;
          case 'This month':
            if (today.month() !== itemDate.month()) return false;
            break;
          case 'This quarter':
            if (!isSameQuarter(today, itemDate)) return false;
            break;
          case 'This year':
            if (today.year() !== itemDate.year()) return false;
            break;
          case 'Last week':
            if (diffInDays < 7 || diffInDays > 13) return false;
            break;
          case 'Last month':
            if (today.month() === itemDate.month()) return false;
            break;
          default:
            break;
        }
      }

      return true;
    });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = filteredData && filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-transaction-main-container">
              <div className="row top-nav">
                <div className="col-md-6">
                  <div className="bold big-head-new">Your transactions</div>
                  <div className="fst-normal setting_text_color">Here’s all of your transactions from all accounts</div>
                </div>
                <div className="col-md-6 right-section your-trans-back-to-portal">
                  <div className="setting_background_color right-section search-spacing">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Transactions 💳"
                        description={[
                          'Your transactions are all shown here, including the category for each one.',
                          'To view more transactions, you can keep scrolling down. You can filter by clicking the different filter fields up the top. If you want to dive deeper and find specific transactions, you can use the search function by clicking the icon.',
                          'If you’d like to add a transaction that hasn’t been pulled through from your connected account, you can tap the + icon.',
                          'If you’d like to allocate a transaction to a different category, click the category button below the name and then select the category using the ‘Select category’ option field.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 search-spacing filter-transaction-wrapper">
                <div className="bold heading-sml filter-trans">Filter Transactions</div>
                <div className="col-md-3 sa">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Merchant type</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedMerchantType === 'All' ? 'green' : ''}`}
                          onClick={() => setSelectedMerchantType('All')}
                        >
                          All
                        </button>
                      </div>
                      {category?.length > 0 &&
                        category.map((it) => (
                          <div className="button-blck">
                            <button
                              className={`group-button ${selectedMerchantType === it.categorie ? 'green' : ''}`}
                              onClick={() => setSelectedMerchantType(it.categorie)}
                            >
                              {it.categorie}
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-3 search-spacing for-speed-range-section ">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Spend range</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === 'All' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('All')}
                        >
                          All
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === '$10-$50' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('$10-$50')}
                        >
                          $10-50
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === '$50-$100' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('$50-$100')}
                        >
                          $50-100
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === '$100-$300' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('$100-$300')}
                        >
                          $100-300
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === '$300-$600' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('$300-$600')}
                        >
                          $300-600
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedSpendRange === '$600+' ? 'green' : ''}`}
                          onClick={() => setSelectedSpendRange('$600+')}
                        >
                          $600+
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 search-spacing for-date-range-section ">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Date range</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'All' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('All')}
                        >
                          All
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'This week' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('This week')}
                        >
                          This week
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'This month' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('This month')}
                        >
                          This month
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'This quarter' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('This quarter')}
                        >
                          This quarter
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'This year' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('This year')}
                        >
                          This year
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'Last week' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('Last week')}
                        >
                          Last week
                        </button>
                      </div>
                      <div className="button-blck">
                        <button
                          className={`group-button ${selectedDateRange === 'Last month' ? 'green' : ''}`}
                          onClick={() => setSelectedDateRange('Last month')}
                        >
                          Last month
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 search-spacing for-date-range-section ">
                  <div className="group-backgroud-color mt-3">
                    <div className="bold black">Select Category</div>
                    <div className="group-btn green-btn mt-4">
                      <div className="button-blck">
                        <div className="icon-tag">
                          <div className="icon-transaction-img pink-img ">✈️</div>
                          <div className="content">
                            <h6 className="icon-content ">Travel</h6>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="icon-tag" style={{ width: '125px' }}>
                          <div className="icon-transaction-img insurance-img">💰</div>
                          <div className="content">
                            <h6 className="icon-content ">Insurance</h6>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="icon-tag">
                          <div className="icon-transaction-img plug-img">🔌</div>
                          <div className="content">
                            <h6 className="icon-content ">Utilities</h6>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="icon-tag" style={{ width: '125px' }}>
                          <div className="icon-transaction-img eating-img">🌯</div>
                          <div className="content">
                            <h6 className="icon-content ">Eating Out</h6>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="icon-tag" style={{ width: '125px' }}>
                          <div className="icon-transaction-img groceries-img">🛒</div>
                          <div className="content">
                            <h6 className="icon-content ">Groceries</h6>
                          </div>
                        </div>
                      </div>
                      <div className="button-blck">
                        <div className="icon-tag" style={{ width: '125px' }}>
                          <div className="icon-transaction-img shopping-img">🛍️</div>
                          <div className="content">
                            <h6 className="icon-content ">Shopping</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-12 transaction-side">
                  <div className="d-flex justify-content-between mb-3">
                    <div className="bold heading-sml filter-trans">Transactions</div>
                    <div className="d-flex">
                      <div>
                        <button
                          className="bold"
                          onClick={fetchAkahuTransactions}
                          style={{
                            padding: '4px 9px',
                            borderRadius: '8px',
                            border: 0,
                            backgroundColor: ' #469b88',
                            marginRight: '10px',
                          }}
                        >
                          <img src={Refresh} alt="refresh_logo" />
                        </button>
                      </div>
                      <div>
                        <Search className="trabs" />
                      </div>
                      <div className="">
                        <Link to={`/AddTransaction/${uuid}`} style={{ color: 'black' }}>
                          <i class="fa-solid fa-plus ms-4"></i>
                        </Link>
                      </div>
                      <div>
                        <Filter className="ms-4" />
                      </div>
                    </div>
                  </div>
                  <div className="table-horizontal">
                    {currentTransactions &&
                      currentTransactions?.length > 0 &&
                      currentTransactions.map((it) => (
                        <div className="transactions-table table-transaction" key={it.userUuid}>
                          <div className="d-flex">
                            <div className="countdown-transaction">
                              <img
                                src={it.merchantLogo === '' || it.merchantLogo === null ? Countdown : it.merchantLogo}
                                alt=""
                              />
                            </div>
                            <div className="ms-4">
                              <div className="transactions-text inter">{it.transactionName}</div>
                              <div className="select-transaction">
                                <select
                                  className="form-select"
                                  style={{ width: '320px', height: '33px', fontSize: '13px' }}
                                >
                                  <option selected>{it.TransactionCategory}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="transactions-text big-text-transaction inter">
                            {it.merchantName ?? it.accountName}
                          </div>
                          <div className="transactions-text big-text-transaction inter">
                            {moment(it.dateTime).format('DD/MM/YYYY')} &nbsp;&nbsp; | &nbsp;&nbsp;
                            {moment(it.dateTime).format('hh:mma')}
                          </div>
                          <div
                            className={`transactions-text inter ${
                              it.status.toLowerCase() === 'pending' ? 'pending' : 'done'
                            }`}
                          >
                            {it.status}
                          </div>
                          <div
                            className={`transactions-text inter ${
                              it.transactionCategory.toLowerCase() === 'dr' ? 'light-red-trans' : 'light-green-trans'
                            }`}
                          >
                            {it.transactionCategory.toLowerCase() === 'dr'
                              ? `-$${Math.abs(Number(it.amount)).toFixed(2)}`
                              : `+$${it.amount}`}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="mt-3">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={filteredData.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                      activeClass="active"
                      innerClass="pagination justify-content-center"
                      activeLinkClass="active-link"
                      prevPageText="Previous"
                      nextPageText="Next"
                      hideFirstLastPages={false}
                      hidePrevNextPages={false}
                      itemClassFirst="page-item"
                      itemClassLast="page-item"
                      linkClassFirst="page-link"
                      linkClassLast="page-link"
                      itemClassPrev="page-item"
                      itemClassNext="page-item"
                      linkClassPrev="page-link"
                      linkClassNext="page-link"
                      itemClassOut="page-item-disabled"
                      linkClassOut="page-link-disabled"
                      prevPageTitle="Go to previous page"
                      nextPageTitle="Go to next page"
                      getPageUrl={(pageNumber) => `#${pageNumber}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Transactions;
