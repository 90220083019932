import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Widgetpiechart = () => {
  const percentage = 12.5;

  return (
    <>
      <div style={{ width: '100%' }} className="pie-blck-circular">
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.1,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',

            // Text size
            textSize: '14px',

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.3,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `rgba(70, 155, 136, 1), ${percentage / 100})`,
            textColor: 'rgba(38, 50, 56, 1)',
            trailColor: 'rgba(70, 155, 136, 0.4)',
            backgroundColor: '',
          })}
        />
      </div>
    </>
  );
};
export default Widgetpiechart;
