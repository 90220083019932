import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import { getWithExpiry } from '../pages/checkAndSetLoginExpried';
import '../pages/Login.css';
import weburls from '../Weburls/weburls';

const schema = yup.object().shape({
  feedbackMessage: yup.string().required('Please enter your feedback'),
});

const FeedbackManage2 = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const userUuid = getWithExpiry('feedbackUserUuid');
  const message = getWithExpiry('feedbackMessage');
  const [formData, setFormData] = useState({
    feedbackMessage: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const logoRef = useRef(null);
  const formRef = useRef(null);

  const onSubmitHandler = async (formData) => {
    const body = {
      message: message,
      userUuid: userUuid,
      feedbackMessage: formData.feedbackMessage,
    };
    setLoading(true);
    try {
      await axios.post(weburls.Free_Plan_Expire_Feedback, body);
      toast.success('Thank You For Your Valuable Feedback');
      setTimeout(() => {
        window.location.href = 'https://www.budgetbuddie.com/';
      }, 1000);
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp mt-4" ref={formRef}>
                <p className="text-center welcomeText" style={{ fontSize: '28px', padding: '10px' }}>
                  Which features would you like to see?
                </p>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-outline">
                    <div className="feedback-form-input-box" style={{ height: '100%' }}>
                      <div className="input-text-box-feedback-margin">
                        <textarea
                          {...register('feedbackMessage', {
                            onChange: (e) => {
                              handleChange(e);
                            },
                          })}
                          value={formData.feedbackMessage}
                          name="feedbackMessage"
                          type="text"
                          id="form3Example3"
                          className="register-focus "
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            resize: 'none',
                            height: '170px',
                          }}
                          placeholder='Spill the beans here...'
                        />
                        {errors.name && <p className="t-1 error-message mt-1">{errors.name.message}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="text-center pt-2" style={{ marginTop: '20px' }}>
                    <button
                      type="submit"
                      className={`comn-btn ${loading ? 'feedback-btn-load' : 'feedback-btn'}`}
                      style={{ marginBottom: '20px' }}
                    >
                      {loading ? <ClipLoader size={15} color={'#ffffff'} /> : 'Submit feedback'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeedbackManage2;
