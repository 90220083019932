import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Backarrow } from '../../assests/images/icons/Arrow_rleft.svg';
import Profile from '../../assests/images/icons/image.png';
import { ReactComponent as Edit } from '../../assests/images/icons/more.svg';
import Arrowright from '../../assests/images/icons/white-right.svg';
import MainSidebar from '../MainSidebar';
import PopupColorModal from '../Popup/PopupColorModel';
import weburls from '../Weburls/weburls';

const EditGoalBudget = () => {
  const [active, setActive] = useState(false);
  const [isPopupOpenColor, setPopupOpenColor] = useState(false);
  const [goal, setGoal] = useState();
  const [selectedColorOption, setSelectedColorOption] = useState('');
  const togglePopupColor = () => setPopupOpenColor(!isPopupOpenColor);
  const handleOptionColorClick = (option) => {
    setSelectedColorOption(option);
    setPopupOpenColor(false);
  };
  const { goalUuid } = useParams();
  const colorCss = (color) => {
    switch (color) {
      case 'Dark green':
        return 'dark-green-color';
      case 'Red':
        return 'red-color';
      case 'Yellow':
        return 'yellow-color';
      case 'Blue':
        return 'blue-color';
      case 'Purple':
        return 'purple-color-color';
      case 'Orange':
        return 'orange-color';
      case 'Pink':
        return 'pink-color';
      case 'Light blue':
        return 'light-blue-color';
      case 'Lime green':
        return 'lime-green-color';
      case 'Dark red':
        return 'dark-red-color';
      case 'Green':
        return 'green-color';
      case 'Dark blue':
        return 'dark-blue-color';
      default:
        return 'green';
    }
  };

  const activeChange = () => {
    setActive(true);
  };
  const fetchGoals = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Goals_ById}${goalUuid}`);
      setGoal(response?.data);
      setSelectedColorOption(response?.data.colour);
    } catch (error) {
      console.error('error', error);
    }
  }, [goalUuid]);

  useEffect(() => {
    fetchGoals();
  }, [fetchGoals]);

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={goal?.userUuid} />
            <div className="app-main__outer edit-your-budget-main-wrapper">
              <div className="row top-nav">
                <div className="col-md-4 col-lg-3 col-xl-4 edit-your-budget-heading-wrp">
                  <div className="fs-3 bold top-head big-head-new">Edit your Goal Budget</div>
                  <div className="fst-normal setting_text_color">Make any changes required for your goals budget.</div>
                </div>
                <div className="col-md-4 edit-your-budget">
                  <div className="edit-btn-col">
                    <Link to={`/BudgetSummary/${goal?.userUuid}`}>
                      <div className="edit-btn" href-="#">
                        <div className="left-arrow">
                          <Link to={`/BudgetSummary/${goal?.userUuid}`}>
                            <Backarrow />
                          </Link>
                        </div>
                        <div className="right">
                          <h4 className="back-to-budget-summ bold">Back to budget summary</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 col-lg-5 col-xl-4 right-section edit-your-budget-back-portal-wrp">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <Link to={`/Clients/Activepage/${goal?.userUuid}`}>
                        <button className="comn-btn">
                          <i class="fa-solid fa-arrow-left"></i> Back to portal
                        </button>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <h3>View only</h3>
                    </div>
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton">
                <div className="mt-2">
                  <div className="col-lg-10 new-table edit-goal-table">
                    <div>
                      <div className="table-horizontal">
                        <div class="col-12 table-horizontal edit-goal-budget-wrapper">
                          <table class="table-image new-table">
                            <thead>
                              <tr>
                                <th scope="col">Goal name</th>
                                <th scope="col">Goal budget amount</th>
                                <th scope="col">Start date</th>
                                <th scope="col">End date</th>
                                <th scope="col">Account associated</th>
                              </tr>
                            </thead>
                            <tbody className="space-table">
                              <tr onClick={activeChange} className="cursor-pointer">
                                <th>
                                  <div className="air-image">
                                    <h3 className="table-content display">{goal?.goalName}</h3>
                                  </div>
                                </th>
                                <td className="table-text-color">
                                  <div className="planner big-planner">{goal?.value}</div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner big-planner">
                                    {goal?.startDate ? moment(goal?.startDate).format('YYYY-MM-DD') : ''}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner big-planner">
                                    {goal?.endDate ? moment(goal?.endDate).format('YYYY-MM-DD') : ''}
                                  </div>
                                </td>
                                <td className="table-text-color">
                                  <div className="planner big-planner">{goal?.accountName}</div>
                                </td>
                                <td>
                                  <Edit />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="setting_text_color p-1">
                        <span className="fw-bold">TIP: </span> For transactions to automatically categorise to your
                        goal, set a start and end date. Or transactions can be categorised manually.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xl-12">
                <h3 className="air-new fw-bold p-2">Personalise</h3>
              </div>
              <div className="row">
                <div className="col-md-6 col-xl-7  row groceries-edit-second-section">
                  <div className="col-md-6 col-xl-6 d-flex">
                    <div className="back-section">
                      <div className="personalised">
                        <h3 className="bold">Personalised colour</h3>
                      </div>
                      <div className="color-option">
                        <div className="dropdown">
                          <button
                            className={`group-button ${colorCss(selectedColorOption)} popoup-color`}
                            onClick={togglePopupColor}
                            aria-haspopup="true"
                            aria-expanded={isPopupOpenColor}
                          >
                            {selectedColorOption}
                          </button>
                          {isPopupOpenColor && (
                            <PopupColorModal
                              isOpen={isPopupOpenColor}
                              onClose={togglePopupColor}
                              title="Date Range"
                              options={[
                                'Dark green',
                                'Red',
                                'Yellow',
                                'Blue',
                                'Purple',
                                'Orange',
                                'Pink',
                                'Light blue',
                                'Lime green',
                                'Dark red',
                                'Green',
                                'Dark blue',
                              ]}
                              selectedOption={selectedColorOption}
                              handleOptionClick={handleOptionColorClick}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-6 d-flex">
                    <div className="back-section">
                      <div className="personalised">
                        <h3 className="bold">Personalised Emoji</h3>
                      </div>
                      <div className="color-option">
                        <div className="planner">{goal?.icon}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6"></div>
              </div>
              <div className="col-xl-8 mt-6-new">
                <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 back-red white">
                  <div className="inner-blck">
                    <div className="merchantSummary">
                      <div className="icon-img red-img goal-1-progress-color">{goal?.icon}</div>
                      <div className="content">
                        <h6 className="small-content white">{goal?.goalName}</h6>
                        <h5 className="bold">${goal?.value}</h5>
                      </div>
                    </div>
                    <div className="arrow">
                      <img src={Arrowright} />
                    </div>
                  </div>
                  <div className="left-block name-merchant">
                    <h6 className="white mb-2">100%</h6>
                  </div>
                  <div class="progress goal-1-progress-color" style={{ height: '12px' }}>
                    <div
                      class="progress-bar w-100 goal-1-progress-color"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditGoalBudget;
