import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
      bottom: 10,
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'April 2022',
      align: 'start',
      color: 'black',
      font: {
        size: 20,
      },
    },

    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      stacked: true,
      border: {
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
      },
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
  },
};

const labels = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

export function Cart({ income, expense }) {
  const maxIncome = Math.max(...income);
  const maxExpense = Math.max(...expense);
  const maxYValue = Math.max(maxIncome, maxExpense);
  const updatedOptions = {
    ...options,
    scales: {
      ...options.scales,
      y: {
        ...options.scales.y,
        suggestedMax: maxYValue + 20000,
      },
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Income',
        data: income,
        backgroundColor: '#469b88',
        minBarLength: 10,
      },
      {
        label: 'Expenses',
        data: expense,
        backgroundColor: '#ff8585',
      },
    ],
  };
  return (
    <div className="car-wrp">
      <Bar options={updatedOptions} width={100} height={50} data={chartData} className="barcart" />
    </div>
  );
}
