import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Piechart = ({ percentage }) => {
  return (
    <>
      <div style={{ width: '220px ' }}>
        <CircularProgressbarWithChildren
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            rotation: 0.1,

            strokeLinecap: 'butt',

            textSize: '20px',

            pathTransitionDuration: 0.3,

            pathColor: `rgba(70, 155, 136, 1), ${percentage / 100})`,
            textColor: 'rgba(38, 50, 56, 1)',
            trailColor: 'rgba(70, 155, 136, 0.4)',
            backgroundColor: '',
          })}
        >
          <div
            style={{
              fontSize: 13,
              marginTop: 90,
              fontWeight: 'bold',
              fontFamily: 'NeurialGrotesk-Regular',
              letterSpacing: 2,
            }}
          >
            <center>OF INCOME</center>
          </div>
          <div style={{ fontSize: 13, fontWeight: 'bold', fontFamily: 'NeurialGrotesk-Regular', letterSpacing: 2 }}>
            <center>RECEIVED</center>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </>
  );
};
export default Piechart;
