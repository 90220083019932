import axios from 'axios';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paymentSuccess from '../../assests/images/payment-success.gif';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import '../pages/Login.css';
import '../Setting/setting.css';
import weburls from '../Weburls/weburls';
import { getWithExpiry } from './checkAndSetLoginExpried';

const PaymentsUpgradeSuccess = () => {
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState('');
  const [apiCall, setApiCall] = useState(true);
  const { uuid } = useParams();
  const userEmail = getWithExpiry('email');
  const userName = getWithExpiry('name');

  const logoRef = useRef(null);
  const formRef = useRef(null);

  useEffect(() => {
    if (!userEmail) {
      navigate('/manageAccountLogin');
    }
  }, [userEmail]);

  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const fetchedData = useCallback(async () => {
    const body = {
      email: userEmail,
    };
    try {
      const response = await axios.post(weburls.Get_Subscription_id, body);
      const session = response.data;
      setSessionId(session);
      const bodys = {
        sessionId: session,
        email: userEmail,
      };
      if (apiCall) {
        setApiCall(false);
        const responses = await axios.post(weburls.Payement_Success, bodys);
        if (responses.data.message) {
          console.log(responses.data);
          localStorage.setItem('planDescription', responses.data.data.planDescription);
          localStorage.setItem('planDuration', responses.data.data.planDuration);
          localStorage.setItem('amount', responses.data.data.amount);
        }
      }
      setTimeout(() => {
        navigate(`/managePlan`);
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  }, [apiCall, userEmail, navigate]);

  useEffect(() => {
    fetchedData();
  }, [fetchedData]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);

    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
    };
  }, []);

  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }

        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <section class="login-section loginSectionWelcome">
        <div class="container-fluid h-custom">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div
              class="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp" ref={formRef}>
                <div className="heading-2fa bold black mt-5">
                  <h3>Change successful 🎉</h3>
                </div>
                <div className="text-center">
                  <img src={paymentSuccess} className="payment-gif" />
                </div>
                <div class="modal-popup-btn-wrp verification-code-input no-padding mb-5 mt-3">
                  <button className="comn-btn verify-btn" type="button">
                    You will be redirected in {counter}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentsUpgradeSuccess;

