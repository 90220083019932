import React, { useRef, useState } from 'react';
import { ReactComponent as ArrowUp } from '../../../assests/images/icons/arrow-down.svg';
import { ReactComponent as Chevronleft } from '../../../assests/images/icons/chevron-left.svg';

const Accordian = (props) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('accordion__icon');
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
  }
  return (
    <>
      <div className="col-lg-6 contribution-inner-left-side">
        <div className="your-contribution-box mt-2">
          <div className={`accordion your-contribution ${setActive}`} onClick={toggleAccordion}>
            <div className="d-flex">
              <div className="acc-image">
                <img src={props.image} alt="" />
              </div>
              <div className="ms-3 d-flex align-items-center">
                <div className="text-merchant fw-bold inter">{props.title}</div>
              </div>
            </div>
            <div className="account-number">
              <ArrowUp className={`${setRotate}`} />
            </div>
          </div>
          <div className="accordion__content" ref={content} style={{ maxHeight: `${setHeight}` }}>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="fw-bold you-income-text inter">Your income</div>
              <div className="d-flex your-income">
                <div className="edit-goal-house you-income me-2 inter">$2500</div>
                <div className="edit-goal-house you-income inter">Fornightly</div>
                <div className="position-relative">
                  <Chevronleft className="your-account-Chevronleft-icon" />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="fw-bold you-income-text inter">Fund type</div>
              <div className="edit-goal-house you-income position-relative big-width inter">
                Growth Fund
                <Chevronleft className="your-account-Chevronleft-icon" />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="fw-bold you-income-text inter">Annual growth rate</div>
              <div className="edit-goal-house you-income position-relative big-width inter">
                6%
                {/* <Chevronleft className="your-account-Chevronleft-icon" /> */}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="fw-bold you-income-text inter">Contribution rate</div>
              <div className="edit-goal-house you-income position-relative big-width inter">
                4%
                <Chevronleft className="your-account-Chevronleft-icon" />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 align-items-center">
              <div className="fw-bold you-income-text inter">Employer contribution</div>
              <div className="edit-goal-house you-income position-relative big-width inter">
                3%
                <Chevronleft className="your-account-Chevronleft-icon" />
              </div>
            </div>
            <div className="you-income-text mt-2   sml-content">
              <span className="bold black">Disclaimer :</span> Kiwisaver fund growth rates are based on rates provided
              by the fund. If the rates differ to what’s listed, this can be edited.
            </div>
          </div>
        </div>
      </div>

      {/* <div className="accordion__section">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div> */}
    </>
  );
};
export default Accordian;
