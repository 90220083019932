import moment from 'moment/moment';
import React from 'react';

const GoalDetailsShow = ({ goal, onEdit }) => {
  return (
    <>
      <div className="mt-3 edit-goal-ami active-edit-goal">
        <div>
          <div className="bold black sub-head-text inter">Goal Details</div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Name</div>
            <input
              type="text"
              value={goal.goalName}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
              readOnly
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between">
            <div className="fw-bold inter">Goal Dollar Value</div>
            <input
              type="text"
              value={goal.value}
              className="edit-goal-house w-75 py-2 add-goal"
              style={{ border: 'none' }}
              readOnly
            />
          </div>
          {/* Add more read-only fields for other details */}
          <div className="edit-goal-box d-flex justify-content-between align-items-center">
            <div className="fw-bold inter">Start Date</div>
            <input
              type="date"
              value={moment(goal.startDate).format('YYYY-MM-DD')}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
              readOnly
            />
          </div>
          <div className="edit-goal-box d-flex justify-content-between align-items-center">
            <div className="fw-bold inter">End Date</div>
            <input
              type="date"
              value={moment(goal.endDate).format('YYYY-MM-DD')}
              className="edit-goal-house w-75 py-2 inter add-goal"
              style={{ border: 'none' }}
              readOnly
            />
          </div>
          {/* Add more read-only fields for other details */}
          <div className="d-flex justify-content-between mt-3">
            <button onClick={onEdit} className="btn btn-primary">
              Edit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalDetailsShow;
