import React from 'react';

const Notactivatedbutton = () => {
  return (
    <>
      <div className="amount-activated">Not activated</div>
    </>
  );
};
export default Notactivatedbutton;
