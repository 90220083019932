import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactComponent as Edit } from '../../assests/images/icons/edit.svg';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import './BudgetSummary.css';
import IncomeBudget from './IncomeVsBudget/IncomeBudget';
import SpendBudget from './SpendvsBudget/Spendbudget';

const BudgetSummary = () => {
  const sidebarRef = useRef(null);
  const { uuid } = useParams();

  const [isPopupOpenIncome, setPopupOpenIncome] = useState(false);
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);

  const [selectedIncomeOption, setSelectedIncomeOption] = useState('This week');

  const togglePopupIncome = () => setPopupOpenIncome(!isPopupOpenIncome);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const handleOptionClick = (option) => {
    setSelectedIncomeOption(option);
    setPopupOpenIncome(false);
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer budget-summary-main">
              <div className="row top-nav">
                <div className="col-md-12 col-xl-4 col-lg-4 col-sm-12 page-heading-title-sub-title">
                  <div className="bold big-head-new">Budget summary</div>
                  <div className="fst-normal setting_text_color">
                    Here's your summary of your budget and how you're tracking
                  </div>
                </div>
                <div className="col-md-12 col-xl-8 col-lg-8 col-sm-12 right-section back-to-portal-top">
                  <div className="right-section">
                    <div className="profile-dashboard">
                      <Link to={`/Editbudget/${uuid}`}>
                        <div className="edit-btn" href-="#" style={{ width: '327px', height: '66px' }}>
                          <div className="left">
                            <h4>Edit your budget</h4>
                            <p style={{ color: '#04baff' }}>Make any needed changes.</p>
                          </div>

                          <div className="right">
                            <Link to={`/Editbudget/${uuid}`}>
                              <Edit />
                            </Link>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>
                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Budget summary 💸"
                        description={[
                          'Your budget summary shows your actual income or spending compared to the budgeted amount you’ve entered in the edit your budget screen',
                          'Here, you can filter different period views, edit, and personalise categories by simply clicking on each widget.',
                          'Here is where you’ll also see your one-off budgets and how you’re tracking. Your one-off budgets are things that are outside of your regular spending.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img src={Profile} alt="profile" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row middle-seciton budget-summ-tabber-wrp">
                <div className="col-md-6 col-lg-6">
                  <ul class="nav nav-pills mb-5 budget-tabs img-back" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        This Month
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                      >
                        This Quarter
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content col-xl-12 col-lg-12 col-sm-12" id="pills-tabContent">
                  {/* month */}
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row">
                      <div className="col-md-8 col-xl-8 col-sm-12 top-section mb-2 income-vs-budget-wrapper">
                        <div className="col-md-6">
                          <h5 className="title main-title">Income vs Budget</h5>
                        </div>
                        <div class="col-md-6 right-section">
                          <i
                            class="fa-solid fa-plus "
                            style={{ marginLeft: '264px', width: '40px', fontSize: '20px' }}
                          ></i>
                        </div>
                        {/* <div className="arrow">
                          <img src={ArrowRight} alt="arrow" />
                        </div>
                        <div className="arrow">
                          <img src={ArrowRight} alt="arrow" />
                        </div> */}
                      </div>
                    </div>
                    <IncomeBudget uuid={uuid} monthly={true} />
                    <SpendBudget uuid={uuid} monthly={true} />
                  </div>
                  {/* quarter */}
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="row">
                      <div className="col-md-8 col-xl-8 col-sm-12 top-section mb-2 income-vs-budget-wrapper">
                        <div className="col-md-6">
                          <h5 className="title main-title">Income vs Budget</h5>
                        </div>
                        <div class="col-md-6 right-section">
                          <i
                            class="fa-solid fa-plus "
                            style={{ marginLeft: '264px', width: '40px', fontSize: '20px' }}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <IncomeBudget uuid={uuid} monthly={false} />
                    <SpendBudget uuid={uuid} monthly={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BudgetSummary;
