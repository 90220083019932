import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ArrowRight from '../../../assests/images/Expand_right.png';
import PopupModal from '../../Popup/PopupModel';
import weburls from '../../Weburls/weburls';
import Piechart from '../Donutpiechart/Piechart';
import { progressbarColor } from '../Financial';
import Smallcart from '../Smallcart/Smallcart';

const IncomeBudget = ({ uuid, monthly }) => {
  const [incomeVsBudget, setIncomeVsBudget] = useState();

  const [isPopupOpenIncome, setPopupOpenIncome] = useState(false);

  const [selectedIncomeOption, setSelectedIncomeOption] = useState('This week');
  const [incomeBreakDownData, setIncomeBreakDownData] = useState();

  const togglePopupIncome = () => setPopupOpenIncome(!isPopupOpenIncome);
  const handleOptionClick = (option) => {
    setSelectedIncomeOption(option);
    setPopupOpenIncome(false);
  };

  const fetchSpendVsBudgetData = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Income_Vs_Budget}${uuid}`);
      if (response?.data) {
        if (monthly) {
          setIncomeVsBudget(response?.data?.income?.monthly);
        } else {
          setIncomeVsBudget(response?.data?.income?.quarter);
        }
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [monthly, uuid]);

  useEffect(() => {
    fetchSpendVsBudgetData();
  }, [fetchSpendVsBudgetData]);
  function calculatePercentageCompletion(completedValue, totalValue) {
    const percentageCompletion = (completedValue / totalValue) * 100;

    return percentageCompletion;
  }

  function calculateBudget(income) {
    let totalIncome = 0;
    let totalRemaining = 0;
    let totalAdjusted = 0;

    income?.forEach((item) => {
      totalIncome += item.total_transaction_amount;
      totalRemaining += item.adjusted_income_amount - item.total_transaction_amount;
      totalAdjusted += item.adjusted_income_amount;
    });

    return {
      totalIncome: totalIncome.toFixed(2),
      totalRemaining: totalRemaining.toFixed(2),
      totalAdjusted: totalAdjusted.toFixed(2),
    };
  }
  const budgetCalculation = calculateBudget(incomeVsBudget);

  const IncomeBreakDownData = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_Income_BreakDown}${uuid}`);
      if (response?.data) {
        if (selectedIncomeOption === 'This week') {
          setIncomeBreakDownData(response?.data?.incomeBreakDown?.thisWeek);
        } else if (selectedIncomeOption === 'Last Week') {
          setIncomeBreakDownData(response?.data?.incomeBreakDown?.lastWeek);
        } else if (selectedIncomeOption === 'This month') {
          setIncomeBreakDownData(response?.data?.incomeBreakDown?.thisMonth);
        } else if (selectedIncomeOption === 'Last Month') {
          setIncomeBreakDownData(response?.data?.incomeBreakDown?.lastMonth);
        } else if (selectedIncomeOption === 'This year') {
          setIncomeBreakDownData(response?.data?.incomeBreakDown?.thisYear);
        }
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Something went wrong please try again.', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [selectedIncomeOption, uuid]);
  useEffect(() => {
    IncomeBreakDownData();
  }, [IncomeBreakDownData]);

  function calculateTotalIncome(arr) {
    let sum = 0;
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].hasOwnProperty('total_income_amount')) {
        sum += arr[i].total_income_amount;
      } else {
        throw new Error(`Object at index ${i} is missing 'total_income_amount' property`);
      }
    }

    return sum;
  }
  const totalIncomeSum = calculateTotalIncome(incomeBreakDownData);
  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-8">
        <div className="wrp">
          {incomeVsBudget &&
            incomeVsBudget?.length &&
            incomeVsBudget.map((it) => (
              <div className="income-merchant col-lg-5 col-xl-5 col-sm-12 mb-3">
                <div className="inner-blck">
                  <div className="merchantSummary">
                    <div className="icon-img red-img">{it?.emoji}</div>
                    <div className="content">
                      <h6 className="small-content">{it?.income_name}</h6>
                      <h5 className="bold">${it?.total_transaction_amount}</h5>
                    </div>
                  </div>
                  <div className="arrow">
                    <img src={ArrowRight} alt="arrow" />
                  </div>
                </div>
                <div className="left-block name-merchant mb-2">
                  <h6>${(it?.adjusted_income_amount - it?.total_transaction_amount).toFixed(2)} remaining</h6>
                </div>
                <div class={`progress red-progress`} style={{ height: '12px' }}>
                  <div
                    className={`progress-bar ${progressbarColor(it?.colour)}`}
                    role="progressbar"
                    aria-valuenow={calculatePercentageCompletion(
                      it?.total_transaction_amount,
                      it?.adjusted_income_amount,
                    ).toFixed(2)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${calculatePercentageCompletion(
                        it?.total_transaction_amount,
                        it?.adjusted_income_amount,
                      ).toFixed(2)}%`,
                      opacity: '2 !important',
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col-xl-4 col-md-12 col-sm-12 mt-6 income-vs-budget">
        <div className="right-top-blck">
          <div className="new-blck">
            <div className="content">
              <h3 className="title">Total income vs budget</h3>
              <p className="common-content">See how you’re tracking</p>
            </div>
            <div className="right">
              <h3 className="per-head bold">
                {calculatePercentageCompletion(budgetCalculation.totalIncome, budgetCalculation.totalAdjusted).toFixed(
                  2,
                )}
                %
              </h3>
            </div>
          </div>
          <div className="pie-blck first-circular-block ">
            <Piechart
              percentage={calculatePercentageCompletion(
                budgetCalculation.totalIncome,
                budgetCalculation.totalAdjusted,
              ).toFixed(2)}
            />
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        <div className="wrp">
          <div className="col-lg-12 col-xl-12 col-sm-12 merchant-blck">
            <div className="inner-blck">
              <div className="merchantSummary">
                <div className="content">
                  <h6 className="small-content little-big-font">Total income vs budget</h6>
                  <h5 className="bold">${budgetCalculation.totalIncome}</h5>
                </div>
              </div>
            </div>
            <div className="left-block name-merchant mb-2 mt-3">
              <h6>${budgetCalculation.totalRemaining} remaining</h6>
            </div>
            <div class="progress blue-progress light-green" style={{ height: '16px' }}>
              <div
                className={`progress-bar green`}
                role="progressbar"
                aria-valuenow={calculatePercentageCompletion(
                  budgetCalculation.totalIncome,
                  budgetCalculation.totalAdjusted,
                ).toFixed(2)}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{
                  width: `${calculatePercentageCompletion(
                    budgetCalculation.totalIncome,
                    budgetCalculation.totalAdjusted,
                  ).toFixed(2)}%`,
                  opacity: '2 !important',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4">
        <div className="right-top-blck new-top-blck">
          <div className="new-blck">
            <div className="content">
              <h3 className="title">Income breakdown</h3>
              <div class="right-section">
                <h3 className="per-head bold">${totalIncomeSum.toFixed(2)}</h3>
              </div>
            </div>
            <div className="right">
              <div className="dropdown">
                <button
                  className="group-button green"
                  onClick={togglePopupIncome}
                  aria-haspopup="true"
                  aria-expanded={isPopupOpenIncome}
                >
                  {selectedIncomeOption}
                </button>
                {isPopupOpenIncome && (
                  <PopupModal
                    isOpen={isPopupOpenIncome}
                    onClose={togglePopupIncome}
                    title="Date Range"
                    options={['This week', 'Last Week', 'This month', 'Last Month', 'This year']}
                    selectedOption={selectedIncomeOption}
                    handleOptionClick={handleOptionClick}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="pie-blck">
            <Smallcart data={incomeBreakDownData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeBudget;
