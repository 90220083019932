import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Profileimg from '../../assests/images/icons/Big-profile.png';
import Camera from '../../assests/images/icons/camera.png';
import CardIcon from '../../assests/images/icons/card-icon.png';
import Profile from '../../assests/images/icons/image.png';
import Shape from '../../assests/images/icons/shape.png';
import VisaIcon from '../../assests/images/icons/visa-icon.png';
import MainSidebar from '../MainSidebar';
import PopupInfoModal from '../Popup/PopupInfoModel';
import weburls from '../Weburls/weburls';
import './setting.css';

const ToggleSwitch = ({ value, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className="slider round"></span>
    </label>
  );
};

const Setting = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const sidebarRef = useRef(null);
  const [data, setData] = useState();
  const [toggleTab, setToggleTab] = useState('details');
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [dashboardYesNo, setDashboardYesNo] = useState('Yes');
  const [budgetYesNo, setBudgetYesNo] = useState('Yes');
  const [cashflowYesNo, setCashflowYesNo] = useState('Yes');
  const [netWorthYesNo, setNetWorthYesNo] = useState('Yes');
  const [retirementYesNo, setRetirementYesNo] = useState('Yes');
  const [goalsYesNo, setGoalsYesNo] = useState('Yes');
  const [transactionsYesNo, setTransactionsYesNo] = useState('Yes');
  const [accountsYesNo, setAccountsYesNo] = useState('Yes');
  const [connectYesNo, setConnectYesNo] = useState('Yes');

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [revokeConfirmPassword, setRevokeConfirmPassword] = useState('');
  const [revokeConfirmEmail, setRevokeConfirmEmail] = useState('');

  const [darkMode, setDarkMode] = useState(false);
  const [lightMode, setLightMode] = useState(false);

  const [email2FAVerify, setEmail2FAVerify] = useState(false);

  const toggle2FAVerify = useCallback(async () => {
    setEmail2FAVerify(!email2FAVerify);
    if (!email2FAVerify) {
      setEmail2FAVerify(!email2FAVerify);
      setTimeout(() => {
        navigate(`/Email2FAVerifaction/${uuid}`);
      }, 500);
    } else {
      setEmail2FAVerify(!email2FAVerify);
    }
  }, [email2FAVerify]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    setLightMode(false);
  };

  const toggleLightMode = () => {
    setLightMode(!lightMode);
    setDarkMode(false);
  };

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const settingApi = useCallback(async () => {
    const response = await axios.get(weburls.Get_clients_by_uuid + `${uuid}`);
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || 'No Role Found', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  }, [uuid]);

  useEffect(() => {
    settingApi();
  }, [settingApi]);

  const Logoutpg = () => {
    localStorage.removeItem('budget-token');
    localStorage.removeItem('userData');
    navigate('/');
  };

  const toggleOnHandle = (event) => {
    console.log(event, 'event');
    setToggleTab(event);
  };

  const changePassword = async () => {
    if (currentPassword === '' || currentPassword === undefined) {
      toast.error('Please Insert Current Password', {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    if (!hasNumber || !hasSpecialChar) {
      toast.error('New password must contain at least one number and one special character.', {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error('New password and Confirm password do not match.', {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }
    const data = {
      userUuid: uuid,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await axios.post(weburls.Change_Password, data);
      if (response.data.code === 0) {
        toast.error('Your current password incorrect', {
          position: 'top-right',
          autoClose: 2000,
        });
        setToggleTab('security');
      } else if (response.data.code === 1) {
        toast.success('Your password changed successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setToggleTab('security');
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  const deleteAccount = async () => {
    if (revokeConfirmEmail === '' || revokeConfirmEmail === undefined) {
      toast.error('Please Insert Email Address', {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }

    if (revokeConfirmPassword === '' || revokeConfirmPassword === undefined) {
      toast.error('Please Insert Confirm Password', {
        position: 'top-right',
        autoClose: 2000,
      });
      return;
    }

    const data = {
      email: revokeConfirmEmail,
      password: revokeConfirmPassword,
    };
    try {
      const response = await axios.put(`${weburls.Revoke_account}${uuid}`, data);
      if (response.data.code === 0) {
        toast.error('User email and password incorrect', {
          position: 'top-right',
          autoClose: 2000,
        });
        setToggleTab('security');
      } else if (response.data.code === 1) {
        toast.success('Your account de-activated successfully', {
          position: 'top-right',
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (error) {
      console.error('error', error);
      toast.error('Please enter valid email address', {
        position: 'top-right',
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className="container-scroller" ref={sidebarRef}>
        <div className="container-fluid">
          <div className="appmain mainsidebar main-wrapper">
            <MainSidebar uuid={uuid} />
            <div className="app-main__outer your-setting-page-container">
              <ToastContainer position="top-right" autoClose={5000} closeOnClick />
              <div className="row">
                <div className="col-md-6">
                  <div className="fs-3 bold big-head-new">Your settings</div>
                  <div className="fst-normal setting_text_color">
                    Lets get the nuts and bolts sorted to optimise your account experience.
                  </div>
                </div>
                <div className="col-md-6 right-section your-setting-page-back-portal">
                  <div className="setting_background_color right-section">
                    <div className="profile-dashboard">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        style={{ width: '20px', marginLeft: '20px' }}
                      />
                    </div>

                    {isPopupOpenInfo && (
                      <PopupInfoModal
                        isOpen={isPopupOpenInfo}
                        onClose={togglePopupInfo}
                        title="Settings ⚙️"
                        description={[
                          'Manage all your details, preferences, and security settings here.',
                          'Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.',
                          'Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ',
                          'View when your next payment is being processed and what card you’re using.',
                        ]}
                      />
                    )}
                    <div className="profile-dashboard">
                      <div className="profile-img">
                        <img
                          src={data?.avatar ? data?.avatar : Profile}
                          alt="profile"
                          style={{
                            height: '59px',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row setting-page">
                <div className="col-xl-12 setting-wrp">
                  <ul class="nav nav-pills mb-2 setting-tab" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => toggleOnHandle('details')}
                      >
                        Your details
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => toggleOnHandle('security')}
                      >
                        Notifications & Security
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-contact-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-contact"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                        onClick={() => toggleOnHandle('appearance')}
                      >
                        Appearance
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-payment-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-payment"
                        type="button"
                        role="tab"
                        aria-controls="pills-payment"
                        aria-selected="false"
                        onClick={() => toggleOnHandle('payment')}
                      >
                        Payments & Billing
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="tab-content mt-4" id="pills-tabContent">
                  {toggleTab === 'details' && (
                    <div
                      class="tab-pane col-xl-12 fade show active d-flex"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="col-xl-6 setting-left-section mt-4">
                        <div className="main-section-left">
                          <div className="heading bold black">
                            <h3>Your details</h3>
                          </div>
                          <form className="row">
                            <div className="field-form col-xl-5">
                              <label className="label-text">Name</label>
                              <div
                                className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                style={{ height: '45px' }}
                              >{`${data?.firstName} ${data?.lastName}`}</div>
                            </div>
                            {/* <div className="field-form col-xl-3">
                            <label>Country Code</label>
                            <div className="user-settings">{data?.countryCode}</div>
                          </div> */}
                            <div className="field-form col-xl-4">
                              <label className="label-text">Phone number</label>
                              <div className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction">
                                {data?.phoneNumber}
                              </div>
                            </div>
                            <div className="field-form col-xl-8">
                              <label className="label-text">Your email</label>
                              <div className="edit-settings-house w-75 py-2 inter add-goal ">{data?.email}</div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-xl-6 profile-wrp">
                        <div className="profile-img-setting  profile-img-margin">
                          <img
                            src={data?.avatar === '' || data?.avatar === null ? Profileimg : data?.avatar}
                            alt="profile imag"
                            style={{
                              width: '133px',
                              height: '133px',
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="camera">
                            <img src={Camera} alt="camera" />
                          </div>
                        </div>
                        <div className="profile-img-setting btn-padding">
                          <button className="setting-logout-btn" onClick={Logoutpg}>
                            Log out
                          </button>
                        </div>
                        <p className="version-padding">Version 1.1</p>
                      </div>
                    </div>
                  )}
                  {toggleTab === 'security' && (
                    <>
                      <div
                        class="tab-pane col-xl-12 fade show active d-flex"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        <div className="col-xl-10 setting-left-section mt-4">
                          <div className="main-section-left">
                            <div className="heading bold black">
                              <h3>Notifications</h3>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center saving-title">
                                      <div className="text-merchant fw-bold inter">Dashboard</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          dashboardYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setDashboardYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          dashboardYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setDashboardYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                      <div className="text-merchant fw-bold inter ">Budget</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          budgetYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setBudgetYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          budgetYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setBudgetYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box mt-2 setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                      <div className="text-merchant fw-bold inter setting-padding-security">
                                        Cashflow
                                      </div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          cashflowYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setCashflowYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          cashflowYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setCashflowYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box mt-2 last-house-wrp setting-padding-security">
                                  <div className="d-flex">
                                    <div className="account-assets-inner">
                                      <div className="text-merchant fw-bold inter">Net Worth</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          netWorthYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setNetWorthYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          netWorthYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setNetWorthYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center saving-title">
                                      <div className="text-merchant fw-bold inter">Retirement planner</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          retirementYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setRetirementYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          retirementYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setRetirementYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                      <div className="text-merchant fw-bold inter ">Goals</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          goalsYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setGoalsYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          goalsYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setGoalsYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box mt-2 setting-padding-security">
                                  <div className="d-flex">
                                    <div className="d-flex align-items-center">
                                      <div className="text-merchant fw-bold inter setting-padding-security">
                                        Transactions
                                      </div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          transactionsYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setTransactionsYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          transactionsYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setTransactionsYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box mt-2 last-house-wrp setting-padding-security">
                                  <div className="d-flex">
                                    <div className="account-assets-inner">
                                      <div className="text-merchant fw-bold inter">Accounts</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          accountsYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setAccountsYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          accountsYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setAccountsYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 account-assets-column">
                                <div className="merchant-box mt-2 last-house-wrp setting-padding-security">
                                  <div className="d-flex">
                                    <div className="account-assets-inner">
                                      <div className="text-merchant fw-bold inter">Connect your accounts</div>
                                    </div>
                                  </div>
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          connectYesNo === 'Yes' ? 'dark-green-color' : 'btn-no'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setConnectYesNo('Yes')}
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="button-blck">
                                      <button
                                        type="button"
                                        className={`group-button ${
                                          connectYesNo === 'Yes' ? 'btn-no' : 'dark-green-color'
                                        } popoup-color`}
                                        aria-haspopup="true"
                                        onClick={() => setConnectYesNo('No')}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 profile-wrp half-width">
                          <div className="profile-img-setting  profile-img-margin">
                            <img
                              src={data?.avatar === '' || data?.avatar === null ? Profileimg : data?.avatar}
                              alt="profile imag"
                              style={{
                                width: '133px',
                                height: '133px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                            <div className="camera">
                              <img src={Camera} alt="camera" />
                            </div>
                          </div>
                          <div className="profile-img-setting btn-padding">
                            <button className="setting-logout-btn" onClick={Logoutpg}>
                              Log out
                            </button>
                          </div>
                          <p className="version-padding">Version 1.1</p>
                        </div>
                      </div>
                      <div
                        class="tab-pane col-xl-12 fade show active d-flex"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="col-xl-4 setting-left-section mt-4">
                          <div className="main-section-left">
                            <div className="heading bold black">
                              <h3>Change password</h3>
                            </div>
                            <form className="row">
                              <div className="field-form col-xl-12">
                                <label className="label-text">Current password</label>
                                <input
                                  type="password"
                                  name="currentPassword"
                                  value={currentPassword}
                                  className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                  style={{ border: 'none' }}
                                  onChange={(e) => {
                                    setCurrentPassword(e.target.value);
                                  }}
                                  required
                                />
                              </div>
                              <div className="field-form col-xl-12">
                                <label className="label-text">New password</label>
                                <input
                                  type="password"
                                  name="newPassword"
                                  value={newPassword}
                                  className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                  style={{ border: 'none' }}
                                  onChange={(e) => {
                                    setNewPassword(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="field-form col-xl-12">
                                <label className="label-text">Confirm password</label>
                                <input
                                  type="password"
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                  style={{ border: 'none' }}
                                  onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                  }}
                                />
                              </div>
                              <div class="modal-popup-btn-wrp" style={{ marginTop: '5px', marginBottom: '15px' }}>
                                <button className="change-password-btn" type="button" onClick={changePassword}>
                                  Change password
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-xl-6 profile-wrp">
                          <div className="main-section-left mt-4 ms-4">
                            <div className="heading bold black">
                              <h3 className="header-h3">Set up 2-Factor Authentication</h3>
                              <h3 className="header-h3" style={{ float: 'right' }}>
                                <p className="header-p">
                                  <div className="account-number inter text-16px">
                                    <div className="button-blck">
                                      <ToggleSwitch value={email2FAVerify} onChange={toggle2FAVerify} />
                                    </div>
                                  </div>
                                </p>
                              </h3>
                            </div>
                          </div>
                          <div className="main-section-left mt-4 ms-4">
                            <div className="heading bold black">
                              <h3>Delete account & revoke access</h3>
                            </div>
                            <form className="row">
                              <div className="field-form col-xl-6">
                                <label className="label-text">Confirm email</label>
                                <input
                                  type="email"
                                  name="revokeConfirmEmail"
                                  value={revokeConfirmEmail}
                                  className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                  style={{ border: 'none' }}
                                  onChange={(e) => {
                                    setRevokeConfirmEmail(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="field-form col-xl-6">
                                <label className="label-text">Confirm password</label>
                                <input
                                  type="password"
                                  name="revokeConfirmPassword"
                                  value={revokeConfirmPassword}
                                  className="edit-settings-house w-75 py-2 inter add-goal full-width-transaction"
                                  style={{ border: 'none' }}
                                  onChange={(e) => {
                                    setRevokeConfirmPassword(e.target.value);
                                  }}
                                />
                              </div>
                              <p>
                                <b>NOTE:</b> Deleting your account will mean we remove all stored data and remove your
                                account connections that you have set up. Once your accounts deleted you will not be
                                able to access BudgetBuddie unless you create a new account. Once you confirm deletion,
                                this cannot be un-done.
                              </p>
                              <div class="modal-popup-btn-wrp" style={{ marginTop: '5px', marginBottom: '15px' }}>
                                <button
                                  className="change-password-btn delete-btn"
                                  type="button"
                                  onClick={deleteAccount}
                                >
                                  Delete account
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {toggleTab === 'appearance' && (
                    <div
                      class="tab-pane col-xl-12 fade show active d-flex"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <div className="col-xl-6 setting-left-section mt-4">
                        <div className="main-section-left">
                          <div className="heading bold black">
                            <h3>Appearance</h3>
                          </div>
                          <div className="row">
                            <div className="col-lg-6 account-assets-column">
                              <div className="merchant-box appearance-box">
                                <div className="d-flex">
                                  <div className="d-flex align-items-center saving-title">
                                    <div className="text-merchant fw-bold inter">Dark mode</div>
                                  </div>
                                </div>
                                <div className="account-number inter text-16px">
                                  <div className="button-blck">
                                    <ToggleSwitch value={darkMode} onChange={toggleDarkMode} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 account-assets-column">
                              <div className="merchant-box appearance-box">
                                <div className="d-flex">
                                  <div className="d-flex align-items-center">
                                    <div className="text-merchant fw-bold inter ">Light mode</div>
                                  </div>
                                </div>
                                <div className="account-number inter text-16px">
                                  <div className="button-blck">
                                    <ToggleSwitch value={lightMode} onChange={toggleLightMode} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 profile-wrp">
                        <div className="profile-img-setting  profile-img-margin">
                          <img
                            src={data?.avatar === '' || data?.avatar === null ? Profileimg : data?.avatar}
                            alt="profile imag"
                            style={{
                              width: '133px',
                              height: '133px',
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                          />
                          <div className="camera">
                            <img src={Camera} alt="camera" />
                          </div>
                        </div>
                        <div className="profile-img-setting btn-padding">
                          <button className="setting-logout-btn" onClick={Logoutpg}>
                            Log out
                          </button>
                        </div>
                        <p className="version-padding">Version 1.1</p>
                      </div>
                    </div>
                  )}
                  {toggleTab === 'payment' && (
                    <>
                      <div
                        class="tab-pane col-xl-12 fade show active d-flex"
                        id="pills-payment"
                        role="tabpanel"
                        aria-labelledby="pills-payment-tab"
                      >
                        <div className="col-xl-4 setting-left-section mt-4">
                          <div className="main-section-left">
                            <div className="heading bold black">
                              <h3>Your current plan</h3>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 account-assets-column mb-4 box-radis-25">
                                <div className="plan-billing mb-3">
                                  <div className="inner-blck">
                                    <div className="merchantSummary">
                                      <div className="content">
                                        <h5 className="plan-text ">
                                          Standard - $9<span className="plan-span">/Month</span>
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="arrow">
                                      <div className="button-blck">
                                        <button className="change-plan">Change plan</button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="plan-description">
                                    Our most popular plan with all the essentials. Includes access to most features such
                                    as dashboard, budget, cashflow, goals, and 5 connections.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 profile-wrp payment-margin ">
                          <div className="profile-img-setting  profile-img-margin">
                            <img
                              src={data?.avatar === '' || data?.avatar === null ? Profileimg : data?.avatar}
                              alt="profile imag"
                              style={{
                                width: '133px',
                                height: '133px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                              }}
                            />
                            <div className="camera">
                              <img src={Camera} alt="camera" />
                            </div>
                          </div>
                          <div className="profile-img-setting btn-padding">
                            <button className="setting-logout-btn" onClick={Logoutpg}>
                              Log out
                            </button>
                          </div>
                          <p className="version-padding">Version 1.1</p>
                        </div>
                      </div>
                      <div
                        class="tab-pane col-xl-12 fade show active d-flex"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="col-xl-5 setting-left-section">
                          <div className="main-section-left">
                            <div className="heading bold black">
                              <h3>Payments & Billing</h3>
                            </div>
                            <form className="row">
                              <div className="field-form col-xl-7">
                                <label>Next payment date</label>
                                <div className="user-settings"></div>
                              </div>
                              <div className="field-form col-xl-4">
                                <label>Next payment amount</label>
                                <div className="user-settings"></div>
                              </div>
                              <div className="field-form col-xl-7">
                                <label className="label-text">Current payment method</label>
                                {/* <div className="billing">
                                  <img src={VisaIcon} alt="profile" style={{ width: '80px' }} />
                                  <span>TC PRESTON</span>
                                  <br />
                                  <span>Visa ending 1234</span>
                                </div> */}
                                <div className="merchant-box shadow">
                                  <div className="d-flex">
                                    <div className="visa-logo-img">
                                      <img src={VisaIcon} alt="profile" />
                                    </div>
                                    <div className="ms-5">
                                      <div className="payment-method-text fw-bold-600">TC PRESTON</div>
                                      <div className="payment-method-number inter">Visa ending 1234</div>
                                    </div>
                                  </div>
                                  <img src={CardIcon} alt="profile" className="card-logo-img" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Setting;
